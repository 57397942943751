import * as React from "react";
import * as CryptoJS from "crypto-js";
import * as Configs from "../components/Configs";
import { getSignedUrlCall } from "../services/CloudFunctionService";
import { IonContent, IonLoading } from "@ionic/react";
import * as Util from "../components/Util";

const decodeBase64String = (base64url: string): string => {
  const words = CryptoJS.enc.Base64.parse(base64url);
  const textString = CryptoJS.enc.Utf8.stringify(words);
  return textString;
};

const AdvanceProductSearchScreen = () => {
  return ScreenTemplate(Configs.LOOKER_DASHBOARD_ADVANCE_PRODUCT_SEARCH);
};

const SalesDashboardScreen = () => {
  return ScreenTemplate(Configs.LOOKER_DASHBOARD_SALES);
};

const ProductDetailScreen = () => {
  return ScreenTemplate(Configs.LOOKER_DASHBOARD_PRODUCT_DETAILS);
};

/*
Generic screen for Looker embeded reports...
*/
const ScreenTemplate = (link: string) => {
  if (Util.getProduct()) {
    link = link.replace("MIN=", "MIN=" + Util.getProduct());
  }

  if (Util.getStore()) {
    link = link.replace("Store=", "Store=" + Util.getStore());
  }

  const [shouldHide, setShouldHide] = React.useState(false);
  const [url, setUrl] = React.useState<string>();

  React.useEffect(() => {
    setShouldHide(false);
    return () => {
      setShouldHide(true);
    };
  }, []);

  React.useEffect(() => {
    (async () => {
      const { base64url } = await getSignedUrlCall(
        "/embed/dashboards/" + link,
        Util.getIdToken()
      );
      if (base64url !== undefined) {
        setUrl(decodeBase64String(base64url));
      }
    })();
  }, []);

  if (url === null || url === undefined) {
    return (
      <IonContent placeholder={undefined}>
        <IonLoading isOpen={true} />
      </IonContent>
    );
  } else {
    // Do nothing...
  }

  return shouldHide ? null : <>{getWebView(url)}</>;
  //return shouldHide ? null : <IonContent>{getWebView(url)}</IonContent>;
};

const getWebView = (url: string) => {
  const props = {
    border: "none",
    frameBorder: "0",
    scrolling: "no",
    height: "970px",
    width: "1px",
    minWidth: "100%",
  };

  // src="about:blank" for blank page...
  return (
    <iframe
      id="looker-report"
      title="looker-report-title"
      src={url}
      data-src={url}
      style={{ ...props }}
    />
  );
};

export {
  ProductDetailScreen,
  AdvanceProductSearchScreen,
  SalesDashboardScreen,
};
