import {
  IonAccordion,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import React from "react";
import { Product } from "../../../models/Product";
import {
  getProductPriceCall,
  getProductPromotionCall,
} from "../../../services/CloudFunctionService";
import CurrentPriceBox from "./CurrentPriceBox";
import FuturePriceBox from "./FuturePriceBox";
import * as Util from "../../../components/Util";
import { Price } from "../../../models/Price";
import CompetitorPriceBox from "./CompetitorPriceBox";
import MultiBuyPromotionsBox from "./MultiBuyPromotionsBox";

// Price Section...
const PriceSection = (props: {
  product: Product | undefined;
  isCorporate: boolean;
}) => {
  const [product, setProduct] = React.useState<Product | undefined>(
    props.product
  );
  const [productMin] = React.useState<string | undefined>(
    props.product?.itemNumber
  );
  const [price, setPrice] = React.useState<Price>();
  const [isLoading, setLoading] = React.useState<boolean>();

  // Load data for all other components asynchronously...
  React.useEffect(() => {
    if (productMin) {
      setLoading(true);
      // Load Price information...
      const getProductPriceRes = getProductPriceCall(
        productMin,
        Util.getIdToken(),
        props.isCorporate
      );
      getProductPriceRes.then((response) => {
        if (Util.getDebugMode()) console.log(response);
        if (response.errorCode) {
          console.error(
            "Product Price Call Failed With Error " + response.errorMessage
          );
        } else {
          const json = product;
          if (json) {
            json.price = response;
            Util.setProductJson(json);
            setPrice(response);
            setProduct(json);
          }
        }
      });
    }
  }, [productMin]);

  // Load Promotion information...
  React.useEffect(() => {
    if (productMin) {
      if (price) {
        setLoading(true);
        const getProductPromotionRes = getProductPromotionCall(
          productMin,
          Util.getIdToken(),
          props.isCorporate
        );
        getProductPromotionRes.then((response) => {
          if (Util.getDebugMode()) console.log(response);
          if (response.errorCode) {
            console.error(
              "Store Space Call Failed With Error " + response.errorMessage
            );
          } else {
            const json = product;
            if (json) {
              json.promotion = response;
              Util.setProductJson(json);
              setProduct(json);
            }
          }
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }, [price]);

  const headerName = "Price & Promotion";
  return (
    <IonAccordion placeholder={undefined}>
      <IonItem
        slot="header"
        className="mpp-accordian-header-item"
        placeholder={undefined}
      >
        <IonLabel
          className="mpp-accordian-header-label"
          placeholder={undefined}
        >
          {headerName}
          {isLoading ? " (Loading...)" : ""}
        </IonLabel>
      </IonItem>
      <IonGrid className="mpp-ion-grid" slot="content" placeholder={undefined}>
        <IonRow
          className="mpp-ion-grid-row"
          aria-setsize={3}
          placeholder={undefined}
        >
          <IonCol className="mpp-ion-grid-infobox" placeholder={undefined}>
            <CurrentPriceBox {...product} />
          </IonCol>
          <IonCol className="mpp-ion-grid-infobox" placeholder={undefined}>
            <FuturePriceBox {...product} />
          </IonCol>
          <IonCol placeholder={undefined}>
            <MultiBuyPromotionsBox {...product} />
          </IonCol>
          <IonCol placeholder={undefined}>
            <CompetitorPriceBox {...product} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonAccordion>
  );
};

export default PriceSection;
