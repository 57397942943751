import { Product } from "../../../models/Product";
import * as Util from "../../../components/Util";
import { Favourite, Favourites } from "../../../models/Favourites";
import { useState } from "react";
import { IonIcon, IonToast, useIonAlert } from "@ionic/react";
import * as Icons from "ionicons/icons";
import { setFavourites } from "../../../services/FirebaseService";

const FavouriteIcon = (product: Product) => {
  const [isFavourite, setIsFavourite] = useState<boolean>(Util.getFavourite());
  const [showToast, setShowToast] = useState<boolean>(false);
  const [presentAlert] = useIonAlert();

  const toggleFavourites = () => {
    setIsFavourite(!isFavourite);
    let favourites: Favourites | undefined = Util.getFavourites();
    if (favourites) {
      // Do nothing...
    } else {
      favourites = { favourites: [] };
    }

    if (!isFavourite) {
      const favouritesArr = favourites?.favourites;
      let img: string = "";
      if (product?.imageUrl && product?.imageUrl.length > 0) {
        img = product?.imageUrl[0].url;
      }
      const prod: Favourite = {
        min: product?.itemNumber,
        name: product?.itemDescription,
        img: img,
      };

      favouritesArr?.push(prod);
      favourites.favourites = favouritesArr;
    } else {
      const favouritesArr = favourites?.favourites;
      const revisedFavArr: Favourite[] = [];
      favouritesArr?.forEach((favourite) => {
        if (favourite.min === product?.itemNumber) {
        } else {
          revisedFavArr.push(favourite);
        }
      });
      favourites.favourites = revisedFavArr;
    }

    Util.setFavourites(favourites);
    setFavourites(favourites);
  };

  const getIcon = (isFavourite: boolean) => {
    if (isFavourite) return Icons.star;
    else return Icons.starOutline;
  };

  const getMessage = (isFavourite: boolean) => {
    if (isFavourite)
      return (
        "Remove " +
        Util.initCap(product.itemDescription ? product.itemDescription : "") +
        " from favourites"
      );
    else
      return (
        "Add " +
        Util.initCap(product.itemDescription ? product.itemDescription : "") +
        " to favourites"
      );
  };

  const getConfirmMessage = (isFavourite: boolean) => {
    if (isFavourite) return "Product is added to favourites";
    else return "Product is removed from favourites";
  };

  return (
    <>
      <IonIcon
        icon={getIcon(isFavourite)}
        className="mpp-favourite-icon"
        onClick={() =>
          presentAlert({
            header: "Please Confirm!",
            message: getMessage(isFavourite),
            cssClass: "mpp-favourite-icon",
            buttons: [
              {
                text: "Cancel",
                role: "cancel",
                cssClass: "mpp-favourite-icon",
                handler: () => {},
              },
              {
                text: "Confirm",
                role: "confirm",
                cssClass: "mpp-favourite-icon",
                handler: () => {
                  toggleFavourites();
                  setShowToast(true);
                },
              },
            ],
          })
        }
        placeholder={undefined}
      />
      <IonToast
        icon={Icons.informationCircle}
        cssClass={"mpp-custom-toast-message"}
        position="top"
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={getConfirmMessage(isFavourite)}
        duration={5000}
        buttons={[
          {
            side: "end",
            icon: Icons.closeOutline,
            handler: () => setShowToast(false),
          },
        ]}
      />
    </>
  );
};

export default FavouriteIcon;
