import {
  IonBreadcrumb,
  IonBreadcrumbs,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonToggle,
  IonToolbar,
  useIonAlert,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { useState } from "react";
import * as Util from "../../components/Util";
import PageTemplate from "../PageTemplate";
import { getAllLocations } from "../../services/LocationService";
import CustomSearchAndSelect from "../../components/CustomSearchAndSelect";
import { setPreference } from "../../services/FirebaseService";

const Settings = () => {
  const [store, setStore] = useState(Util.getStore());
  const [email] = useState(Util.getUser().email);
  const [userPreference, setUserPreference] = useState(
    Util.getUserPreference()
  );
  const [presentAlert] = useIonAlert();
  const [presentSavingPreference, dismissSavingPreference] = useIonLoading();
  const [presentToast] = useIonToast();

  const setCorporateRetroDeals = (isEnable: boolean) => {
    const persistentPreference = userPreference.persistentPreference;
    if (persistentPreference) {
      persistentPreference.enableCorporateRetroDeals = isEnable;
      setUserPreference(userPreference);
    }
  };

  const setPriceAndPromotion = (isEnable: boolean) => {
    const persistentPreference = userPreference.persistentPreference;
    if (persistentPreference) {
      persistentPreference.enablePriceAndPromotion = isEnable;
      setUserPreference(userPreference);
    }
  };

  const setDepotOutboundAndSupplierInbound = (isEnable: boolean) => {
    const persistentPreference = userPreference.persistentPreference;
    if (persistentPreference) {
      persistentPreference.enableDepotOutboundAndSupplierInbound = isEnable;
      setUserPreference(userPreference);
    }
  };

  const setLocationAndSpace = (isEnable: boolean) => {
    const persistentPreference = userPreference.persistentPreference;
    if (persistentPreference) {
      persistentPreference.enableLocationAndSpace = isEnable;
      setUserPreference(userPreference);
    }
  };

  const setRetailPriceHistory = (isEnable: boolean) => {
    const persistentPreference = userPreference.persistentPreference;
    if (persistentPreference) {
      persistentPreference.enableRetailPriceHistory = isEnable;
      setUserPreference(userPreference);
    }
  };

  const setSalesAndFutureForecast = (isEnable: boolean) => {
    const persistentPreference = userPreference.persistentPreference;
    if (persistentPreference) {
      persistentPreference.enableSalesAndFutureForecast = isEnable;
      setUserPreference(userPreference);
    }
  };

  const setStockAvailability = (isEnable: boolean) => {
    const persistentPreference = userPreference.persistentPreference;
    if (persistentPreference) {
      persistentPreference.enableStockAvailability = isEnable;
      setUserPreference(userPreference);
    }
  };

  const setStockHistory = (isEnable: boolean) => {
    const persistentPreference = userPreference.persistentPreference;
    if (persistentPreference) {
      persistentPreference.enableStockHistory = isEnable;
      setUserPreference(userPreference);
    }
  };

  const setSupplierInformation = (isEnable: boolean) => {
    const persistentPreference = userPreference.persistentPreference;
    if (persistentPreference) {
      persistentPreference.enableSupplierInformation = isEnable;
      setUserPreference(userPreference);
    }
  };

  const setWasteAndMarkdown = (isEnable: boolean) => {
    const persistentPreference = userPreference.persistentPreference;
    if (persistentPreference) {
      persistentPreference.enableWasteAndMarkdown = isEnable;
      setUserPreference(userPreference);
    }
  };

  const save = () => {
    Util.setUserPreference(userPreference);
    presentSavingPreference({
      message: "Saving preference",
      cssClass: "mpp-custom-loading-spinner",
      spinner: "bubbles",
    });
    setPreference(userPreference.persistentPreference).then(() => {
      Util.delay(100).then(() => {
        dismissSavingPreference();
        presentToast({
          header: "Success",
          message: "Preference saved successfully.",
          duration: 1500,
          position: "top",
          cssClass: "mpp-custom-toast-message",
        });
      });
    });
  };

  const cancel = () => {
    setUserPreference(Util.getUserPreference());
  };

  const Section = (param: {
    section: string;
    initVal: boolean;
    onChangeMethod: (value: boolean) => void;
  }) => {
    return (
      <IonItem placeholder={undefined}>
        <IonLabel placeholder={undefined}>{param.section}</IonLabel>
        <IonToggle
          slot="end"
          class="mppEnableDisableToggle"
          checked={param.initVal}
          onIonChange={(e) => param.onChangeMethod(e.detail.checked)}
          placeholder={undefined}
        />
      </IonItem>
    );
  };

  const SectionSelector = () => {
    return (
      <IonRow placeholder={undefined}>
        <IonList placeholder={undefined}>
          <Section
            section="Corporate Retro Deals"
            initVal={
              userPreference.persistentPreference?.enableCorporateRetroDeals
                ? userPreference.persistentPreference?.enableCorporateRetroDeals
                : false
            }
            onChangeMethod={setCorporateRetroDeals}
          />
          <Section
            section="Depot Outbound & Supplier Inbound"
            initVal={
              userPreference.persistentPreference
                ?.enableDepotOutboundAndSupplierInbound
                ? userPreference.persistentPreference
                    ?.enableDepotOutboundAndSupplierInbound
                : false
            }
            onChangeMethod={setDepotOutboundAndSupplierInbound}
          />
          <Section
            section="Location & Space"
            initVal={
              userPreference.persistentPreference?.enableLocationAndSpace
                ? userPreference.persistentPreference?.enableLocationAndSpace
                : false
            }
            onChangeMethod={setLocationAndSpace}
          />
          <Section
            section="Price & Promotion"
            initVal={
              userPreference.persistentPreference?.enablePriceAndPromotion
                ? userPreference.persistentPreference?.enablePriceAndPromotion
                : false
            }
            onChangeMethod={setPriceAndPromotion}
          />
          <IonItem placeholder={undefined}>
            <IonLabel placeholder={undefined}>Product Overview</IonLabel>
          </IonItem>
          <Section
            section="Retail Price History"
            initVal={
              userPreference.persistentPreference?.enableRetailPriceHistory
                ? userPreference.persistentPreference?.enableRetailPriceHistory
                : false
            }
            onChangeMethod={setRetailPriceHistory}
          />
          <Section
            section="Sales & Future Forecast"
            initVal={
              userPreference.persistentPreference?.enableSalesAndFutureForecast
                ? userPreference.persistentPreference
                    ?.enableSalesAndFutureForecast
                : false
            }
            onChangeMethod={setSalesAndFutureForecast}
          />
          <Section
            section="Stock & Availability"
            initVal={
              userPreference.persistentPreference?.enableStockAvailability
                ? userPreference.persistentPreference?.enableStockAvailability
                : false
            }
            onChangeMethod={setStockAvailability}
          />
          <Section
            section="Stock History"
            initVal={
              userPreference.persistentPreference?.enableStockHistory
                ? userPreference.persistentPreference?.enableStockHistory
                : false
            }
            onChangeMethod={setStockHistory}
          />
          <Section
            section="Supplier Information"
            initVal={
              userPreference.persistentPreference?.enableSupplierInformation
                ? userPreference.persistentPreference?.enableSupplierInformation
                : false
            }
            onChangeMethod={setSupplierInformation}
          />
          <Section
            section="Waste & Markdown"
            initVal={
              userPreference.persistentPreference?.enableWasteAndMarkdown
                ? userPreference.persistentPreference?.enableWasteAndMarkdown
                : false
            }
            onChangeMethod={setWasteAndMarkdown}
          />
        </IonList>
      </IonRow>
    );
  };

  return (
    <PageTemplate id="settingsPage">
      <IonToolbar placeholder={undefined}>
        <IonBreadcrumbs
          maxItems={4}
          itemsAfterCollapse={3}
          placeholder={undefined}
        >
          <IonBreadcrumb
            className="mpp-breadcrumb"
            href="/home"
            placeholder={undefined}
          >
            Home
          </IonBreadcrumb>
          <IonBreadcrumb className="mpp-breadcrumb" placeholder={undefined}>
            Settings
          </IonBreadcrumb>
        </IonBreadcrumbs>
      </IonToolbar>
      <IonContent placeholder={undefined}>
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <IonLabel placeholder={undefined}>Store</IonLabel>
              <CustomSearchAndSelect
                initValue={store}
                options={getAllLocations()}
                onclickFn={function (value: string): void {
                  setStore(value);
                  userPreference.store = value;
                  Util.setStore(value);
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <IonLabel placeholder={undefined}>Email</IonLabel>
              <IonCol placeholder={undefined}>
                <IonLabel placeholder={undefined}>{email}</IonLabel>
              </IonCol>
            </IonCol>
          </IonRow>
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                Enable or Disable Sections
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <IonLabel
                style={{ fontSize: "small", color: "gray" }}
                placeholder={undefined}
              >
                Enable or disable section from loading in the MPP App by simply
                toggling the button
              </IonLabel>
            </IonCol>
          </IonRow>
          <SectionSelector />
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <IonButton
                className="mpp-button"
                onClick={cancel}
                placeholder={undefined}
              >
                Cancel
              </IonButton>
            </IonCol>
            <IonCol placeholder={undefined}>
              <IonButton
                className="mpp-button"
                onClick={() =>
                  presentAlert({
                    header: "Please Confirm!",
                    message:
                      "This action will change your preference impacting the available sections in the product details page",
                    cssClass: "mpp-favourite-icon",
                    buttons: [
                      {
                        text: "Cancel",
                        cssClass: "mpp-favourite-icon",
                        role: "cancel",

                        handler: () => {
                          cancel();
                        },
                      },
                      {
                        text: "Confirm",
                        cssClass: "mpp-favourite-icon",
                        role: "confirm",
                        handler: () => {
                          save();
                        },
                      },
                    ],
                  })
                }
                placeholder={undefined}
              >
                Save
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </PageTemplate>
  );
};

export { Settings };
