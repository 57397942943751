import {
  IonAccordion,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonToggle,
} from "@ionic/react";
import React from "react";
import { CustomRowTableBox } from "../../../components/CustomRowTableBox";
import { Product } from "../../../models/Product";
import { getCorporateProductSales } from "../../../services/LookerService";
import * as Util from "../../../components/Util";
import { DataValue } from "../../../components/DataTypes";

const SalesByChannelSection = (props: { product: Product | undefined }) => {
  interface SalesData {
    average_price?: DataValue;
    business_week_start_date?: DataValue;
    sales_units?: DataValue;
    takings?: DataValue;
    average_sales_units?: DataValue;
    average_takings?: DataValue;
  }

  // For sales forecast...
  const [salesDetailsWeekly, setSalesDetailsWeekly] =
    React.useState<SalesData[]>();
  const [salesDetailsDaily, setSalesDetailsDaily] =
    React.useState<SalesData[]>();

  const [productMin] = React.useState<string | undefined>(
    props.product?.itemNumber
  );
  const [weekOrDay, setWeekOrDay] = React.useState<boolean>(false);
  const [isLoading, setLoading] = React.useState<boolean>();
  const [channel, setChannel] = React.useState<string>(
    "Online,Wholesale,Supermarket"
  );

  const handleSalesServiceResponse = (
    response: {
      ok: boolean;
      value: any[];
      error: any;
    },
    isDay: boolean
  ) => {
    if (Util.getDebugMode()) console.log(JSON.stringify(response));
    if (response.ok) {
      const data: any[] = [];
      const rows = response.value;
      rows.forEach((row) => {
        const dataRows: DataValue[] = [];
        const averagePriceDataValue: DataValue = {
          key: "average_price",
          value: row["average_price"],
          type: "currency",
        };

        const businessWeekStartDateDataValue: DataValue = {
          key: "business_week_start_date",
          value: isDay
            ? row["morrisons_calendar.calendar_date_date"]
            : row["morrisons_calendar.business_week_start_date"],
          type: isDay ? "date" : "week",
        };

        const salesUnitsDataValue: DataValue = {
          key: "sales_units",
          value: row["unaudited_sales.sales_units"],
          type: "number",
        };

        const takingDataValue: DataValue = {
          key: "takings",
          value: row["unaudited_sales.takings"],
          type: "currency",
        };

        dataRows.push(
          businessWeekStartDateDataValue,
          takingDataValue,
          salesUnitsDataValue,
          averagePriceDataValue
        );

        data.push(dataRows);
      });

      if (isDay) {
        setSalesDetailsDaily(data);
      } else {
        setSalesDetailsWeekly(data);
      }
    } else {
      console.error(
        "No sales information found for " +
          productMin +
          " for store " +
          Util.getStore()
      );
    }
  };

  React.useEffect(() => {
    if (productMin && !weekOrDay) {
      setLoading(true);
      getCorporateProductSales(
        productMin,
        false,
        Util.getCountry() === Util.ALL_VALUES ? undefined : Util.getCountry(),
        Util.getRegion() === Util.ALL_VALUES ? undefined : Util.getRegion(),
        channel
      )
        .then((response) => {
          handleSalesServiceResponse(response, false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [productMin, weekOrDay, channel]);

  React.useEffect(() => {
    if (productMin && weekOrDay) {
      setLoading(true);
      getCorporateProductSales(
        productMin,
        true,
        Util.getCountry() === Util.ALL_VALUES ? undefined : Util.getCountry(),
        Util.getRegion() === Util.ALL_VALUES ? undefined : Util.getRegion(),
        channel
      )
        .then((response) => {
          handleSalesServiceResponse(response, true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [productMin, weekOrDay, channel]);

  const WeeklySalesDetailsContent = () => {
    const header = ["Week", "Takings (£)", "Volume (Units)", "Average Price"];
    const fixedColumns = ["Week"];
    const data = salesDetailsWeekly;
    const title = "Sales Information";

    return (
      <CustomRowTableBox
        title={title}
        header={header}
        data={data}
        fixedColumns={fixedColumns}
        csvFileName={
          "WeeklySalesReport" +
          "_MIN-" +
          productMin
            ?.replaceAll(" ", "-")
            .replaceAll("(", "")
            .replaceAll(")", "") +
          ".csv"
        }
      />
    );
  };

  const DailySalesDetailsContent = () => {
    const header = ["Day", "Takings (£)", "Volume (Units)", "Average Price"];
    const fixedColumns = ["Day"];
    const data = salesDetailsDaily;
    const title = "Sales Information";

    return (
      <CustomRowTableBox
        title={title}
        header={header}
        data={data}
        fixedColumns={fixedColumns}
        csvFileName={
          "DailySalesReport" +
          "_MIN-" +
          productMin
            ?.replaceAll(" ", "-")
            .replaceAll("(", "")
            .replaceAll(")", "") +
          ".csv"
        }
      />
    );
  };

  const getVisibleComponent = () => {
    if (!weekOrDay) {
      return (
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <WeeklySalesDetailsContent />
            </IonCol>
          </IonRow>
        </IonGrid>
      );
    } else {
      return (
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <DailySalesDetailsContent />
            </IonCol>
          </IonRow>
        </IonGrid>
      );
    }
  };

  const getSegmentLabelClass = (val: string) => {
    if (channel === val) {
      return "mpp-segment-lebel-selected";
    } else {
      return "mpp-segment-lebel";
    }
  };

  const headerName = "Sales By Channels";

  return (
    <IonAccordion aria-expanded placeholder={undefined}>
      <IonItem
        slot="header"
        className="mpp-accordian-header-item"
        placeholder={undefined}
      >
        <IonLabel
          className="mpp-accordian-header-label"
          placeholder={undefined}
        >
          {headerName}
          {isLoading ? " (Loading...)" : ""}
        </IonLabel>
      </IonItem>
      <IonList slot="content" placeholder={undefined}>
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol
              size="6"
              style={{ minWidth: "350px" }}
              placeholder={undefined}
            >
              <IonSegment
                value={channel}
                selectOnFocus={true}
                mode={"ios"}
                placeholder={undefined}
              >
                <IonSegmentButton
                  value="Online,Wholesale,Supermarket"
                  onClick={() => setChannel("Online,Wholesale,Supermarket")}
                  placeholder={undefined}
                >
                  <IonLabel
                    className={getSegmentLabelClass(
                      "Online,Wholesale,Supermarket"
                    )}
                    placeholder={undefined}
                  >
                    All
                  </IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="Online"
                  onClick={() => setChannel("Online")}
                  placeholder={undefined}
                >
                  <IonLabel
                    className={getSegmentLabelClass("Online")}
                    placeholder={undefined}
                  >
                    Online
                  </IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="Supermarket"
                  onClick={() => setChannel("Supermarket")}
                  placeholder={undefined}
                >
                  <IonLabel
                    className={getSegmentLabelClass("Supermarket")}
                    placeholder={undefined}
                  >
                    Supermarket
                  </IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="Wholesale"
                  onClick={() => setChannel("Wholesale")}
                  placeholder={undefined}
                >
                  <IonLabel
                    className={getSegmentLabelClass("Wholesale")}
                    placeholder={undefined}
                  >
                    Wholesale
                  </IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonCol>
          </IonRow>
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <IonLabel className="fixedLabelWeek" placeholder={undefined}>
                Week
              </IonLabel>
              <IonToggle
                class="mppToggle"
                checked={weekOrDay}
                onIonChange={(e) => setWeekOrDay(e.detail.checked)}
                placeholder={undefined}
              />
              <IonLabel className="fixedLabelDay" placeholder={undefined}>
                Day
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
        {getVisibleComponent()}
      </IonList>
    </IonAccordion>
  );
};

export default SalesByChannelSection;
