import * as React from "react";
import {
  IonAccordion,
  IonAccordionGroup,
  IonBreadcrumb,
  IonBreadcrumbs,
  IonIcon,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonToolbar,
  useIonLoading,
  useIonAlert,
} from "@ionic/react";
import * as Icons from "ionicons/icons";
import { getProductDetailsCall } from "../../services/CloudFunctionService";
import { Product } from "../../models/Product";
import "./styles.css";
import * as Util from "../../components/Util";
import PageTemplate from "../PageTemplate";
import { getProductDetails } from "../../services/LookerService";
import {
  BasicInformationBox,
  CommercialHierarchyBox,
  OtherInformationBox,
  ProductImageBoxCol,
  SupplierInformationBox,
  RetroDealSection,
  SupplierCostPrice,
  HFSSInformationBox,
  FavouriteIcon,
} from "../ProductDetails/PageComponents";
import NoDataFound from "../NoDataFound";
import { useHistory, useLocation } from "react-router-dom";
import { auth } from "../../services/FirebaseService";
import {
  SalesSection,
  SpaceSection,
  WasteMarkdownSection,
  StockAvailabilitySection,
  StockHistorySection,
  InboundOutboundSection,
  SalesByChannelSection,
  PriceSection,
  RetailPriceHistorySection,
} from "./PageComponents";

const CorporateProductDetails = () => {
  const barcode = Util.getProduct();
  let actualBarcode = barcode;
  const location = useLocation<{ caller: string; backlink: string }>();
  const [servceCallStatus, setServceCallStatus] = React.useState<boolean>();
  const [product, setProduct] = React.useState<Product>();
  const [isProductFound, setIsProductFound] = React.useState(false);
  const [presentLoading, dismissLoading] = useIonLoading();
  const history = useHistory();
  const [presentAlert] = useIonAlert();

  // Process input and get product informations : start
  React.useEffect(() => {
    presentLoading({
      message: "Loading",
      cssClass: "mpp-custom-loading-spinner",
      spinner: "bubbles",
    });

    auth.currentUser
      ?.getIdToken()
      .then((idToken) => {
        const mppUser = Util.getUser();
        mppUser.idToken = idToken;
        Util.setUser(mppUser);
      })
      .catch((error) => {
        console.warn("Session expired");
        alert("Session expired. New login required.");
        history.push({
          pathname: "/logout",
        });
      });

    actualBarcode = Util.getProperProductBarcode(barcode).finalBarcode;

    const getProductDetailsResponse = getProductDetailsCall(
      actualBarcode,
      Util.getIdToken()
    );
    getProductDetailsResponse.then((json) => {
      if (Util.getDebugMode()) console.log(JSON.stringify(json));
      if (json.itemNumber) {
        // Load other product information like TUC...
        const getProductDetailsRes = getProductDetails(json.itemNumber);
        getProductDetailsRes.then((response) => {
          if (Util.getDebugMode()) console.log(JSON.stringify(response));
          if (response.ok) {
            const element = response.value[0];
            json.tuc = element["product.tuc"];
            json.supplierName = element["supplier.supplier_name"];
            json.supplierEBSId = element["supplier.ebs_supplier_id"];
            json.supplierRMSId = element["supplier.rms_supplier_id"];
            json.supplierSiteId = element["supplier.ebs_supplier_site_id"];
            json.supplierSiteName = element["supplier.supplier_site_name"];
            json.supplierTermDescription =
              element["supplier.supplier_terms_description"];
            json.supplierDiscountPercentage =
              element["supplier.supplier_discount_percentage"];
            json.supplierEmail =
              element["supplier.Supplier_Email"] &&
              element["supplier.Supplier_Email"].toLocaleUpperCase() !==
                "UNKNOWN"
                ? element["supplier.Supplier_Email"]
                : null;
            json.supplierLeadContact =
              element["supplier.Supplier_Lead_Contact"] &&
              element["supplier.Supplier_Lead_Contact"].toLocaleUpperCase() !==
                "UNKNOWN"
                ? element["supplier.Supplier_Lead_Contact"]
                : null;

            let address = "";
            address =
              element["supplier.ADDR_LINE_1"] &&
              element["supplier.ADDR_LINE_1"].toLocaleUpperCase() !== "UNKNOWN"
                ? element["supplier.ADDR_LINE_1"]
                : "";
            address =
              element["supplier.ADDR_LINE_2"] &&
              element["supplier.ADDR_LINE_2"].toLocaleUpperCase() !== "UNKNOWN"
                ? address + ", " + element["supplier.ADDR_LINE_2"]
                : address;
            address =
              element["supplier.ADDR_LINE_3"] &&
              element["supplier.ADDR_LINE_3"].toLocaleUpperCase() !== "UNKNOWN"
                ? address + ", " + element["supplier.ADDR_LINE_3"]
                : address;
            address =
              element["supplier.CITY_NAME"] &&
              element["supplier.CITY_NAME"].toLocaleUpperCase() !== "UNKNOWN"
                ? address + ", " + element["supplier.CITY_NAME"]
                : address;
            address =
              element["supplier.POSTCODE"] &&
              element["supplier.POSTCODE"].toLocaleUpperCase() !== "UNKNOWN"
                ? address + ", " + element["supplier.POSTCODE"]
                : address;
            if (address.startsWith(", ")) address = address.substring(2);
            json.supplierAddress = address;

            json.supplierPrimaryCurrency =
              element["supplier.Primary_Currency"] &&
              element["supplier.Primary_Currency"].toLocaleUpperCase() !==
                "UNKNOWN"
                ? element["supplier.Primary_Currency"]
                : null;

            json.hfssFoodDrink = element["hfss_consolidated.food_drink"];
            json.hfssInScope = element["hfss_consolidated.hfss_in_scope"];
            json.hfssScore = element["hfss_consolidated.hfss_score"];
            json.hfssStatus = element["hfss_consolidated.hfss_status"];
            json.hfssCategory = element["hfss_consolidated.hfss_category_desc"];
          }
          // These two lines controls the rendering of the pages.
          // These must be set at the end of all the async calls.
          // Here we call the Product service to get most of the product features then
          // call the Looker product API to get the information that are missing in
          // product service call like TUC, Supplier, Retail Price etc.
          setProduct(json);
          Util.setProductJson(json);
        });
      }
      if (json.errorCode !== undefined) {
        setIsProductFound(false);
      } else {
        setIsProductFound(true);
      }
      setServceCallStatus(true);
    });
  }, []);
  // Process input and get product informations : end

  if (typeof servceCallStatus === "undefined") {
    return (
      <PageTemplate id="productDetailsTypePage">
        <></>
      </PageTemplate>
    );
  }

  if (!servceCallStatus) {
    return (
      <PageTemplate id="productDetailsTypePage">
        <></>
      </PageTemplate>
    );
  } else {
    if (!isProductFound) {
      dismissLoading();
      return (
        <PageTemplate id="productDetailsTypePage">
          <NoDataFound />
        </PageTemplate>
      );
    }
  }

  if (product?.itemNumber) {
    Util.setProduct(product?.itemNumber);
    dismissLoading();
  }

  if (product?.imageUrl) {
    //imageUril is an array of image objects...
    let url;
    if (product?.imageUrl.length > 0) {
      // Id if the length is not 0 then take the first item...
      url = product?.imageUrl[0].url;
    } else {
      url = undefined;
    }
    Util.setProductImageUrl(url);
  } else {
    Util.setProductImageUrl(undefined);
  }

  const getBackText = (): string | undefined => {
    if (location) {
      return location.state.caller;
    } else {
      return undefined;
    }
  };

  const getBackLink = (): string | undefined => {
    if (location) {
      return location.state.backlink;
    } else {
      return undefined;
    }
  };

  // Look and feel components : start
  const PageHeader = (props: { product: Product | undefined }) => {
    return (
      <IonToolbar placeholder={undefined}>
        <IonBreadcrumbs
          maxItems={4}
          itemsAfterCollapse={3}
          placeholder={undefined}
        >
          <IonBreadcrumb
            className="mpp-breadcrumb"
            href="/home"
            placeholder={undefined}
          >
            Home
          </IonBreadcrumb>
          <IonBreadcrumb
            className="mpp-breadcrumb"
            href={"" + getBackLink()}
            placeholder={undefined}
          >
            {getBackText()}
          </IonBreadcrumb>
          <IonBreadcrumb className="mpp-breadcrumb" placeholder={undefined}>
            MIN {props.product?.itemNumber}
            <IonIcon
              icon={Icons.location}
              className="mpp-favourite-icon"
              onClick={() =>
                presentAlert({
                  header: "Current Location",
                  message: Util.getCurrentCorporateLocation(),
                  buttons: [
                    {
                      text: "Close",
                      role: "close",
                      cssClass: "mpp-favourite-icon",
                    },
                  ],
                  cssClass: "mpp-favourite-icon",
                })
              }
              placeholder={undefined}
            />
          </IonBreadcrumb>
        </IonBreadcrumbs>
      </IonToolbar>
    );
  };

  const getPriceSection = () => {
    const countries = Util.getCountryFromRegion();
    return Util.getUserPreference().persistentPreference
      ?.enablePriceAndPromotion ? (
      <PriceSection
        product={product}
        isCorporate={true}
        countries={countries}
      />
    ) : (
      <></>
    );
  };

  const getSupplierCostPrice = () => {
    return Util.getUserPreference().persistentPreference
      ?.enableSupplierInformation ? (
      <SupplierCostPrice product={product} />
    ) : (
      <></>
    );
  };

  const getSalesSection = () => {
    return Util.getUserPreference().persistentPreference
      ?.enableSalesAndFutureForecast ? (
      <SalesSection product={product} />
    ) : (
      <></>
    );
  };

  const getSalesByChannelSection = () => {
    return Util.getUserPreference().persistentPreference
      ?.enableSalesAndFutureForecast ? (
      <SalesByChannelSection product={product} />
    ) : (
      <></>
    );
  };

  const getWasteMarkdownSection = () => {
    return Util.getUserPreference().persistentPreference
      ?.enableWasteAndMarkdown ? (
      <WasteMarkdownSection product={product} />
    ) : (
      <></>
    );
  };

  const getInboundOutboundSection = () => {
    return Util.getUserPreference().persistentPreference
      ?.enableDepotOutboundAndSupplierInbound ? (
      <InboundOutboundSection product={product} />
    ) : (
      <></>
    );
  };

  const getStockAvailabilitySection = () => {
    return Util.getUserPreference().persistentPreference
      ?.enableStockAvailability ? (
      <StockAvailabilitySection product={product} />
    ) : (
      <></>
    );
  };

  const getStockHistorySection = () => {
    return Util.getUserPreference().persistentPreference?.enableStockHistory ? (
      <StockHistorySection product={product} />
    ) : (
      <></>
    );
  };

  const getSpaceSection = () => {
    return Util.getUserPreference().persistentPreference
      ?.enableLocationAndSpace ? (
      <SpaceSection product={product} />
    ) : (
      <></>
    );
  };

  const getRetroDealSection = () => {
    return Util.getUserPreference().persistentPreference
      ?.enableCorporateRetroDeals ? (
      <RetroDealSection product={product} />
    ) : (
      <></>
    );
  };

  const getRetailPriceHistorySection = () => {
    const countries = Util.getCountryFromRegion();
    return Util.getUserPreference().persistentPreference
      ?.enableRetailPriceHistory ? (
      <RetailPriceHistorySection
        product={product}
        isCorporate={true}
        countries={countries}
      />
    ) : (
      <></>
    );
  };

  const PageContent = () => {
    return (
      <IonContent placeholder={undefined}>
        <IonAccordionGroup
          expand="compact"
          value={"Product Details"}
          placeholder={undefined}
        >
          <ProductOverviewSection />
          {getPriceSection()}
          {getSupplierCostPrice()}
          {getSalesSection()}
          {getSalesByChannelSection()}
          {getWasteMarkdownSection()}
          {getInboundOutboundSection()}
          {getStockAvailabilitySection()}
          {getStockHistorySection()}
          {getSpaceSection()}
          {getRetroDealSection()}
          {getRetailPriceHistorySection()}
        </IonAccordionGroup>
      </IonContent>
    );
  };

  // Non image flex boxes on the page...

  // Generic Component...

  // Accordian Sections On The Product Detail Page...
  // Product Overview Section...
  const ProductOverviewSection = () => {
    const headerName = "Product Overview";
    const Header = (props: any) => {
      return (
        <IonItem
          slot="header"
          className="mpp-accordian-header-item"
          placeholder={undefined}
        >
          <IonLabel
            className="mpp-accordian-header-label"
            placeholder={undefined}
          >
            {props.headerLabel}
          </IonLabel>
        </IonItem>
      );
    };

    return (
      <IonAccordion value="Product Details" placeholder={undefined}>
        <Header headerLabel={headerName} />
        <IonGrid
          className="mpp-ion-grid"
          slot="content"
          placeholder={undefined}
        >
          <IonRow
            className="mpp-ion-grid-row"
            aria-setsize={3}
            placeholder={undefined}
          >
            <ProductImageBoxCol {...product} />
            <IonCol className="mpp-ion-grid-infobox" placeholder={undefined}>
              <BasicInformationBox {...product} />
            </IonCol>
            <IonCol className="mpp-ion-grid-infobox" placeholder={undefined}>
              <OtherInformationBox {...product} />
            </IonCol>
            <IonCol className="mpp-ion-grid-infobox" placeholder={undefined}>
              <SupplierInformationBox {...product} />
            </IonCol>
            <IonCol className="mpp-ion-grid-infobox" placeholder={undefined}>
              <CommercialHierarchyBox {...product} />
            </IonCol>
            <IonCol className="mpp-ion-grid-infobox" placeholder={undefined}>
              <HFSSInformationBox {...product} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonAccordion>
    );
  };

  // Look and feel components : end

  return (
    <PageTemplate id="productDetailsTypePage">
      <PageHeader product={product} />
      <IonLabel className="mpp-product-name" placeholder={undefined}>
        {product?.itemDescription?.substring(0, 30)}
        <FavouriteIcon {...product} />
      </IonLabel>
      <PageContent />
    </PageTemplate>
  );
};

export default CorporateProductDetails;
