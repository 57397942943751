export const LBL_APPLICATION_NAME = "My Product Profile";
export const LBL_HOME = "Home";
export const LBL_PRODUCT_SCAN = "Product Scan";
export const LBL_STORE_PRODUCT_SEARCH = "Product Search - Individual Store";
export const LBL_CORPORATE_PRODUCT_SEARCH = "Product Search - All Stores";
export const LBL_SETTINGS = "Settings";
export const LBL_FAVOURITES = "Favourites";
export const LBL_INFORMATION = "Information";
export const LBL_COMING_SOON = "Coming Soon";
export const LBL_CHANGE_REQUEST = "MPP Change Request";
export const LBL_LOGIN = "Login";
export const LBL_LOGOUT = "Logout";
export const LBL_CLOSE = "Close";
export const LBL_CANCEL = "Cancel";
export const LBL_OKAY = "Okay";
export const LBL_FILTERS = "Filters";
export const LBL_RESULTS = "Results";
export const LBL_STORE = "Store";
export const LBL_CURRENT_STORE = "Current Store";
export const LBL_REGION = "Region";
export const LBL_COUNTRY = "Country";
export const LBL_PRODUCT_MIN = "MIN";
export const LBL_PRODUCT_MAN = "MAN";
export const LBL_PRODUCT_BARCODE = "Barcode";
export const LBL_PRIMARY_BARCODE = "Primary Barcode";
export const LBL_PRODUCT_NAME = "Product Name";
export const LBL_PRODUCT_TRADING_GROUP = "Trading Group";
export const LBL_PRODUCT_CATEGORY = "Category";
export const LBL_PRODUCT_GROUP = "Product Group";
export const LBL_PRODUCT_DIVISION = "Division";
export const LBL_PRODUCT_CLASS = "Class";
export const LBL_PRODUCT_SUBCLASS = "Subclass";
export const LBL_PRODUCT_BRAND = "Brand";
export const LBL_PRODUCT_SUPPLIER = "Supplier";
export const LBL_NAME = "Name";
export const LBL_DESCRIPTION = "Description";
export const LBL_PRODUCT_DESCRIPTION = "Product Description";
export const LBL_TUC = "TUC";
export const LBL_VAT_RATE = "VAT Rate";
export const LBL_ALCOHOL_BY_VOLUME = "Alcohol By Volume";
export const LBL_COMPITITOR = "Competitor";
export const LBL_BASE_PRICE = "Base Price";
export const LBL_OFFER_TYPE = "Offer Type";
export const LBL_OFFER_PRICE_PER_UNIT = "Offer Price Per Unit";
export const LBL_TORAL_OFFER_PRICE = "Total Offer Price";
export const LBL_OFFER_QUANTITY = "Offer Quantity";
export const LBL_PRICING_DATA_REFRESH = "Pricing Data Refreshed";

// Place holders...
export const LBL_PRODUCT_MIN_PLACEHOLDER = "Please Enter MIN Number";
export const LBL_PRODUCT_BARCODE_PLACEHOLDER = "Please Enter Barcode";
export const LBL_PRODUCT_NAME_PLACEHOLDER = "Please Enter Product Name";

// Messages...
export const MSG_LOADING_VALUES = "Loading Values...";
export const MSG_SEARCHING = "Searching";
export const MSG_RESET_FILTERS = "Reset The Filters";
export const MSG_SEARCHING_FOR_BARCODE = "Searching for barcode ";

// Sections...
export const LBL_SECTION_PRODUCT_DETAILS = "Product Details";
export const LBL_SECTION_PRODUCT_OVERVIEW = "Product Overview";
export const LBL_SECTION_PRODUCT_BASIC_INFORMATION = "Basic Information";
export const LBL_SECTION_PRODUCT_COMMERCIAL_HIERARCHY = "Commercial Hierarchy";
export const LBL_SECTION_PRODUCT_COMPITITOR_PRICE = "Compititor Price";

// Errors...
export const ERR_NOT_A_VALID_MIN = "Not A Valid MIN";
export const ERR_NOT_A_VALID_BARCODE = "Not A Valid Barcode";
export const ERR_NOT_A_VALID_PIN = "Not A Valid PIN";
export const ERR_SESSION_EXPIRED = "Session expired. New login required.";
