import { Product } from "../../../models/Product";
import { CustomRowTableBox } from "../../../components/CustomRowTableBox";
import { useState } from "react";
import { DataValue } from "../../../components/DataTypes";
import { MultiBuyPromotion, Promotion } from "../../../models/Promotion";
import { Price } from "../../../models/Price";

interface MultiBuyPromotionData {
  startDate?: DataValue;
  endDate?: DataValue;
  promotionName?: DataValue;
  type?: DataValue;
  promotionPrice?: DataValue;
}

const MultiBuyPromotionsBox = (props: {
  product?: Product;
  price?: Price;
  promotion?: Promotion;
}) => {
  const [productMin] = useState<string | undefined>(props.product?.itemNumber);
  let multiBuyPromotionData: MultiBuyPromotionData[] = [];
  const multiBuyPromotions: MultiBuyPromotion[] | undefined =
    props.promotion?.multiBuyPromotions;

  const handleServiceResponse = () => {
    const multiBuyPromotionDataRows: any[] = [];
    if (multiBuyPromotions)
      multiBuyPromotions.forEach((multiBuyPromotion) => {
        const multiBuyPromotionDataRow: DataValue[] = [];

        const startDate: DataValue = {
          key: "startDate",
          value:
            multiBuyPromotion && multiBuyPromotion.startDate
              ? multiBuyPromotion.startDate
              : undefined,
          type: "longdate",
        };

        const endDate: DataValue = {
          key: "endDate",
          value:
            multiBuyPromotion && multiBuyPromotion.endDate
              ? multiBuyPromotion.endDate
              : undefined,
          type: "longdate",
        };

        const promotionName: DataValue = {
          key: "promotionName",
          value:
            multiBuyPromotion && multiBuyPromotion.promotionName
              ? multiBuyPromotion.promotionName
              : undefined,
          type: "string",
        };

        const promotionPrice: DataValue = {
          key: "promotionPrice",
          value:
            multiBuyPromotion && multiBuyPromotion.promotionPrice
              ? multiBuyPromotion.promotionPrice
              : undefined,
          type: "currency",
        };

        // Multi Buy Promotion Information Rows
        multiBuyPromotionDataRow.push(
          promotionName,
          promotionPrice,
          startDate,
          endDate
        );
        multiBuyPromotionDataRows.push(multiBuyPromotionDataRow);
      });
    multiBuyPromotionData = multiBuyPromotionDataRows;
  };

  handleServiceResponse();

  const header = [
    "Promotion Name",
    "Promotional Price",
    "Start Date",
    "End Date",
  ];
  const fixedColumns = ["Promotion Price"];
  const data = multiBuyPromotionData;
  const title = "Multi Buy Promotion Description";
  return (
    <CustomRowTableBox
      title={title}
      header={header}
      data={data}
      fixedColumns={fixedColumns}
      csvFileName={
        "MultiBuyPromotion" +
        "_MIN-" +
        productMin
          ?.replaceAll(" ", "-")
          .replaceAll("(", "")
          .replaceAll(")", "") +
        ".csv"
      }
    />
  );
};

export default MultiBuyPromotionsBox;
