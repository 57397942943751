import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonNavLink,
  useIonModal,
  IonIcon,
  useIonLoading,
  IonLabel,
} from "@ionic/react";
import * as Icons from "ionicons/icons";
import { useEffect, useState } from "react";
import * as Util from "../../../components/Util";
import { getCorporateStoresPlanned } from "../../../services/LookerService";
import "./styles.css";

const PlannedStores = (props: {
  productMin: string;
  value: string | number | undefined;
  modalAdditionalValues?: { planogramClass?: string | number };
}) => {
  const [productMin] = useState<string | undefined>(props.productMin);
  const [plannedStores, setPlannedStores] = useState<{ store: string }[]>([]);
  const [plannedTitile] = useState<string>(
    props.modalAdditionalValues?.planogramClass
      ? props.modalAdditionalValues?.planogramClass?.toString()
      : "Details"
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [presentLoading, dismissLoading] = useIonLoading();

  const ModalExample = ({
    onDismiss,
  }: {
    onDismiss: (
      data?: string | null | undefined | number,
      role?: string
    ) => void;
  }) => {
    return (
      <>
        <IonHeader placeholder={undefined}>
          <IonToolbar placeholder={undefined}>
            <IonGrid placeholder={undefined}>
              <IonRow placeholder={undefined}>
                <IonLabel className="mpp_modal_title" placeholder={undefined}>
                  {plannedTitile}
                </IonLabel>
              </IonRow>
              <IonRow placeholder={undefined}>
                <IonLabel
                  className="mpp_modal_sub_title"
                  placeholder={undefined}
                >
                  Number of Stores ({plannedStores.length})
                </IonLabel>
              </IonRow>
            </IonGrid>
            <IonButtons slot="end" placeholder={undefined}>
              <IonIcon
                icon={Icons.closeOutline}
                onClick={dismiss}
                placeholder={undefined}
              />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent placeholder={undefined}>
          <IonItem lines="none" placeholder={undefined}>
            <IonGrid className="custom-table-box-grid" placeholder={undefined}>
              {plannedStores?.map((strval) => {
                return (
                  <IonRow
                    key={counter++}
                    className="custom-table-box-grid-column-row"
                    placeholder={undefined}
                  >
                    <IonCol
                      className="custom-table-box-grid-column-header"
                      placeholder={undefined}
                    >
                      <div className={"selectable"}>{strval.store}</div>
                    </IonCol>
                  </IonRow>
                );
              })}
            </IonGrid>
          </IonItem>
        </IonContent>
      </>
    );
  };

  useEffect(() => {
    if (isLoading) {
      const arr: { store: string }[] = [];
      if (productMin && plannedStores.length === 0) {
        presentLoading({
          message: "Fetching planned stores",
          cssClass: "mpp-custom-loading-spinner",
          spinner: "bubbles",
        });
        getCorporateStoresPlanned(
          productMin,
          Util.getCountry() === Util.ALL_VALUES ? undefined : Util.getCountry(),
          Util.getRegion() === Util.ALL_VALUES ? undefined : Util.getRegion(),
          props.modalAdditionalValues?.planogramClass?.toString()
        )
          .then((response) => {
            if (response.ok) {
              const rows = response.value;
              rows.forEach((row) => {
                arr.push({
                  store: row["location.store_description"],
                });
              });
              setPlannedStores(arr);
            }
          })

          .finally(() => {
            setLoading(false);
            dismissLoading();
          });
      }
    }
  }, [isLoading]);

  const [present, dismiss] = useIonModal(ModalExample, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });

  const openModal = () => {
    present();
  };

  let counter = 0;
  return (
    <>
      <IonNavLink
        className="mpp-min-link"
        onClick={() => {
          setLoading(true);
          openModal();
        }}
        placeholder={undefined}
      >
        {props.value ? Util.initCap(props.value.toString()) : props.value}
      </IonNavLink>
    </>
  );
};

export default PlannedStores;
