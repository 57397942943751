import { IonBreadcrumb, IonBreadcrumbs, IonToolbar } from "@ionic/react";

import { SalesDashboardScreen } from "../LookerEmbededScreens";
import PageTemplate from "../PageTemplate";

const SalesDashboard = () => {
  return (
    <PageTemplate id="productDetailsTypePage">
      <IonToolbar placeholder={undefined}>
        <IonBreadcrumbs
          maxItems={4}
          itemsAfterCollapse={3}
          placeholder={undefined}
        >
          <IonBreadcrumb href="/home" placeholder={undefined}>
            Home
          </IonBreadcrumb>
          <IonBreadcrumb placeholder={undefined}>Sales Dashboard</IonBreadcrumb>
        </IonBreadcrumbs>
      </IonToolbar>
      <SalesDashboardScreen />
    </PageTemplate>
  );
};

export default SalesDashboard;
