// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  getFirestore,
  DocumentSnapshot,
  DocumentData,
  QuerySnapshot,
} from "firebase/firestore";
import * as Util from "../components/Util";

import {
  getAuth,
  GoogleAuthProvider,
  signOut,
  signInWithRedirect,
  getRedirectResult,
  setPersistence,
} from "firebase/auth";

import * as Configs from "../components/Configs";
// Your web app's Firebase configuration
const firebaseConfig = Configs.firebaseConfig;

// Initialize Firebase
const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  login_hint: "user@morrisonsplc.co.uk",
});

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getFirestore(app);
const storage = getStorage(app);

const getSignedFileURL = async (filename: string) => {
  return getDownloadURL(ref(storage, filename));
};

auth.languageCode = "en";

/// All access methods...
const getStores = async (): Promise<DocumentSnapshot<DocumentData>> => {
  return getDocument("appconfig", "storegeo");
};

const getMessages = async (): Promise<QuerySnapshot<DocumentData>> => {
  return getDocumentCollection("messages");
};

const getSystemMessage = async (): Promise<DocumentSnapshot<DocumentData>> => {
  return getDocument("messages", "sysmsg");
};

const getFavourites = async (): Promise<DocumentSnapshot<DocumentData>> => {
  return getDocument("favourites", Util.getEmail());
};

const setFavourites = async (data: any): Promise<void> => {
  return setDocument("favourites", Util.getEmail(), data);
};

/// User specific methods...
const getPreference = async (): Promise<DocumentSnapshot<DocumentData>> => {
  return getDocument("preferences", Util.getEmail());
};

const setPreference = async (data: any): Promise<void> => {
  return setDocument("preferences", Util.getEmail(), data);
};

///// Generic methods do not change //////
const getDocumentCollection = async (
  collectionName: string
): Promise<QuerySnapshot<DocumentData>> => {
  return getDocs(collection(database, collectionName));
};

const getDocument = async (
  collectionName: string,
  documentId: string
): Promise<DocumentSnapshot<DocumentData>> => {
  return getDoc(doc(database, collectionName, documentId));
};

const setDocument = async (
  collectionName: string,
  documentId: string,
  data: any
): Promise<void> => {
  return setDoc(doc(database, collectionName, documentId), data);
};

///// Generic methods do not change //////

export {
  auth,
  provider,
  signOut,
  signInWithRedirect,
  getRedirectResult,
  setPersistence,
  database,
  getStores,
  getMessages,
  getPreference,
  getSystemMessage,
  setPreference,
  getFavourites,
  setFavourites,
  getSignedFileURL,
};
