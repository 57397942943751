import { IonGrid, IonRow, IonCol, IonImg } from "@ionic/react";

const NoDataFound = () => {
  return (
    <IonGrid
      style={{
        paddingTop: "10%",
      }}
      placeholder={undefined}
    >
      <IonRow style={{ width: "100%" }} placeholder={undefined}>
        <IonCol style={{ width: "5%" }} placeholder={undefined}></IonCol>
        <IonCol style={{ width: "90%" }} placeholder={undefined}>
          <IonImg
            style={{ height: "200px", width: "400px" }}
            src={require("../../assets/images/product-not-found.jpg")}
            placeholder={undefined}
          />
        </IonCol>
        <IonCol style={{ width: "5%" }} placeholder={undefined}></IonCol>
      </IonRow>
      <IonRow style={{ width: "100%" }} placeholder={undefined}>
        <IonCol
          style={{ width: "90%", paddingLeft: "10%" }}
          placeholder={undefined}
        >
          <h2
            style={{
              color: "red",
              width: "100%",
              display: "flex",
            }}
          >
            <b>Sorry No Data Found...</b>
          </h2>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default NoDataFound;
