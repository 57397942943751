import { CustomTableBox } from "../../../components/CustomTableBox";
import { Product } from "../../../models/Product";
import * as Util from "../../../components/Util";
import { getProductMergin } from "../../../services/LookerService";
import { useEffect, useState } from "react";
import { Price } from "../../../models/Price";
import { Promotion } from "../../../models/Promotion";

const CurrentPriceBox = (props: {
  product?: Product;
  price?: Price;
  promotion?: Promotion;
}) => {
  const [productMin] = useState<string | undefined>(props.product?.itemNumber);

  const [productMargin, setProductMargin] = useState<number>();
  const [productMarginPct, setProductMarginPct] = useState<number>();

  useEffect(() => {
    if (productMin) {
      getProductMergin(productMin).then((response) => {
        if (response.ok) {
          const rows = response.value;
          rows.forEach((row) => {
            setProductMargin(row["current_margin.Current_Margin"]);
            setProductMarginPct(row["current_margin.Current_Margin_Pct"]);
          });
        }
      });
    }
  }, [productMin]);

  const data = {
    header: "Current Price",
    rows: [
      {
        key: "Current Live Retail Price",
        value: props.promotion?.currentPromotion?.promotionPrice
          ? Util.getUKPriceFormat(
              props.promotion?.currentPromotion?.promotionPrice
            )
          : props.price?.currentPrice?.regularPrice
          ? Util.getUKPriceFormat(props.price?.currentPrice?.regularPrice)
          : Util.NOT_AVAILABLE,
      },
      {
        key: "Retail Price Live Date",
        value: Util.yyyymmddToDdmmyyyy(
          props.promotion?.currentPromotion?.promotionPrice
            ? props.promotion?.currentPromotion?.startDate
            : props.price?.currentPrice?.effectiveDate
            ? props.price?.currentPrice?.effectiveDate
            : Util.NOT_AVAILABLE
        ),
      },
      {
        key: "Base Price",
        value: props.price?.currentPrice?.regularPrice
          ? Util.getUKPriceFormat(props.price?.currentPrice?.regularPrice)
          : Util.NOT_AVAILABLE,
      },
      {
        key: "Base Price Live Date",
        value: Util.yyyymmddToDdmmyyyy(
          props.price?.currentPrice?.effectiveDate
            ? props.price?.currentPrice?.effectiveDate
            : Util.NOT_AVAILABLE
        ),
      },
      {
        key: "Margin WTD Per Unit",
        value: productMargin
          ? Util.getUKPriceFormat(productMargin)
          : Util.NOT_AVAILABLE,
      },
      {
        key: "Margin % WTD Per Unit",
        value: productMarginPct
          ? Util.getUKPercentageFormat(productMarginPct)
          : Util.NOT_AVAILABLE,
      },
      {
        key: "Promotional Price",
        value: props.promotion?.currentPromotion?.promotionPrice
          ? Util.getUKPriceFormat(
              props.promotion?.currentPromotion?.promotionPrice
            )
          : Util.NOT_AVAILABLE,
      },
      {
        key: "Promotional Price Start Date",
        value: Util.yyyymmddToDdmmyyyy(
          props.promotion?.currentPromotion?.startDate
            ? props.promotion?.currentPromotion?.startDate
            : Util.NOT_AVAILABLE
        ),
      },
      {
        key: "Promotional Price End Date",
        value: Util.yyyymmddToDdmmyyyy(
          props.promotion?.currentPromotion?.endDate
            ? props.promotion?.currentPromotion?.endDate
            : Util.NOT_AVAILABLE
        ),
      },
    ],
  };

  return <CustomTableBox {...data} />;
};

export default CurrentPriceBox;
