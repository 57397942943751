import { Product } from "../../../models/Product";
import * as Util from "../../../components/Util";
import { CustomRowTableBox } from "../../../components/CustomRowTableBox";
import { useEffect, useState } from "react";
import { getCompetitorPrice } from "../../../services/LookerService";
import { DataValue } from "../../../components/DataTypes";
import { lables } from "../../../components/Labels";

interface CompetitorPriceData {
  product_description?: DataValue;
  competitor_name?: DataValue;
  offer_type?: DataValue;
  base_price?: DataValue;
  offer_price?: DataValue;
  offer_quantity?: DataValue;
  total_offer_price?: DataValue;
  last_updated?: DataValue;
}

const CompetitorPriceBox = (product: Product) => {
  const [productMin] = useState<string | undefined>(product?.itemNumber);
  const [competitorPrice, setcompetitorPrice] =
    useState<CompetitorPriceData[]>();

  // Load competitor price...
  const handleServiceResponse = (response: {
    ok: boolean;
    value: any[];
    error: any;
  }) => {
    if (Util.getDebugMode()) console.log(JSON.stringify(response));
    if (response.ok) {
      const data_by_competitor_price: any[] = [];

      const rows = response.value;
      rows.forEach((row) => {
        const dataRows_by_competitor_price: DataValue[] = [];

        const product_description: DataValue = {
          key: "product_description",
          value:
            row["assosia_cmptr_price_with_min.product_description"] &&
            row["assosia_cmptr_price_with_min.product_description"] !== ""
              ? row["assosia_cmptr_price_with_min.product_description"]
              : undefined,
          type: "string",
        };

        const competitor_name: DataValue = {
          key: "competitor_name",
          value:
            row["assosia_cmptr_price_with_min.competitor_name"] &&
            row["assosia_cmptr_price_with_min.competitor_name"] !== ""
              ? row["assosia_cmptr_price_with_min.competitor_name"]
              : undefined,
          type: "string",
        };

        const offer_type: DataValue = {
          key: "offer_type",
          value:
            row["assosia_cmptr_price_with_min.offer_type"] &&
            row["assosia_cmptr_price_with_min.offer_type"] !== ""
              ? row["assosia_cmptr_price_with_min.offer_type"]
              : undefined,
          type: "string",
          alternalte: "-",
        };

        const base_price: DataValue = {
          key: "base_price",
          value:
            row["assosia_cmptr_price_with_min.base_price"] &&
            row["assosia_cmptr_price_with_min.base_price"] !== ""
              ? parseFloat(row["assosia_cmptr_price_with_min.base_price"])
              : undefined,
          type: "currency",
          alternalte: "-",
        };

        const offer_price: DataValue = {
          key: "offer_price",
          value:
            row["assosia_cmptr_price_with_min.offer_price"] &&
            row["assosia_cmptr_price_with_min.offer_price"] !== ""
              ? parseFloat(row["assosia_cmptr_price_with_min.offer_price"])
              : undefined,
          type: "currency",
          alternalte: "-",
        };

        const offer_quantity: DataValue = {
          key: "offer_quantity",
          value:
            row["assosia_cmptr_price_with_min.mix_and_match_quantity"] &&
            row["assosia_cmptr_price_with_min.mix_and_match_quantity"] !== ""
              ? row["assosia_cmptr_price_with_min.mix_and_match_quantity"]
              : undefined,
          type: "number",
          alternalte: "-",
        };

        const total_offer_price: DataValue = {
          key: "total_offer_price",
          value:
            offer_price.value && offer_quantity.value
              ? parseFloat(row["assosia_cmptr_price_with_min.offer_price"]) *
                parseFloat(
                  row["assosia_cmptr_price_with_min.mix_and_match_quantity"]
                )
              : undefined,
          type: "currency",
          alternalte: "-",
        };

        const last_updated: DataValue = {
          key: "last_updated",
          value:
            row["assosia_cmptr_price_with_min.file_date"] &&
            row["assosia_cmptr_price_with_min.file_date"] !== ""
              ? row["assosia_cmptr_price_with_min.file_date"]
              : undefined,
          type: "longdate",
          alternalte: "-",
        };

        // Competitor Price Information Rows
        dataRows_by_competitor_price.push(
          product_description,
          competitor_name,
          base_price,
          offer_type,
          offer_price,
          offer_quantity,
          total_offer_price,
          last_updated
        );
        data_by_competitor_price.push(dataRows_by_competitor_price);
      });
      setcompetitorPrice(data_by_competitor_price);
    } else {
      console.error(
        "No sales information found for " +
          productMin +
          " for store " +
          Util.getStore()
      );
    }
  };

  useEffect(() => {
    if (productMin) {
      const getProductPriceRes = getCompetitorPrice(productMin);
      getProductPriceRes.then((response) => {
        handleServiceResponse(response);
      });
    }
  }, [productMin]);

  const header = [
    lables.LBL_PRODUCT_DESCRIPTION,
    lables.LBL_COMPITITOR,
    lables.LBL_BASE_PRICE,
    lables.LBL_OFFER_TYPE,
    lables.LBL_OFFER_PRICE_PER_UNIT,
    lables.LBL_OFFER_QUANTITY,
    lables.LBL_TORAL_OFFER_PRICE,
    lables.LBL_PRICING_DATA_REFRESH,
  ];
  const fixedColumns = [lables.LBL_PRODUCT_DESCRIPTION];
  const data = competitorPrice;
  const title = lables.LBL_SECTION_PRODUCT_COMPITITOR_PRICE;
  return (
    <CustomRowTableBox
      title={title}
      header={header}
      data={data}
      fixedColumns={fixedColumns}
      csvFileName={
        "CompititorPrice" +
        "_MIN-" +
        productMin
          ?.replaceAll(" ", "-")
          .replaceAll("(", "")
          .replaceAll(")", "") +
        ".csv"
      }
    />
  );
};

export default CompetitorPriceBox;
