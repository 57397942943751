import {
  IonAccordion,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import React from "react";
import { Product } from "../../../models/Product";
import { getProductRetailPriceHistoryCall } from "../../../services/CloudFunctionService";
import * as Util from "../../../components/Util";
import { RetailPriceHistory } from "../../../models/StoreSpace";
import { CustomRowTableBox } from "../../../components/CustomRowTableBox";
import { DataValue } from "../../../components/DataTypes";

interface RetailPriceData {
  effectiveDate?: DataValue;
  endDate?: DataValue;
  type?: DataValue;
  retailPrice?: DataValue;
  /*startDate?: DataValue;*/
}

// Space Section...
const RetailPriceHistorySection = (props: {
  product: Product | undefined;
  isCorporate: boolean;
  countries: string[];
}) => {
  const [productMin] = React.useState<string | undefined>(
    props.product?.itemNumber
  );

  const [retailPriceData, setRetailPriceData] =
    React.useState<RetailPriceData[]>();

  const [isLoading, setLoading] = React.useState<boolean>();
  const [country, setCountry] = React.useState<string>(props.countries[0]);

  // Load data for all other components asynchronously...
  React.useEffect(() => {
    if (productMin) {
      setLoading(true);
      const getProductRetailPriceHistoryRes = getProductRetailPriceHistoryCall(
        productMin,
        Util.getIdToken(),
        props.isCorporate,
        country
      );
      getProductRetailPriceHistoryRes
        .then((response) => {
          if (response.errorCode) {
            console.error(
              "Get Product Retail Price History Call Failed With Error " +
                response.errorMessage
            );
          } else {
            processProductRetailPriceHistoryRes(response);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [productMin, country]);

  const processProductRetailPriceHistoryRes = (
    response: RetailPriceHistory
  ) => {
    const retailPrices = response?.retailPrices;
    if (retailPrices) {
      const data_retail_price_history: any[] = [];
      retailPrices.forEach((inRetailPrice) => {
        const dataRows: DataValue[] = [];

        const effectiveDate: DataValue = {
          key: "effectiveDate",
          value: inRetailPrice.effectiveDate,
          type: "longdate",
        };

        const type: DataValue = {
          key: "type",
          value: inRetailPrice.type,
          type: "string",
        };

        const retailPrice: DataValue = {
          key: "retailPrice",
          value: inRetailPrice.retailPrice,
          type: "currency",
        };

        const endDate: DataValue = {
          key: "endDate",
          value: inRetailPrice.endDate,
          type: "longdate",
          alternalte: "-",
        };

        dataRows.push(
          effectiveDate,
          endDate,
          type,
          retailPrice /*, startDate*/
        );
        data_retail_price_history.push(dataRows);
      });
      setRetailPriceData(data_retail_price_history);
    }
  };

  const RetailPriceHistoryContent = (props: {
    data: RetailPriceData[] | undefined;
  }) => {
    const header = [
      "Effective Date",
      "End Date",
      "Price Type",
      "Retail Price" /**, Start Date*/,
    ];
    const fixedColumns = ["Effective Date"];
    if (Util.getDebugMode()) console.log(JSON.stringify(props.data));

    const data = props.data;
    const title = "Retail Price History";
    return (
      <CustomRowTableBox
        title={title}
        header={header}
        data={data}
        fixedColumns={fixedColumns}
        csvFileName={
          "RetailPriceHistoryReport" +
          "_MIN-" +
          productMin
            ?.replaceAll(" ", "-")
            .replaceAll("(", "")
            .replaceAll(")", "") +
          ".csv"
        }
        allCapital={false}
        allAlignCenter={false}
      />
    );
  };

  const getComponents = () => {
    return (
      <IonGrid className="mpp-ion-grid" slot="content" placeholder={undefined}>
        <IonRow
          className="mpp-ion-grid-row"
          aria-setsize={3}
          placeholder={undefined}
        >
          <IonCol className="mpp-ion-grid-infobox" placeholder={undefined}>
            <RetailPriceHistoryContent {...{ data: retailPriceData }} />
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  };

  const getSegmentLabelClass = (val: string) => {
    if (country === val) {
      return "mpp-segment-lebel-selected";
    } else {
      return "mpp-segment-lebel";
    }
  };

  const headerName = "Retail Price History";
  return (
    <IonAccordion placeholder={undefined}>
      <IonItem
        slot="header"
        className="mpp-accordian-header-item"
        placeholder={undefined}
      >
        <IonLabel
          className="mpp-accordian-header-label"
          placeholder={undefined}
        >
          {headerName}
          {isLoading ? " (Loading...)" : ""}
        </IonLabel>
      </IonItem>
      <IonList slot="content" placeholder={undefined}>
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol
              size="6"
              style={{ minWidth: "350px" }}
              placeholder={undefined}
            >
              <IonSegment
                value={country}
                selectOnFocus={true}
                mode={"ios"}
                placeholder={undefined}
              >
                {props.countries.map((val) => {
                  return (
                    <IonSegmentButton
                      value={val}
                      onClick={() => setCountry(val)}
                      placeholder={undefined}
                    >
                      <IonLabel
                        className={getSegmentLabelClass(val)}
                        placeholder={undefined}
                      >
                        {Util.initCap(val)}
                      </IonLabel>
                    </IonSegmentButton>
                  );
                })}
              </IonSegment>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonList>
      {getComponents()}
    </IonAccordion>
  );
};

export default RetailPriceHistorySection;
