import AuthenticatedLanding from "../AuthenticatedLanding";
import Login from "../../components/FirebaseLogin";
import { Redirect, Route, Switch } from "react-router-dom";
import { IonReactRouter } from "@ionic/react-router";
import ProductDetails from "../ProductDetails";
import Scan from "../Scan";
import Logout from "../../components/FirebaseLogout";
import SalesDashboard from "../SalesDashboard";
import AdvanceSearch from "../AdvanceSearch";
import { Settings } from "../Settings";
import { useEffect, useState } from "react";
import * as Util from "../../components/Util";
import { Favourites } from "../Favourites";
import CorporateAdvanceSearch from "../CorporateAdvanceSearch";
import CorporateProductDetails from "../CorporateProductDetails";

const MainRouter = () => {
  return (
    <IonReactRouter>
      <MainRouterOutlet />
    </IonReactRouter>
  );
};

const MainRouterOutlet = () => {
  return (
    <Switch>
      <PublicRouter path="/" exact component={Login} />
      <PublicRouter path="/login" exact component={Login} />
      <PrivateRouter path="/logout" component={Logout} exact />
      <PrivateRouter path="/home" component={AuthenticatedLanding} exact />
      <PrivateRouter path="/scan" component={Scan} exact />
      <PrivateRouter path="/salesdashboard" component={SalesDashboard} exact />
      <PrivateRouter path="/settings" component={Settings} exact />
      <PrivateRouter path="/favourites" component={Favourites} exact />
      <PrivateRouter path="/advancesearch" component={AdvanceSearch} exact />
      <PrivateRouter
        path="/corporateadvancesearch"
        component={CorporateAdvanceSearch}
        exact
      />
      <PrivateRouter path="/productdetails" component={ProductDetails} exact />
      <PrivateRouter
        path="/corporateproductdetails"
        component={CorporateProductDetails}
        exact
      />
    </Switch>
  );
};

const PublicRouter = ({ component: Component, ...rest }: any) => {
  const [isSignedIn] = useState(Util.getUser().isLoggedIn);

  useEffect(() => {
    // Do nothing...
  }, [isSignedIn]);

  return (
    <>
      <Route
        render={(props) =>
          !isSignedIn ? <Component {...props} /> : <Redirect exact to="/home" />
        }
      />
    </>
  );
};

const PrivateRouter = ({ component: Component, ...rest }: any) => {
  const [isSignedIn] = useState(Util.getUser().isLoggedIn);

  useEffect(() => {
    // Do nothing...
  }, [isSignedIn]);

  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          isSignedIn ? <Component {...props} /> : <Redirect exact to="/" />
        }
      />
    </>
  );
};

export { MainRouterOutlet, MainRouter };
