import {
  IonAvatar,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRow,
  IonText,
  IonToolbar,
} from "@ionic/react";
import "./styles.css";
import { lables } from "../../components/Labels";
import * as Icons from "ionicons/icons";
import * as Util from "../../components/Util";

const Menu = (props: any) => {
  return (
    <IonMenu
      side="start"
      contentId={props.contentId}
      menuId="main"
      type="overlay"
      placeholder={undefined}
    >
      <MenuHeader />
      <IonContent placeholder={undefined}>
        <IonList placeholder={undefined}>
          <HomeMenuButton />
          <CorporateAdvanceSearchMenuButton />
          <AdvanceSearchMenuButton />
          <ScanMenuButton />
          <FavouritesMenuButton />
          <SettingsMenuButton />
          <InfoMenuButton />
          <SuggestMenuButton />
          <ComingSoonMenuButton />
          <LogOutMenuButton />
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

const MenuHeader = () => {
  const firstName = Util.getGivenName();
  const lastName = Util.getFamilyName();
  const image = Util.getImageUrl();

  return (
    <IonHeader placeholder={undefined}>
      <IonToolbar placeholder={undefined}>
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol style={{ flex: 0.7 }} placeholder={undefined}>
              <IonText placeholder={undefined}>{firstName}</IonText>
              <br />
              <IonText placeholder={undefined}>{lastName}</IonText>
            </IonCol>
            <IonCol style={{ flex: 0.3 }} placeholder={undefined}>
              <IonAvatar placeholder={undefined}>
                <IonImg src={image} placeholder={undefined} />
              </IonAvatar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </IonHeader>
  );
};

const HomeMenuButton = () => {
  return (
    <CustomMenuButton
      hidden={false}
      routerLink="/home"
      label={lables.LBL_HOME}
      getIcon={() => (
        <IonIcon
          className="mpp-menu-icon"
          icon={Icons.home}
          placeholder={undefined}
        />
      )}
    />
  );
};

const ScanMenuButton = () => {
  return (
    <CustomMenuButton
      hidden={false}
      routerLink="/scan"
      label={lables.LBL_PRODUCT_SCAN}
      getIcon={() => (
        <IonIcon
          className="mpp-menu-icon"
          icon={Icons.scanCircle}
          placeholder={undefined}
        />
      )}
    />
  );
};

const AdvanceSearchMenuButton = () => {
  return (
    <CustomMenuButton
      hidden={false}
      routerLink="/advancesearch"
      label={lables.LBL_STORE_PRODUCT_SEARCH}
      getIcon={() => (
        <IonIcon
          className="mpp-menu-icon"
          icon={Icons.storefront}
          placeholder={undefined}
        />
      )}
    />
  );
};

const CorporateAdvanceSearchMenuButton = () => {
  return (
    <CustomMenuButton
      hidden={false}
      routerLink="/corporateadvancesearch"
      label={lables.LBL_CORPORATE_PRODUCT_SEARCH}
      getIcon={() => (
        <IonIcon
          className="mpp-menu-icon"
          icon={Icons.searchCircle}
          placeholder={undefined}
        />
      )}
    />
  );
};

const SettingsMenuButton = () => {
  return (
    <CustomMenuButton
      hidden={false}
      routerLink="/settings"
      label={lables.LBL_SETTINGS}
      getIcon={() => (
        <IonIcon
          className="mpp-menu-icon"
          icon={Icons.settings}
          placeholder={undefined}
        />
      )}
    />
  );
};

const FavouritesMenuButton = () => {
  return (
    <CustomMenuButton
      hidden={false}
      routerLink="/favourites"
      label={lables.LBL_FAVOURITES}
      getIcon={() => (
        <IonIcon
          className="mpp-menu-icon"
          icon={Icons.star}
          placeholder={undefined}
        />
      )}
    />
  );
};

// Last minute changes as requested by Toni. Enhance in future release...
const getDocumentLink = () => {
  const infoDocumentId = "1gSDaUo9Rk0Fs1sITKLDHXv3fLOzelz0r";
  const comingSoonDocumentId = "1X5eG6jvZqMgIl8XDFlHz20_rAA1zdLxI";
  return {
    infoLink: "https://drive.google.com/file/d/" + infoDocumentId + "/view",
    comingSoonLink:
      "https://drive.google.com/file/d/" + comingSoonDocumentId + "/view",
    suggestionLink:
      "https://docs.google.com/forms/d/e/1FAIpQLScfTsjCKoOoDw6fXmpF90FmqeknJn_uw0xVVuylWT7KEBPd1g/viewform",
  };
};

const InfoMenuButton = () => {
  return (
    <CustomMenuButton
      hidden={false}
      routerLink={getDocumentLink().infoLink}
      label={lables.LBL_INFORMATION}
      getIcon={() => (
        <IonIcon
          className="mpp-menu-icon"
          icon={Icons.informationCircle}
          placeholder={undefined}
        />
      )}
    />
  );
};

const ComingSoonMenuButton = () => {
  return (
    <CustomMenuButton
      hidden={false}
      routerLink={getDocumentLink().comingSoonLink}
      label={lables.LBL_COMING_SOON}
      getIcon={() => (
        <IonIcon
          className="mpp-menu-icon"
          icon={Icons.calendarNumber}
          placeholder={undefined}
        />
      )}
    />
  );
};

const SuggestMenuButton = () => {
  return (
    <CustomMenuButton
      hidden={false}
      routerLink={getDocumentLink().suggestionLink}
      label={lables.LBL_CHANGE_REQUEST}
      getIcon={() => (
        <IonIcon
          className="mpp-menu-icon"
          icon={Icons.newspaper}
          placeholder={undefined}
        />
      )}
    />
  );
};

const LogOutMenuButton = () => {
  return (
    <CustomMenuButton
      hidden={false}
      routerLink="/logout"
      label={lables.LBL_LOGOUT}
      getIcon={() => (
        <IonIcon
          className="mpp-menu-icon"
          icon={Icons.logOut}
          placeholder={undefined}
        />
      )}
    />
  );
};

const CustomMenuButton = (props: any) => {
  return (
    <IonMenuToggle autoHide={false} placeholder={undefined}>
      <IonItem
        button
        hidden={props.hidden}
        disabled={false}
        href={props.routerLink}
        placeholder={undefined}
      >
        {props.getIcon()}
        <IonLabel className="mpp-menu-button" placeholder={undefined}>
          {props.label}
        </IonLabel>
      </IonItem>
    </IonMenuToggle>
  );
};

export default Menu;
