import { IonAccordion, IonItem, IonLabel, IonList } from "@ionic/react";
import { useEffect, useState } from "react";
import { Product } from "../../../models/Product";
import * as Util from "../../../components/Util";
import { CustomRowTableBox } from "../../../components/CustomRowTableBox";
import { CustomTableWithTotalBox } from "../../../components/CustomTableWithTotalBox";
import { DataValue } from "../../../components/DataTypes";
import {
  getCorporateDelistingDetails,
  getCorporatePreviousRangeInformation,
  getCorporateRangeInformation,
  getCorporateSpaceInformation,
  getCorporateStoreReangedStatus,
} from "../../../services/LookerService";
import { CustomTableBox } from "../../../components/CustomTableBox";

interface SpaceData {
  planName?: DataValue;
  totalFillQuantity?: DataValue;
  storeCount?: DataValue;
}

// Space Section...
const SpaceSection = (props: { product: Product | undefined }) => {
  const [productMin] = useState<string | undefined>(props.product?.itemNumber);

  const [storeSpaceData, setStoreSpaceData] = useState<SpaceData[]>();
  const [storeSpaceSummary, setStoreSpaceSummary] = useState<{
    header: string;
    rows: { key: string; value: any; isTotal: boolean }[];
  }>();
  const [isRangeStatusLoading, setRangeStatusLoading] = useState<boolean>();
  const [delistingData, setdelistingData] = useState<{
    header: string;
    rows: {
      key: string;
      value: any;
    }[];
  }>();
  const [isLoading, setLoading] = useState<boolean>();
  const [isRangeLoading, setRangeLoading] = useState<boolean>();
  const [isSpaceLoading, setSpaceLoading] = useState<boolean>();
  const [callPreviousDay, setCallPreviousDay] = useState<boolean>(false);
  const [delistingDetails, setDelistingDetails] = useState<any>();
  const [storeRangedStatus, setStoreRangedStatus] = useState<any>();

  const handleCorporateSpaceInformationResponse = (response: {
    ok: boolean;
    value: any[];
    error: any;
  }) => {
    if (response.ok) {
      const spaceData: any[] = [];
      const rows = response.value;
      rows.forEach((row) => {
        const dataRows: DataValue[] = [];

        const planName: DataValue = {
          key: "planName",
          value: row["location_space_info_plano.Planogram_Class"],
          type: "string",
        };

        const totalFillQuantity: DataValue = {
          key: "totalFillQuantity",
          value: row["location_space_info_plano.Fill_quantity"],
          type: "string",
        };

        const storeCount: DataValue = {
          key: "storeCount",
          value: row["location_space_info_plano.number_of_stores"],
          type: "string",
          modalKey: "PlannedStores",
          modalMIN: productMin,
          modalAdditionalValues: { planogramClass: planName.value },
        };

        dataRows.push(planName, storeCount, totalFillQuantity);

        spaceData.push(dataRows);
      });
      setStoreSpaceData(spaceData);
    } else {
      console.error(
        "No space information found for " +
          productMin +
          " for store " +
          Util.getStore()
      );
    }
  };

  const handleCorporateRangeInformationResponse = (
    response: {
      ok: boolean;
      value: any[];
      error: any;
    },
    table: string
  ) => {
    if (response.ok) {
      const element = response.value[0];
      if (table === "store_stock" && element["store_stock.Total_Space"] === 0) {
        setCallPreviousDay(true);
      } else {
        const summary = {
          header: "Store Space Summary",
          rows: [
            {
              key: "Standard Space",
              isTotal: false,
              value: element[table + ".standard_space"],
            },
            {
              key: "Promotional Space",
              isTotal: false,
              value: element[table + ".promo_space"],
            },
            {
              key: "Discretionary Space",
              isTotal: false,
              value: element[table + ".discret_space"],
            },
            {
              key: "Total Space",
              isTotal: true,
              value: element[table + ".Total_Space"],
            },
          ],
        };
        setStoreSpaceSummary(summary);
      }
    } else {
      console.error(
        "No range information found for " +
          productMin +
          " for store " +
          Util.getStore()
      );
    }
  };

  // Get Space Information...
  useEffect(() => {
    if (callPreviousDay && productMin) {
      setRangeLoading(true);
      const getPreviousRangeInformationRes = getCorporatePreviousRangeInformation(
        productMin,
        Util.getCountry() === Util.ALL_VALUES ? undefined : Util.getCountry(),
        Util.getRegion() === Util.ALL_VALUES ? undefined : Util.getRegion()
      );
      getPreviousRangeInformationRes
        .then((response) => {
          handleCorporateRangeInformationResponse(
            response,
            "store_stock_range"
          );
        })
        .finally(() => {
          setRangeLoading(false);
        });
    }
  }, [productMin, callPreviousDay]);

  // Get Range Information...
  useEffect(() => {
    if (productMin) {
      setRangeLoading(true);
      const getRangeInformationRes = getCorporateRangeInformation(
        productMin,
        Util.getCountry() === Util.ALL_VALUES ? undefined : Util.getCountry(),
        Util.getRegion() === Util.ALL_VALUES ? undefined : Util.getRegion()
      );
      getRangeInformationRes
        .then((response) => {
          handleCorporateRangeInformationResponse(response, "store_stock");
        })
        .finally(() => {
          setRangeLoading(false);
        });
    }
  }, [productMin]);

  // Get Space Information...
  useEffect(() => {
    if (productMin) {
      setSpaceLoading(true);
      const getCorporateSpaceInformationRes = getCorporateSpaceInformation(
        productMin,
        Util.getCountry() === Util.ALL_VALUES ? undefined : Util.getCountry(),
        Util.getRegion() === Util.ALL_VALUES ? undefined : Util.getRegion()
      );
      getCorporateSpaceInformationRes
        .then((response) => {
          handleCorporateSpaceInformationResponse(response);
        })
        .finally(() => {
          setSpaceLoading(false);
        });
    }
  }, [productMin]);

  // Due to be delisted and deranged...
  useEffect(() => {
    if (productMin) {
      const getDelistingDetailsRes = getCorporateDelistingDetails(
        productMin,
        Util.getCountry() === Util.ALL_VALUES ? undefined : Util.getCountry(),
        Util.getRegion() === Util.ALL_VALUES ? undefined : Util.getRegion()
      );
      getDelistingDetailsRes
        .then((response) => {
          handleDelistingDetailsRes(response);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [productMin]);

  const handleDelistingDetailsRes = (response: {
    ok: boolean;
    value: any[];
    error: any;
  }) => {
    if (response.ok) {
      const element = response.value[0];
      setDelistingDetails(element);
    }
  };

  useEffect(() => {
    if (productMin) {
      setRangeStatusLoading(true);
      const getStoreReangedStatusRes = getCorporateStoreReangedStatus(
        productMin,
        Util.getCountry() === Util.ALL_VALUES ? undefined : Util.getCountry(),
        Util.getRegion() === Util.ALL_VALUES ? undefined : Util.getRegion()
      );
      getStoreReangedStatusRes
        .then((response) => {
          handleStoreReangedStatusRes(response);
        })
        .finally(() => {
          setRangeStatusLoading(false);
        });
    }
  }, [productMin]);

  const handleStoreReangedStatusRes = (response: {
    ok: boolean;
    value: any[];
    error: any;
  }) => {
    if (response.ok) {
      const element = response.value[0];
      setStoreRangedStatus(element);
    }
  };

  useEffect(() => {
    const data = {
      header: "Delisting Information",
      rows: [
        {
          key: "Due To Be Delisted",
          value: delistingDetails
            ? delistingDetails["delist_derange.Store_count_due_to_be_delisted"]
            : Util.NOT_AVAILABLE,
          modalKey: "DelistedStores",
          modalMIN: productMin,
        },
        {
          key: "Due To Be Deranged",
          value: delistingDetails
            ? delistingDetails["delist_derange.Store_count_due_to_be_deranged"]
            : Util.NOT_AVAILABLE,
          modalKey: "DerangedStores",
          modalMIN: productMin,
        },
        {
          key: "Stores Ranged Count",
          value: storeRangedStatus
            ? storeRangedStatus["store_stock.Store_Range"]
            : Util.NOT_AVAILABLE,
        },
        {
          key: "Depots Ranged Count",
          value: storeRangedStatus
            ? storeRangedStatus["store_stock.Depot_Range"]
            : Util.NOT_AVAILABLE,
        },
      ],
    };

    let rows = data.rows;
    rows = rows.filter((row) => row.value !== Util.NOT_AVAILABLE);

    data.rows = rows;
    setdelistingData(data);
  }, [delistingDetails, storeRangedStatus]);

  useEffect(() => {
    setLoading(isSpaceLoading || isRangeLoading || isRangeStatusLoading);
  }, [isSpaceLoading, isRangeLoading, isRangeStatusLoading]);

  const StoreSpaceInformationContent = () => {
    const header = ["Plan Name", "Number Of Stores", "Fill"];
    const fixedColumns = ["Plan Name"];
    const data = storeSpaceData;
    const title = "Corporate Space Information";

    return (
      <CustomRowTableBox
        title={title}
        header={header}
        data={data}
        fixedColumns={fixedColumns}
        csvFileName={"CorporateSpaceReport_MIN-" + productMin + ".csv"}
        allCapital={true}
        allAlignCenter={true}
      />
    );
  };

  const DelistInformationBox = () => {
    if (delistingData) {
      return <CustomTableBox {...delistingData} />;
    } else {
      return <></>;
    }
  };

  const headerName = "Location & Space";
  return (
    <IonAccordion placeholder={undefined}>
      <IonItem
        slot="header"
        className="mpp-accordian-header-item"
        placeholder={undefined}
      >
        <IonLabel
          className="mpp-accordian-header-label"
          placeholder={undefined}
        >
          {headerName}
          {isLoading ? " (Loading...)" : ""}
        </IonLabel>
      </IonItem>
      <IonList slot="content" placeholder={undefined}>
        <CustomTableWithTotalBox {...storeSpaceSummary} />
        <DelistInformationBox />
        <StoreSpaceInformationContent />
      </IonList>
    </IonAccordion>
  );
};

export default SpaceSection;
