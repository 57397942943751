import {
  IonAccordion,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonToggle,
} from "@ionic/react";
import React from "react";
import { CustomRowTableBox } from "../../../components/CustomRowTableBox";
import { Product } from "../../../models/Product";
import { getWastageAndMarkdownByWeek } from "../../../services/LookerService";
import * as Util from "../../../components/Util";
import { DataValue } from "../../../components/DataTypes";
import "../styles.css";

const WasteMarkdownSection = (props: { product: Product | undefined }) => {
  interface WastageAndMarkdownData {
    business_week_start_date?: DataValue;
    total_waste_value?: DataValue;
    total_waste_units?: DataValue;
    waste_pct?: DataValue;
    total_markdown_value?: DataValue;
    total_markdown_units?: DataValue;
    markdown_pct?: DataValue;
    cost_turnover?: DataValue;
    waste_and_markdown_value?: DataValue;
    waste_and_markdown_pct?: DataValue;
  }

  const [
    wastageAndMarkdownByWeekAndTaking,
    setWastageAndMarkdownByWeekAndTaking,
  ] = React.useState<WastageAndMarkdownData[]>();

  const [wastageAndMarkdownByWeekAndCost, setWastageAndMarkdownByWeekAndCost] =
    React.useState<WastageAndMarkdownData[]>();

  const [
    wastageAndMarkdownByDayAndTaking,
    setWastageAndMarkdownByDayAndTaking,
  ] = React.useState<WastageAndMarkdownData[]>();

  const [wastageAndMarkdownByDayAndCost, setWastageAndMarkdownByDayAndCost] =
    React.useState<WastageAndMarkdownData[]>();

  const [productMin] = React.useState<string | undefined>(
    props.product?.itemNumber
  );

  const [weekOrDay, setWeekOrDay] = React.useState<boolean>(false);
  const [takingOrCost, setTakingOrCost] = React.useState<boolean>(false);
  const [isLoading, setLoading] = React.useState<boolean>();

  const handleServiceResponse = (
    response: {
      ok: boolean;
      value: any[];
      error: any;
    },
    isDay: boolean
  ) => {
    if (Util.getDebugMode()) console.log(JSON.stringify(response));
    if (response.ok) {
      const data_by_takings: any[] = [];
      const data_by_cost: any[] = [];
      const rows = response.value;
      rows.forEach((row) => {
        const dataRows_by_takings: DataValue[] = [];
        const dataRows_by_cost: DataValue[] = [];

        const business_week_start_date: DataValue = {
          key: "business_week_start_date",
          value: isDay
            ? row["waste_markdown.clndr_dt"]
            : row["morrisons_calendar.business_week_start_date"],
          type: isDay ? "date" : "week",
        };

        const total_waste_value: DataValue = {
          key: "total_waste_value",
          value: row["waste_markdown.total_waste_value"],
          type: "currency",
        };

        const total_waste_units: DataValue = {
          key: "total_waste_units",
          value: row["waste_markdown.total_waste_units"],
          type: "number",
        };

        const waste_pct_by_takings: DataValue = {
          key: "waste_pct",
          value: row["waste_pct_by_takings"],
          type: "percent",
        };

        const waste_pct_by_cost: DataValue = {
          key: "waste_pct",
          value: row["waste_pct_by_cost"],
          type: "percent",
        };

        const total_markdown_value: DataValue = {
          key: "total_markdown_value",
          value: row["waste_markdown.total_markdown_value"],
          type: "currency",
        };

        const total_markdown_units: DataValue = {
          key: "total_markdown_units",
          value: row["waste_markdown.total_markdown_units"],
          type: "number",
        };

        const markdown_pct_by_takings: DataValue = {
          key: "markdown_pct",
          value: row["markdown_pct_by_takings"],
          type: "percent",
        };

        const markdown_pct_by_cost: DataValue = {
          key: "markdown_pct",
          value: row["markdown_pct_by_cost"],
          type: "percent",
        };

        const waste_and_markdown_value: DataValue = {
          key: "waste_and_markdown_value",
          value: row["waste_and_markdown"],
          type: "currency",
        };

        const waste_and_markdown_units: DataValue = {
          key: "waste_and_markdown_value",
          value: row["waste_and_markdown_units"],
          type: "number",
        };

        const waste_and_markdown_pct_by_takings: DataValue = {
          key: "waste_and_markdown_pct",
          value: row["waste_and_markdown_pct_by_takings"],
          type: "percent",
        };

        const waste_and_markdown_pct_by_cost: DataValue = {
          key: "waste_and_markdown_pct",
          value: row["waste_and_markdown_pct_by_cost"],
          type: "percent",
        };

        // Week and Taking
        dataRows_by_takings.push(
          business_week_start_date,
          waste_and_markdown_value,
          waste_and_markdown_units,
          waste_and_markdown_pct_by_takings,
          total_waste_value,
          total_waste_units,
          waste_pct_by_takings,
          total_markdown_value,
          total_markdown_units,
          markdown_pct_by_takings
        );

        // Week and Cost
        dataRows_by_cost.push(
          business_week_start_date,
          waste_and_markdown_value,
          waste_and_markdown_units,
          waste_and_markdown_pct_by_cost,
          total_waste_value,
          total_waste_units,
          waste_pct_by_cost,
          total_markdown_value,
          total_markdown_units,
          markdown_pct_by_cost
        );

        data_by_takings.push(dataRows_by_takings);
        data_by_cost.push(dataRows_by_cost);
      });
      if (isDay) {
        setWastageAndMarkdownByDayAndTaking(data_by_takings);
        setWastageAndMarkdownByDayAndCost(data_by_cost);
      } else {
        setWastageAndMarkdownByWeekAndTaking(data_by_takings);
        setWastageAndMarkdownByWeekAndCost(data_by_cost);
      }
    } else {
      console.error(
        "No sales information found for " +
          productMin +
          " for store " +
          Util.getStore()
      );
    }
  };

  React.useEffect(() => {
    if (productMin) {
      setLoading(true);
      getWastageAndMarkdownByWeek(productMin, false, Util.getStore()).then(
        (response) => {
          handleServiceResponse(response, false);
          setLoading(false);
        }
      );
    }
  }, [productMin]);

  React.useEffect(() => {
    if (
      productMin &&
      weekOrDay &&
      wastageAndMarkdownByDayAndTaking === undefined
    ) {
      setLoading(true);
      getWastageAndMarkdownByWeek(productMin, true, Util.getStore()).then(
        (response) => {
          handleServiceResponse(response, true);
          setLoading(false);
        }
      );
    }
  }, [productMin, weekOrDay]);

  const WeeklyWastageAndMarkdownContent = () => {
    const header = [
      "Week",
      "W&M £",
      "W&M Units",
      "W&M %",
      "Waste £",
      "Waste Units",
      "Waste %",
      "Markdown £",
      "Markdown Units",
      "Markdown %",
    ];
    const fixedColumns = ["Week"];
    const data = takingOrCost
      ? wastageAndMarkdownByWeekAndCost
      : wastageAndMarkdownByWeekAndTaking;
    const title = "Wastage And Markdown Information";
    return (
      <CustomRowTableBox
        title={title}
        header={header}
        data={data}
        fixedColumns={fixedColumns}
        csvFileName={
          "WeeklyWastageReportBy" +
          (takingOrCost ? "Cost" : "Turnover") +
          "_MIN-" +
          productMin
            ?.replaceAll(" ", "-")
            .replaceAll("(", "")
            .replaceAll(")", "") +
          ".csv"
        }
      />
    );
  };

  const DailyWastageAndMarkdownContent = () => {
    const header = [
      "Day",
      "W&M £",
      "W&M Units",
      "W&M %",
      "Waste £",
      "Waste Units",
      "Waste %",
      "Markdown £",
      "Markdown Units",
      "Markdown %",
    ];
    const fixedColumns = ["Day"];
    const data = takingOrCost
      ? wastageAndMarkdownByDayAndCost
      : wastageAndMarkdownByDayAndTaking;
    const title = "Wastage And Markdown Information";
    return (
      <CustomRowTableBox
        title={title}
        header={header}
        data={data}
        fixedColumns={fixedColumns}
        csvFileName={
          "DailyWastageReportBy" +
          (takingOrCost ? "Cost" : "Turnover") +
          "_MIN-" +
          productMin
            ?.replaceAll(" ", "-")
            .replaceAll("(", "")
            .replaceAll(")", "") +
          ".csv"
        }
      />
    );
  };

  const getVisibleComponent = () => {
    if (!weekOrDay) {
      return <WeeklyWastageAndMarkdownContent />;
    } else {
      return <DailyWastageAndMarkdownContent />;
    }
  };

  const headerName = "Waste & Markdown";
  return (
    <IonAccordion aria-expanded placeholder={undefined}>
      <IonItem
        slot="header"
        className="mpp-accordian-header-item"
        placeholder={undefined}
      >
        <IonLabel
          className="mpp-accordian-header-label"
          placeholder={undefined}
        >
          {headerName}
          {isLoading ? " (Loading...)" : ""}
        </IonLabel>
      </IonItem>
      <IonList slot="content" placeholder={undefined}>
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol size="auto" placeholder={undefined}>
              <IonLabel className="fixedLabelWeek" placeholder={undefined}>
                Week
              </IonLabel>
              <IonToggle
                class="mppToggle"
                checked={weekOrDay}
                onIonChange={(e) => setWeekOrDay(e.detail.checked)}
                placeholder={undefined}
              />
              <IonLabel className="fixedLabelDay" placeholder={undefined}>
                Day
              </IonLabel>
            </IonCol>
            <IonCol size="auto" placeholder={undefined}>
              <IonLabel className="fixedLabelTurnover" placeholder={undefined}>
                Turnover
              </IonLabel>
              <IonToggle
                class="mppToggle"
                checked={takingOrCost}
                onIonChange={(e) => setTakingOrCost(e.detail.checked)}
                placeholder={undefined}
              />
              <IonLabel
                className="fixedLabelCostOfSales"
                placeholder={undefined}
              >
                Cost of Sales
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>

        {getVisibleComponent()}
      </IonList>
    </IonAccordion>
  );
};

export default WasteMarkdownSection;
