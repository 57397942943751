import { CustomTableBox } from "../../../components/CustomTableBox";
import { Product } from "../../../models/Product";
import * as Util from "../../../components/Util";

const SupplierInformationBox = (product: Product) => {
  const data = {
    header: "Supplier",
    rows: [
      {
        key: "Supplier Name",
        value: product?.supplierName
          ? product?.supplierName
          : Util.NOT_AVAILABLE,
      },
      {
        key: "Supplier EBS ID",
        value: product?.supplierEBSId
          ? product?.supplierEBSId
          : Util.NOT_AVAILABLE,
      },
      {
        key: "Supplier RMS ID",
        value: product?.supplierRMSId
          ? product?.supplierRMSId
          : Util.NOT_AVAILABLE,
      },
      {
        key: "Site Name",
        value: product?.supplierSiteName
          ? product?.supplierSiteName
          : Util.NOT_AVAILABLE,
      },
      {
        key: "Site Number",
        value: product?.supplierSiteId
          ? product?.supplierSiteId
          : Util.NOT_AVAILABLE,
      },
      {
        key: "Payment Terms",
        value: product?.supplierTermDescription
          ? product?.supplierTermDescription
          : Util.NOT_AVAILABLE,
      },
      {
        key: "Settlement Discount",
        value: product?.supplierDiscountPercentage
          ? product?.supplierDiscountPercentage + "%"
          : product?.supplierDiscountPercentage === 0
          ? "0%"
          : Util.NOT_AVAILABLE,
      },
      {
        key: "Supplier Email",
        value: product?.supplierEmail
          ? product?.supplierEmail.toLocaleLowerCase()
          : Util.NOT_AVAILABLE,
        isLower: true,
      },
      {
        key: "Supplier Lead Contact",
        value: product?.supplierLeadContact
          ? product?.supplierLeadContact
          : Util.NOT_AVAILABLE,
      },
      {
        key: "Supplier Address",
        value: product?.supplierAddress
          ? product?.supplierAddress.toLocaleUpperCase()
          : Util.NOT_AVAILABLE,
        isUpper: true,
      },
      {
        key: "Supplier Primary Currency",
        value: product?.supplierPrimaryCurrency
          ? product?.supplierPrimaryCurrency.toLocaleUpperCase()
          : Util.NOT_AVAILABLE,
        isUpper: true,
      },
    ],
  };

  return <CustomTableBox {...data} />;
};

export default SupplierInformationBox;
