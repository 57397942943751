import {
  IonCol,
  IonGrid,
  IonHeader,
  IonImg,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Product } from "../../../models/Product";
import "../styles.css";

const ProductImageBoxCol = (product: Product) => {
  let finalImageUrl = "";
  if (product) {
    if (product?.imageUrl && product?.imageUrl.length > 0) {
      finalImageUrl = product?.imageUrl[0].url;
    }
  }

  if (
    finalImageUrl.length === null ||
    finalImageUrl.length === undefined ||
    finalImageUrl.length === 0
  ) {
    return <></>;
  }

  const props = {
    imageUrl: finalImageUrl,
  };

  return (
    <IonCol className="mpp-ion-grid-infobox" placeholder={undefined}>
      <ProductImageBox {...props} />
    </IonCol>
  );
};

const ProductImageBox = (props: any) => {
  const headerNeeded = false;

  const getHeader = () => {
    return headerNeeded ? (
      <IonHeader placeholder={undefined}>
        <IonToolbar
          className="mpp-ion-grid-infobox-header"
          placeholder={undefined}
        >
          <IonTitle
            class="ion-text-center"
            className="mpp-ion-grid-infobox-header-title"
            placeholder={undefined}
          >
            Product Image
          </IonTitle>
        </IonToolbar>
      </IonHeader>
    ) : (
      <></>
    );
  };

  return (
    <IonGrid placeholder={undefined}>
      {getHeader()}
      <IonRow placeholder={undefined}>
        <IonCol placeholder={undefined}>
          <IonImg
            src={props.imageUrl}
            className="mpp-ion-grid-infobox-image"
            placeholder={undefined}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ProductImageBoxCol;
