import { Store } from "../../models/Favourites";
import { getStoreName } from "../../services/LocationService";
import * as Util from "../Util";

interface Point {
  latitude: number;
  longitude: number;
}
const rad = function (x: number) {
  return (x * Math.PI) / 180;
};

const getDistance = function (p1: Point, p2: Point) {
  const R = 6378137; // Earth’s mean radius in meter
  const dLat = rad(p2.latitude - p1.latitude);
  const dLong = rad(p2.longitude - p1.longitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.latitude)) *
      Math.cos(rad(p2.latitude)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d; // returns the distance in meter
};

const getStoreNumber = (latitude: number, longitude: number): string => {
  loadStore();

  interface Distance {
    distance: number;
    storeNumber: string;
  }
  const distances: Distance[] = [];

  const compareDistance = (d1: Distance, d2: Distance) => {
    return d1.distance - d2.distance;
  };

  MORRISONS_STORE_LOCATIONS.forEach((element) => {
    const distance = getDistance(
      { latitude: latitude, longitude: longitude },
      element
    );
    distances.push({ distance: distance, storeNumber: element.storeNumber });
  });

  distances.sort(compareDistance);
  return distances[0].storeNumber;
};

const setUpLocation = () => {
  interface Point {
    latitude: number;
    longitude: number;
  }

  const setCurrentPosition = (position: GeolocationPosition) => {
    const location: Point = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };

    const storeNumber = getStoreNumber(location.latitude, location.longitude);
    Util.setStore(getStoreName(storeNumber));
  };

  const positionError = (error: any) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.error("Client Error: User denied the request for Geolocation.");
        break;

      case error.POSITION_UNAVAILABLE:
        console.error("Client Error: Location information is unavailable.");
        break;

      case error.TIMEOUT:
        console.error(
          "Network Error: The request to get user location timed out."
        );
        break;

      case error.UNKNOWN_ERROR:
        console.error("Client Error: An unknown error occurred.");
        break;
    }
  };

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      setCurrentPosition,
      positionError,
      {
        enableHighAccuracy: true,
        timeout: 30000,
        maximumAge: 0,
      }
    );
  } else {
    console.error("Client Warning: Browser does not support geolocation");
    alert(
      "Browser does not support geolocation. Please manually setup location from Settings."
    );
  }
};

export { getStoreNumber, setUpLocation };

/*At this moment there is no way to get this information in one go other than 
calling Location Service 500 times. This is very costly so for the meantime 
all locations are put here with lat lan information. In future if better solition
available this hard coding will be removed fro, this code.
*/
///////////////////////// Store Locations ////////////////////////////
const MORRISONS_STORE_LOCATIONS: Store[] = [];
const loadStore = () => {
  if (MORRISONS_STORE_LOCATIONS.length === 0) {
    const stores = Util.getStoreGeo()?.stores;
    stores?.forEach((store) => {
      MORRISONS_STORE_LOCATIONS.push(store);
    });
  }
};
