import { CustomTableBox } from "../../../components/CustomTableBox";
import { Product } from "../../../models/Product";
import * as Util from "../../../components/Util";
import { Price } from "../../../models/Price";
import { Promotion } from "../../../models/Promotion";

/*
If future promotion is present and its start data is less than the future base price 
effective date the take the future promotion price details. Else use the base price 
details to calculate the future retail price value.
*/
const getFutureRetailPrice = (
  nextPromotionDate: string | undefined,
  nextPromotionPrice: number | undefined,
  futurePriceEffectiveDate: string | undefined,
  futurePrice: number | undefined
): string | number | undefined => {
  if (nextPromotionDate) {
    if (futurePriceEffectiveDate) {
      const nextPromotionDateIndex = parseInt(
        nextPromotionDate.split("T")[0].replace("-", "")
      );
      const futurePriceEffectiveDateIndex = parseInt(
        futurePriceEffectiveDate.split("T")[0].replace("-", "")
      );
      if (nextPromotionDateIndex <= futurePriceEffectiveDateIndex) {
        return nextPromotionPrice
          ? Util.getUKPriceFormat(nextPromotionPrice)
          : Util.NOT_AVAILABLE;
      } else {
        return futurePrice
          ? Util.getUKPriceFormat(futurePrice)
          : Util.NOT_AVAILABLE;
      }
    } else {
      // No future base price available go with the next promotion price details...
      return nextPromotionPrice
        ? Util.getUKPriceFormat(nextPromotionPrice)
        : Util.NOT_AVAILABLE;
    }
  } else {
    // No next promotion price available go with the future base price details...
    if (futurePriceEffectiveDate) {
      return futurePrice
        ? Util.getUKPriceFormat(futurePrice)
        : Util.NOT_AVAILABLE;
    } else {
      return Util.NOT_AVAILABLE;
    }
  }
};

/*
If future promotion is present and its start data is less than the future base price 
effective date the take the future promotion price details. Else use the base price 
details to calculate the future retail price value.
*/
const getFutureRetailPriceLiveDate = (
  nextPromotionDate: string | undefined,
  futurePriceEffectiveDate: string | undefined
): string | undefined => {
  if (nextPromotionDate) {
    if (futurePriceEffectiveDate) {
      const nextPromotionDateIndex = parseInt(
        nextPromotionDate.split("T")[0].replace("-", "")
      );
      const futurePriceEffectiveDateIndex = parseInt(
        futurePriceEffectiveDate.split("T")[0].replace("-", "")
      );
      if (nextPromotionDateIndex <= futurePriceEffectiveDateIndex) {
        return nextPromotionDate;
      } else {
        return futurePriceEffectiveDate;
      }
    } else {
      return nextPromotionDate;
    }
  } else {
    if (futurePriceEffectiveDate) {
      return futurePriceEffectiveDate;
    } else {
      return Util.NOT_AVAILABLE;
    }
  }
};

const FuturePriceBox = (props: {
  product?: Product;
  price?: Price;
  promotion?: Promotion;
}) => {
  const data = {
    header: "Future Price",
    rows: [
      {
        key: "Future Retail Price",
        value: getFutureRetailPrice(
          props.promotion?.nextPromotion?.startDate,
          props.promotion?.nextPromotion?.promotionPrice,
          props.price?.futurePrice?.effectiveDate,
          props.price?.futurePrice?.regularPrice
        ),
      },
      {
        key: "Future Retail Price Live Date",
        value: Util.yyyymmddToDdmmyyyy(
          getFutureRetailPriceLiveDate(
            props.promotion?.nextPromotion?.startDate,
            props.price?.futurePrice?.effectiveDate
          )
        ),
      },
      {
        key: "Future Base Price",
        value: props.price?.futurePrice?.regularPrice
          ? Util.getUKPriceFormat(props.price?.futurePrice?.regularPrice)
          : Util.NOT_AVAILABLE,
      },
      {
        key: "Future Base Price Live Date",
        value: Util.yyyymmddToDdmmyyyy(
          props.price?.futurePrice?.effectiveDate
            ? props.price?.futurePrice?.effectiveDate
            : Util.NOT_AVAILABLE
        ),
      },
      {
        key: "Next Promotional Price",
        value: props.promotion?.nextPromotion?.promotionPrice
          ? Util.getUKPriceFormat(
              props.promotion?.nextPromotion?.promotionPrice
            )
          : Util.NOT_AVAILABLE,
      },
      {
        key: "Next Promotional Price Start Date",
        value: Util.yyyymmddToDdmmyyyy(
          props.promotion?.nextPromotion?.startDate
            ? props.promotion?.nextPromotion?.startDate
            : Util.NOT_AVAILABLE
        ),
      },
      {
        key: "Next Promotional Price End Date",
        value: Util.yyyymmddToDdmmyyyy(
          props.promotion?.nextPromotion?.endDate
            ? props.promotion?.nextPromotion?.endDate
            : Util.NOT_AVAILABLE
        ),
      },
    ],
  };

  return <CustomTableBox {...data} />;
};

export default FuturePriceBox;
