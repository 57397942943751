import DerangedStores from "./DerangedStores";
import DelistedStores from "./DelistedStores";
import PlannedStores from "./PlannedStores";

const DERANGED_STORES = "DerangedStores";
const DELISTED_STORES = "DelistedStores";
const PLANNED_STORES = "PlannedStores";

const PageModalData = (props: {
  componentName: string;
  productMin: string;
  value: string | number | undefined;
  modalAdditionalValues?: { planogramClass?: string | number };
}) => {
  if (DERANGED_STORES === props.componentName) {
    return <DerangedStores productMin={props.productMin} value={props.value} />;
  } else if (DELISTED_STORES === props.componentName) {
    return <DelistedStores productMin={props.productMin} value={props.value} />;
  } else if (PLANNED_STORES === props.componentName) {
    return (
      <PlannedStores
        productMin={props.productMin}
        value={props.value}
        modalAdditionalValues={props.modalAdditionalValues}
      />
    );
  }

  return <></>;
};

export { DERANGED_STORES, PageModalData };
