import {
  IonAccordion,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonToggle,
} from "@ionic/react";
import React from "react";
import { CustomRowTableBox } from "../../../components/CustomRowTableBox";
import { Product } from "../../../models/Product";
import {
  getCorporateProductSales,
  getCorporateProductSalesForecast,
} from "../../../services/LookerService";
import * as Util from "../../../components/Util";
import { DataValue } from "../../../components/DataTypes";

const SalesSection = (props: { product: Product | undefined }) => {
  interface SalesData {
    average_price?: DataValue;
    business_week_start_date?: DataValue;
    sales_units?: DataValue;
    takings?: DataValue;
    average_sales_units?: DataValue;
    average_takings?: DataValue;
  }

  interface SalesForecastData {
    predicted_units?: DataValue;
    business_week_start_date?: DataValue;
  }

  // For sales forecast...
  const [salesDetailsDaily, setSalesDetailsDaily] =
    React.useState<SalesData[]>();

  // For sales forecast...
  const [salesForecastDetailsWeekly, setSalesForecastDetailsWeekly] =
    React.useState<SalesForecastData[]>();
  const [salesForecastDetailsDaily, setSalesForecastDetailsDaily] =
    React.useState<SalesForecastData[]>();

  const [productMin] = React.useState<string | undefined>(
    props.product?.itemNumber
  );
  const [weekOrDay, setWeekOrDay] = React.useState<boolean>(false);
  const [isLoading, setLoading] = React.useState<boolean>();

  const handleSalesServiceResponse = (
    response: {
      ok: boolean;
      value: any[];
      error: any;
    },
    isDay: boolean
  ) => {
    if (Util.getDebugMode()) console.log(JSON.stringify(response));
    if (response.ok) {
      const data: any[] = [];
      const rows = response.value;
      rows.forEach((row) => {
        const dataRows: DataValue[] = [];
        const averagePriceDataValue: DataValue = {
          key: "average_price",
          value: row["average_price"],
          type: "currency",
        };

        const businessWeekStartDateDataValue: DataValue = {
          key: "business_week_start_date",
          value: isDay
            ? row["morrisons_calendar.calendar_date_date"]
            : row["morrisons_calendar.business_week_start_date"],
          type: isDay ? "date" : "week",
        };

        const salesUnitsDataValue: DataValue = {
          key: "sales_units",
          value: row["unaudited_sales.sales_units"],
          type: "number",
        };

        const takingDataValue: DataValue = {
          key: "takings",
          value: row["unaudited_sales.takings"],
          type: "currency",
        };

        dataRows.push(
          businessWeekStartDateDataValue,
          takingDataValue,
          salesUnitsDataValue,
          averagePriceDataValue
        );

        data.push(dataRows);
      });

      if (isDay) {
        setSalesDetailsDaily(data);
      }
    } else {
      console.error(
        "No sales information found for " +
          productMin +
          " for store " +
          Util.getStore()
      );
    }
  };

  const handleSalesForecastServiceResponse = (
    response: {
      ok: boolean;
      value: any[];
      error: any;
    },
    isDay: boolean
  ) => {
    if (Util.getDebugMode()) console.log(JSON.stringify(response));
    if (response.ok) {
      const data: any[] = [];
      const rows = response.value;
      rows.forEach((row) => {
        const dataRows: DataValue[] = [];

        const businessWeekStartDateDataValue: DataValue = {
          key: "business_week_start_date",
          value: isDay
            ? row["morrisons_calendar.calendar_date_date"]
            : row["morrisons_calendar.business_week_start_date"],
          type: isDay ? "date" : "week",
        };

        const salesUnitsDataValue: DataValue = {
          key: "prediction_units",
          value: row["sales_forecast.prediction_units"],
          type: "number",
        };

        dataRows.push(businessWeekStartDateDataValue, salesUnitsDataValue);

        data.push(dataRows);
      });

      if (isDay) {
        setSalesForecastDetailsDaily(data);
      } else {
        setSalesForecastDetailsWeekly(data);
      }
    } else {
      console.error(
        "No sales information found for " +
          productMin +
          " for store " +
          Util.getStore()
      );
    }
  };

  React.useEffect(() => {
    if (productMin) {
      getCorporateProductSales(
        productMin,
        false,
        Util.getCountry() === Util.ALL_VALUES ? undefined : Util.getCountry(),
        Util.getRegion() === Util.ALL_VALUES ? undefined : Util.getRegion(),
        "Supermarket"
      ).then((response) => {
        handleSalesServiceResponse(response, false);
      });
    }
  }, [productMin]);

  React.useEffect(() => {
    if (productMin && weekOrDay && salesDetailsDaily === undefined) {
      getCorporateProductSales(
        productMin,
        true,
        Util.getCountry() === Util.ALL_VALUES ? undefined : Util.getCountry(),
        Util.getRegion() === Util.ALL_VALUES ? undefined : Util.getRegion(),
        "Supermarket"
      ).then((response) => {
        handleSalesServiceResponse(response, true);
      });
    }
  }, [productMin, weekOrDay]);

  React.useEffect(() => {
    if (productMin) {
      setLoading(true);
      getCorporateProductSalesForecast(
        productMin,
        false,
        Util.getCountry() === Util.ALL_VALUES ? undefined : Util.getCountry(),
        Util.getRegion() === Util.ALL_VALUES ? undefined : Util.getRegion()
      ).then((response) => {
        handleSalesForecastServiceResponse(response, false);
        setLoading(false);
      });
    }
  }, [productMin]);

  React.useEffect(() => {
    if (productMin && weekOrDay && salesForecastDetailsDaily === undefined) {
      setLoading(true);
      getCorporateProductSalesForecast(
        productMin,
        true,
        Util.getCountry() === Util.ALL_VALUES ? undefined : Util.getCountry(),
        Util.getRegion() === Util.ALL_VALUES ? undefined : Util.getRegion()
      ).then((response) => {
        handleSalesForecastServiceResponse(response, true);
        setLoading(false);
      });
    }
  }, [productMin, weekOrDay]);

  const WeeklySalesForecastDetailsContent = () => {
    const header = ["Week", "Forecasted Units (Supermarket)"];
    const fixedColumns = ["Week"];
    const data = salesForecastDetailsWeekly;
    const title = "Sales Forecast Information";

    return (
      <CustomRowTableBox
        title={title}
        header={header}
        data={data}
        fixedColumns={fixedColumns}
        csvFileName={
          "WeeklySalesForecastReport" +
          "_MIN-" +
          productMin
            ?.replaceAll(" ", "-")
            .replaceAll("(", "")
            .replaceAll(")", "") +
          ".csv"
        }
      />
    );
  };

  const DailySalesForecastDetailsContent = () => {
    const header = ["Day", "Forecasted Units (Supermarket)"];
    const fixedColumns = ["Day"];
    const data = salesForecastDetailsDaily;
    const title = "Sales Forecast Information";

    return (
      <CustomRowTableBox
        title={title}
        header={header}
        data={data}
        fixedColumns={fixedColumns}
        csvFileName={
          "DailySalesForecastReport" +
          "_MIN-" +
          productMin
            ?.replaceAll(" ", "-")
            .replaceAll("(", "")
            .replaceAll(")", "") +
          ".csv"
        }
      />
    );
  };

  const getVisibleComponent = () => {
    if (!weekOrDay) {
      return (
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <WeeklySalesForecastDetailsContent />
            </IonCol>
          </IonRow>
        </IonGrid>
      );
    } else {
      return (
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <DailySalesForecastDetailsContent />
            </IonCol>
          </IonRow>
        </IonGrid>
      );
    }
  };

  const headerName = "Supermarket Future Forecast";

  return (
    <IonAccordion aria-expanded placeholder={undefined}>
      <IonItem
        slot="header"
        className="mpp-accordian-header-item"
        placeholder={undefined}
      >
        <IonLabel
          className="mpp-accordian-header-label"
          placeholder={undefined}
        >
          {headerName}
          {isLoading ? " (Loading...)" : ""}
        </IonLabel>
      </IonItem>
      <IonList slot="content" placeholder={undefined}>
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <IonLabel className="fixedLabelWeek" placeholder={undefined}>
                Week
              </IonLabel>
              <IonToggle
                class="mppToggle"
                checked={weekOrDay}
                onIonChange={(e) => setWeekOrDay(e.detail.checked)}
                placeholder={undefined}
              />
              <IonLabel className="fixedLabelDay" placeholder={undefined}>
                Day
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
        {getVisibleComponent()}
      </IonList>
    </IonAccordion>
  );
};

export default SalesSection;
