import LookerAccessToken from "../models/LookerAccessToken";
import LookerResponse from "../models/LookerResponse";
import { Product } from "../models/Product";
import * as Util from "../components/Util";
import * as Configs from "../components/Configs";
import { Price } from "../models/Price";
import { Promotion } from "../models/Promotion";
import { auth } from "../services/FirebaseService";
import { RetailPriceHistory, StoreSpace } from "../models/StoreSpace";

export const getSignedUrlCall = async (
  lookerReportPath: string,
  idToken?: string
): Promise<LookerResponse> => {
  return await executeServiceCall(
    "/getSignedUrl",
    {
      LookerURL: lookerReportPath,
    },
    idToken
  );
};

export const getProductDetailsCall = async (
  barcode: string,
  idToken?: string
): Promise<Product> => {
  return await executeServiceCall(
    "/getProductDetails",
    {
      Barcode: barcode,
    },
    idToken
  );
};

export const getProductPriceCall = async (
  min: string,
  idToken: string,
  isCorporate?: boolean,
  inCountry?: string
): Promise<Price> => {
  const store = Util.getStore(); // Get the store number from the current storage...
  let storeNumber = Util.getStoreNumberFromStoreName(store);
  storeNumber = storeNumber
    ? storeNumber
    : Util.getCorporateStoreNumber(inCountry);

  // No matter what store is selected if the flag is t,rue then fetch the corporate price...
  if (isCorporate) {
    storeNumber = Util.getCorporateStoreNumber(inCountry);
  }

  return await executeServiceCall(
    "/getProductPrice",
    {
      MIN: min,
      Store: storeNumber?.toString(),
    },
    idToken
  );
};

export const getProductPromotionCall = async (
  min: string,
  idToken: string,
  isCorporate?: boolean,
  inCountry?: string
): Promise<Promotion> => {
  const store = Util.getStore(); // Get the store number from the current storage...
  let storeNumber = Util.getStoreNumberFromStoreName(store);
  storeNumber = storeNumber
    ? storeNumber
    : Util.getCorporateStoreNumber(inCountry);

  // No matter what store is selected if the flag is true then fetch the corporate price...
  if (isCorporate) {
    storeNumber = Util.getCorporateStoreNumber(inCountry);
  }

  return await executeServiceCall(
    "/getProductPromotion",
    {
      MIN: min,
      Store: storeNumber?.toString(),
    },
    idToken
  );
};

// This call will provide the User details like User Access_Token, Expiry_Time, Roles and Groups...
export const getUserTokenCall = async (
  idToken?: string
): Promise<LookerAccessToken> => {
  const token = await auth.currentUser?.getIdToken();
  return await executeServiceCall("/getUserToken", {}, token);
};

export const checkMPPAccessCall = async (
  idToken?: string
): Promise<{ isValidMPPUser: boolean }> => {
  const token = await auth.currentUser?.getIdToken();
  return await executeServiceCall("/isMppUser", {}, token);
};

export const getProductSpaceCall = async (
  min: string,
  idToken: string,
  isCorporate?: boolean,
  inCountry?: string
): Promise<StoreSpace> => {
  const store = Util.getStore(); // Get the store number from the current storage...
  let storeNumber = Util.getStoreNumberFromStoreName(store);
  storeNumber = storeNumber
    ? storeNumber
    : Util.getCorporateStoreNumber(inCountry);

  // No matter what store is selected if the flag is t,rue then fetch the corporate price...
  if (isCorporate) {
    storeNumber = Util.getCorporateStoreNumber(inCountry);
  }

  return await executeServiceCall(
    "/getProductSpace",
    {
      MIN: min,
      Store: storeNumber?.toString(),
    },
    idToken
  );
};

export const getProductRetailPriceHistoryCall = async (
  min: string,
  idToken: string,
  isCorporate?: boolean,
  inCountry?: string
): Promise<RetailPriceHistory> => {
  const store = Util.getStore(); // Get the store number from the current storage...
  let storeNumber = Util.getStoreNumberFromStoreName(store);
  storeNumber = storeNumber
    ? storeNumber
    : Util.getCorporateStoreNumber(inCountry);

  // No matter what store is selected if the flag is t,rue then fetch the corporate price...
  if (isCorporate) {
    storeNumber = Util.getCorporateStoreNumber(inCountry);
  }

  return await executeServiceCall(
    "/getProductRetailPriceHistory",
    {
      MIN: min,
      Store: storeNumber?.toString(),
    },
    idToken
  );
};

const executeServiceCall = async (
  url: string,
  headers: any,
  idToekn?: string
): Promise<any> => {
  //const token = Util.getIdToken();
  //const token = await auth.currentUser?.getIdToken();
  const reqHeaders = { Authorization: "Bearer " + idToekn, ...headers };
  if (Util.getDebugMode())
    console.log("Request Header for " + url + " = " + JSON.stringify(headers));
  if (Util.getDebugMode()) console.log(JSON.stringify(reqHeaders)); // Security risk if kept enabled...
  const response = await fetch(Configs.CLOUD_FUNCTION_GATEWAY_URL + url, {
    method: "GET",
    headers: reqHeaders,
  });

  if (response.ok) {
  } else {
    console.error(JSON.stringify(response));
  }

  return await response.json();
};
