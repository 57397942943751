import { useState, useEffect } from "react";
import { IonToast } from "@ionic/react";
import { getSystemMessage } from "../../services/FirebaseService";
import { Message } from "../../models/Message";
import * as Util from "../../components/Util";
import { informationCircle, closeOutline } from "ionicons/icons";

const CustomNotification = () => {
  const dt = new Date();
  const [showToast, setShowToast] = useState(false);
  const [showNotification, setShowNotification] = useState<string>(
    Util.getShowNotice()
  );
  const [notice, setNotice] = useState<{
    title: string;
    message: string;
    timestamp: number;
  }>(Util.getNotice());

  useEffect(() => {
    if (notice.timestamp === 0 || dt.getTime() - notice.timestamp >= 600000) {
      getSystemMessage().then((response) => {
        if (response) {
          if (Util.getDebugMode()) console.log(JSON.stringify(response));
          const data = response.data();
          if (data) {
            const message: Message = JSON.parse(JSON.stringify(response.data()));

            const newnotice = {
              title: message.messageHeader ? message.messageHeader : "",
              message: message.messageBody ? message.messageBody : "",
              timestamp: dt.getTime(),
            };

            if (notice.message === newnotice.message) {
              // Same notice from server. No need to display...
              Util.setNotice({
                message: notice.message,
                title: notice.title,
                timestamp: dt.getTime(),
              });
            } else {
              // New notice from server...
              Util.setNotice(newnotice);
              Util.setShowNotice("Y");
              setNotice(newnotice);
              setShowNotification("Y");
            }
          } else {
            if (Util.getDebugMode()) console.log("data is null");
            // Data is null or undefined...
          }
        } else {
          if (Util.getDebugMode()) console.log("response is null");
        }
      });
    } else {
      // Wait for 10 minutes before next call...
      if (Util.getDebugMode())
        console.log("Waiting for next notification check.");
    }

    if (showNotification === "Y") {
      setShowToast(true);
    } else {
      setShowToast(false);
    }
  }, [showNotification]);

  const onDismiss = () => {
    Util.setShowNotice("N");
    setShowToast(false);
  };

  const getToastMessages = () => {
    if (showNotification === "Y") {
      return (
        <>
          <IonToast
            icon={informationCircle}
            cssClass={"mpp-custom-toast-message"}
            position="top"
            header={notice.title}
            isOpen={showToast}
            onDidDismiss={onDismiss}
            message={notice.message}
            duration={10000}
            buttons={[
              {
                side: "end",
                icon: closeOutline,
                handler: () => {
                  onDismiss();
                },
              },
            ]}
          />
        </>
      );
    } else {
      return <></>;
    }
  };

  return <>{getToastMessages()}</>;
};

export default CustomNotification;
