import {
  IonCol,
  IonContent,
  IonGrid,
  IonList,
  IonMenu,
  IonRow,
} from "@ionic/react";
import "./styles.css";
import * as Util from "../../components/Util";
import { useState } from "react";

const ContextMenu = (props: any) => {
  const product = Util.getProduct();
  const [isEnable] = useState(product.length > 0 ? true : false);

  return (
    <IonMenu
      disabled={!isEnable}
      side="end"
      contentId={props.contentId}
      menuId="context"
      type="overlay"
      placeholder={undefined}
    >
      <IonContent placeholder={undefined}>
        <IonList placeholder={undefined}>
          <IonGrid placeholder={undefined}>
            <IonRow placeholder={undefined}>
              <IonCol placeholder={undefined}>Context Menu</IonCol>
            </IonRow>
            <IonRow placeholder={undefined}>
              <IonCol placeholder={undefined}>Future Place Holder</IonCol>
            </IonRow>
          </IonGrid>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default ContextMenu;
