import { IonAccordion, IonItem, IonLabel, IonList } from "@ionic/react";
import React from "react";
import { CustomRowTableBox } from "../../../components/CustomRowTableBox";
import { Product } from "../../../models/Product";
import { getSupplierCost } from "../../../services/LookerService";
import * as Util from "../../../components/Util";
import { DataValue } from "../../../components/DataTypes";
import "../styles.css";

const SupplierCostPrice = (props: { product: Product | undefined }) => {
  interface supplierCostData {
    pin?: DataValue;
    supplier_id?: DataValue;
    supplier_name?: DataValue;
    supplier_site_name?: DataValue;
    pack_size?: DataValue;
    ti?: DataValue;
    hi?: DataValue;
    pallet_size?: DataValue;
    unit_cost?: DataValue;
    case_cost?: DataValue;
  }

  const [supplierCosts, setSupplierCosts] =
    React.useState<supplierCostData[]>();

  const [productMin] = React.useState<string | undefined>(
    props.product?.itemNumber
  );

  const [isLoading, setLoading] = React.useState<boolean>();

  const handleServiceResponse = (response: {
    ok: boolean;
    value: any[];
    error: any;
  }) => {
    if (Util.getDebugMode()) console.log(JSON.stringify(response));
    if (response.ok) {
      const data_by_supplier_cost: any[] = [];
      const rows = response.value;
      rows.forEach((row) => {
        const dataRows_by_supplier_cost: DataValue[] = [];

        const pin: DataValue = {
          key: "pin",
          value: row["unit_cost.PIN"],
          type: "number",
          styleClass:
            row["unit_cost.Primary_Supplier_Indicator"] === "Yes"
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        const supplier_id: DataValue = {
          key: "supplier_id",
          value: row["unit_cost.Supplier_ID"],
          type: "number",
          styleClass:
            row["unit_cost.Primary_Supplier_Indicator"] === "Yes"
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        const supplier_name: DataValue = {
          key: "supplier_name",
          value: row["unit_cost.Supplier_Name"],
          type: "string",
          styleClass:
            row["unit_cost.Primary_Supplier_Indicator"] === "Yes"
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        const supplier_site_id: DataValue = {
          key: "supplier_site_id",
          value: row["unit_cost.Supplier_Site_ID"],
          type: "number",
          styleClass:
            row["unit_cost.Primary_Supplier_Indicator"] === "Yes"
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        const supplier_site_name: DataValue = {
          key: "supplier_site_name",
          value: row["unit_cost.Supplier_Site_Name"],
          type: "string",
          styleClass:
            row["unit_cost.Primary_Supplier_Indicator"] === "Yes"
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        const supplier_site_name_id: DataValue = {
          key: "supplier_site_name_id",
          value: supplier_site_name.value + " (" + supplier_site_id.value + ")",
          type: "string",
          styleClass:
            row["unit_cost.Primary_Supplier_Indicator"] === "Yes"
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        const pack_size: DataValue = {
          key: "pack_size",
          value: row["unit_cost.Pack_Size"],
          type: "number",
          styleClass:
            row["unit_cost.Primary_Supplier_Indicator"] === "Yes"
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        const unit_cost: DataValue = {
          key: "unit_cost",
          value: row["unit_cost.Supplier_Unit_Cost"],
          type: "number",
          styleClass:
            row["unit_cost.Primary_Supplier_Indicator"] === "Yes"
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        const ti: DataValue = {
          key: "ti",
          value: row["unit_cost.TI"],
          type: "number",
          styleClass:
            row["unit_cost.Primary_Supplier_Indicator"] === "Yes"
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        const hi: DataValue = {
          key: "hi",
          value: row["unit_cost.HI"],
          type: "number",
          styleClass:
            row["unit_cost.Primary_Supplier_Indicator"] === "Yes"
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        const pallet_size: DataValue = {
          key: "pallet_size",
          value: row["unit_cost.Pallet_Size"],
          type: "number",
          styleClass:
            row["unit_cost.Primary_Supplier_Indicator"] === "Yes"
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        const case_cost: DataValue = {
          key: "case_cost",
          value: row["unit_cost.Case_Cost"],
          type: "number",
          styleClass:
            row["unit_cost.Primary_Supplier_Indicator"] === "Yes"
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        const supplier_currency: DataValue = {
          key: "supplier_currency",
          value: row["unit_cost.Supplier_Currency"],
          type: "string",
          styleClass:
            row["unit_cost.Primary_Supplier_Indicator"] === "Yes"
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        const country_of_supply: DataValue = {
          key: "country_of_supply",
          value: row["unit_cost.Country_Of_Supply"],
          type: "string",
          styleClass:
            row["unit_cost.Primary_Supplier_Indicator"] === "Yes"
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        dataRows_by_supplier_cost.push(
          pin,
          supplier_id,
          supplier_name,
          supplier_site_name_id,
          pack_size,
          ti,
          hi,
          pallet_size,
          unit_cost,
          case_cost,
          supplier_currency,
          country_of_supply
        );

        data_by_supplier_cost.push(dataRows_by_supplier_cost);
      });

      setSupplierCosts(data_by_supplier_cost);
    } else {
      console.error(
        "No supplier cost found for " +
          productMin +
          " for store " +
          Util.getStore()
      );
    }
  };

  React.useEffect(() => {
    if (productMin) {
      setLoading(true);
      getSupplierCost(productMin).then((response) => {
        handleServiceResponse(response);
        setLoading(false);
      });
    }
  }, [productMin]);

  const SupplierCostPriceContent = () => {
    const header = [
      "PIN",
      "Supplier ID",
      "Supplier Name",
      "Supplier Site Name (ID)",
      "Pack Size",
      "Ti",
      "Hi",
      "Pallet Size",
      "Unit Cost",
      "Case Cost",
      "Supplier Currency",
      "Country of Supply"
    ];
    const fixedColumns = ["PIN"];
    const data = supplierCosts;
    const title = "Supplier Cost Price";
    return (
      <CustomRowTableBox
        title={title}
        header={header}
        data={data}
        fixedColumns={fixedColumns}
        csvFileName={
          "SupplierCostPrice" +
          "_MIN-" +
          productMin
            ?.replaceAll(" ", "-")
            .replaceAll("(", "")
            .replaceAll(")", "") +
          ".csv"
        }
      />
    );
  };

  const headerName = "Supplier Information";
  return (
    <IonAccordion aria-expanded placeholder={undefined}>
      <IonItem
        slot="header"
        className="mpp-accordian-header-item"
        placeholder={undefined}
      >
        <IonLabel
          className="mpp-accordian-header-label"
          placeholder={undefined}
        >
          {headerName}
          {isLoading ? " (Loading...)" : ""}
        </IonLabel>
      </IonItem>
      <IonList slot="content" placeholder={undefined}>
        <SupplierCostPriceContent />
      </IonList>
    </IonAccordion>
  );
};

export default SupplierCostPrice;
