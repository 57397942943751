import { IonAccordion, IonItem, IonLabel, IonList } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Product } from "../../../models/Product";
import * as Util from "../../../components/Util";
import { CustomRowTableBox } from "../../../components/CustomRowTableBox";
import { CustomTableWithTotalBox } from "../../../components/CustomTableWithTotalBox";
import { DataValue } from "../../../components/DataTypes";
import { CustomTableBox } from "../../../components/CustomTableBox";
import {
  getDelistingDetails,
  getPreviousRangeInformation,
  getRangeInformation,
  getSpaceInformation,
  getStoreReangedStatus,
  getTotalRanged,
} from "../../../services/LookerService";

interface SpaceData {
  planName?: DataValue;
  type?: DataValue;
  aisle?: DataValue;
  bay?: DataValue;
  shelf?: DataValue;
  position?: DataValue;
  fill?: DataValue;
  facings?: DataValue;
  high?: DataValue;
  deep?: DataValue;
}

// Space Section...
const SpaceSection = (props: { product: Product | undefined }) => {
  const [productMin] = React.useState<string | undefined>(
    props.product?.itemNumber
  );

  const [storeSpaceData, setStoreSpaceData] = React.useState<SpaceData[]>();
  const [storeSpaceSummary, setStoreSpaceSummary] = React.useState<{
    header: string;
    rows: { key: string; value: any; isTotal: boolean }[];
  }>();

  const [isLoading, setLoading] = React.useState<boolean>();
  const [isRangeLoading, setRangeLoading] = React.useState<boolean>();
  const [isSpaceLoading, setSpaceLoading] = React.useState<boolean>();
  const [isTotalLoading, setTotalLoading] = React.useState<boolean>();
  const [isRangeStatusLoading, setRangeStatusLoading] =
    React.useState<boolean>();

  const [delistingData, setdelistingData] = React.useState<{
    header: string;
    rows: {
      key: string;
      value: any;
    }[];
  }>();
  const [delistingDetails, setDelistingDetails] = useState<any>();
  const [rangeSummary, setRangeSummary] = useState<any>();
  const [storeRangedStatus, setStoreRangedStatus] = useState<any>();
  const [callPreviousDay, setCallPreviousDay] = useState<boolean>(false);

  const handleSpaceInformationResponse = (response: {
    ok: boolean;
    value: any[];
    error: any;
  }) => {
    if (response.ok) {
      const spaceData: any[] = [];
      const rows = response.value;
      rows.forEach((row) => {
        const dataRows: DataValue[] = [];

        const planName: DataValue = {
          key: "planName",
          value: row["location_space_info_plano.Planogram_Class"],
          type: "string",
        };

        const aisle: DataValue = {
          key: "aisle",
          value: row["location_space_info_plano.aisle"],
          type: "string",
        };

        const bay: DataValue = {
          key: "bay",
          value: row["location_space_info_plano.starts_bay"],
          type: "string",
        };

        const shelf: DataValue = {
          key: "shelf",
          value: row["location_space_info_plano.shelf_from_bottom"],
          type: "string",
        };

        const position: DataValue = {
          key: "position",
          value: row["location_space_info_plano.position_from_left"],
          type: "string",
        };

        const fill: DataValue = {
          key: "fill",
          value: row["location_space_info_plano.units"],
          type: "string",
        };

        const facings: DataValue = {
          key: "facings",
          value: row["location_space_info_plano.facings_wide"],
          type: "string",
        };

        const high: DataValue = {
          key: "high",
          value: row["location_space_info_plano.facings_high"],
          type: "string",
        };

        const deep: DataValue = {
          key: "deep",
          value: row["location_space_info_plano.facings_deep"],
          type: "string",
        };

        dataRows.push(
          planName,
          aisle,
          bay,
          shelf,
          position,
          fill,
          facings,
          high,
          deep
        );

        spaceData.push(dataRows);
      });
      setStoreSpaceData(spaceData);
    } else {
      console.error(
        "No space information found for " +
          productMin +
          " for store " +
          Util.getStore()
      );
    }
  };

  const handleRangeInformationResponse = (
    response: {
      ok: boolean;
      value: any[];
      error: any;
    },
    table: string
  ) => {
    if (response.ok) {
      const element = response.value[0];
      if (table === "store_stock" && element["store_stock.Total_Space"] === 0) {
        setCallPreviousDay(true);
      } else {
        const summary = {
          header: "Store Space Summary",
          rows: [
            {
              key: "Standard Space",
              isTotal: false,
              value: element[table + ".standard_space"],
            },
            {
              key: "Promotional Space",
              isTotal: false,
              value: element[table + ".promo_space"],
            },
            {
              key: "Discretionary Space",
              isTotal: false,
              value: element[table + ".discret_space"],
            },
            {
              key: "Total Space",
              isTotal: true,
              value: element[table + ".Total_Space"],
            },
          ],
        };
        setStoreSpaceSummary(summary);
      }
    } else {
      console.error(
        "No range information found for " +
          productMin +
          " for store " +
          Util.getStore()
      );
    }
  };

  React.useEffect(() => {
    if (callPreviousDay && productMin) {
      setRangeLoading(true);
      const getPreviousRangeInformationRes = getPreviousRangeInformation(
        productMin,
        Util.getStore()
      );
      getPreviousRangeInformationRes
        .then((response) => {
          handleRangeInformationResponse(response, "store_stock_range");
        })
        .finally(() => {
          setRangeLoading(false);
        });
    }
  }, [productMin, callPreviousDay]);

  // Get Range Information...
  React.useEffect(() => {
    if (productMin) {
      setRangeLoading(true);
      const getRangeInformationRes = getRangeInformation(
        productMin,
        Util.getStore()
      );
      getRangeInformationRes
        .then((response) => {
          handleRangeInformationResponse(response, "store_stock");
        })
        .finally(() => {
          setRangeLoading(false);
        });
    }
  }, [productMin]);

  // Get Space Information...
  React.useEffect(() => {
    if (productMin) {
      setSpaceLoading(true);
      const getSpaceInformationRes = getSpaceInformation(
        productMin,
        Util.getStore()
      );
      getSpaceInformationRes
        .then((response) => {
          handleSpaceInformationResponse(response);
        })
        .finally(() => {
          setSpaceLoading(false);
        });
    }
  }, [productMin]);

  // Load data for all other components asynchronously...
  React.useEffect(() => {
    if (productMin) {
      setTotalLoading(true);
      const getTotalRangedRes = getTotalRanged(productMin);
      getTotalRangedRes
        .then((response) => {
          handleGetTotalRangedRes(response);
        })
        .finally(() => {
          setTotalLoading(false);
        });
    }
  }, [productMin]);

  const handleGetTotalRangedRes = (response: {
    ok: boolean;
    value: any[];
    error: any;
  }) => {
    if (response.ok) {
      const element = response.value[0];
      setRangeSummary(element);
    }
  };

  React.useEffect(() => {
    if (productMin) {
      setRangeStatusLoading(true);
      const getStoreReangedStatusRes = getStoreReangedStatus(
        productMin,
        Util.getStore()
      );
      getStoreReangedStatusRes
        .then((response) => {
          handleStoreReangedStatusRes(response);
        })
        .finally(() => {
          setRangeStatusLoading(false);
        });
    }
  }, [productMin]);

  React.useEffect(() => {
    setLoading(
      isSpaceLoading || isRangeLoading || isRangeStatusLoading || isTotalLoading
    );
  }, [isSpaceLoading, isRangeLoading, isRangeStatusLoading, isTotalLoading]);

  const handleStoreReangedStatusRes = (response: {
    ok: boolean;
    value: any[];
    error: any;
  }) => {
    if (response.ok) {
      const element = response.value[0];
      setStoreRangedStatus(element);
    }
  };

  React.useEffect(() => {
    if (productMin) {
      const getDelistingDetailsRes = getDelistingDetails(
        productMin,
        Util.getStore()
      );
      getDelistingDetailsRes
        .then((response) => {
          handleDelistingDetailsRes(response);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [productMin]);

  const handleDelistingDetailsRes = (response: {
    ok: boolean;
    value: any[];
    error: any;
  }) => {
    if (response.ok) {
      const element = response.value[0];
      setDelistingDetails(element);
    }
  };

  useEffect(() => {
    const data = {
      header: "Delisting Information",
      rows: [
        {
          key: "Due To Be Delisted?",
          value:
            delistingDetails &&
            delistingDetails["delist_derange.due_to_be_delisted"]
              ? delistingDetails["delist_derange.due_to_be_delisted"]
              : Util.NOT_AVAILABLE,
        },
        {
          key: "Delisting Date",
          value:
            delistingDetails && delistingDetails["delist_derange.delist_date"]
              ? Util.yyyymmddToDdmmyyyy(
                  delistingDetails["delist_derange.delist_date"]
                )
              : Util.NOT_AVAILABLE,
        },
        {
          key: "Due To Be Deranged?",
          value:
            delistingDetails &&
            delistingDetails["delist_derange.due_to_be_deranged"]
              ? delistingDetails["delist_derange.due_to_be_deranged"]
              : Util.NOT_AVAILABLE,
        },
        {
          key: "Deranging Date",
          value:
            delistingDetails && delistingDetails["delist_derange.derange_date"]
              ? Util.yyyymmddToDdmmyyyy(
                  delistingDetails["delist_derange.derange_date"]
                )
              : Util.NOT_AVAILABLE,
        },
        {
          key: "Ranged In " + Util.getStore(),
          value:
            storeRangedStatus &&
            storeRangedStatus["store_stock.store_range_ind"]
              ? storeRangedStatus["store_stock.store_range_ind"]
              : Util.NOT_AVAILABLE,
        },
        {
          key: "Stores Ranged Count",
          value:
            rangeSummary && rangeSummary["product_search.stores_ranged"]
              ? rangeSummary["product_search.stores_ranged"]
              : Util.NOT_AVAILABLE,
        },
      ],
    };

    let rows = data.rows;
    rows = rows.filter((row) => row.value !== Util.NOT_AVAILABLE);

    data.rows = rows;
    setdelistingData(data);
  }, [delistingDetails, rangeSummary, storeRangedStatus]);

  const StoreSpaceInformationContent = () => {
    const header = [
      "Plan Name",
      "Aisle",
      "Bay",
      "Shelf",
      "Position",
      "Fill",
      "Facings",
      "High",
      "Deep",
    ];
    const fixedColumns = ["Type"];
    const data = storeSpaceData;
    const title = "Store Space Information";

    return (
      <CustomRowTableBox
        title={title}
        header={header}
        data={data}
        fixedColumns={fixedColumns}
        csvFileName={
          "StoreSpaceReport" +
          "_MIN-" +
          productMin
            ?.replaceAll(" ", "-")
            .replaceAll("(", "")
            .replaceAll(")", "") +
          ".csv"
        }
        allCapital={true}
        allAlignCenter={true}
      />
    );
  };

  const DelistInformationBox = () => {
    if (delistingData) {
      return <CustomTableBox {...delistingData} />;
    } else {
      return <></>;
    }
  };

  const headerName = "Location & Space";
  return (
    <IonAccordion placeholder={undefined}>
      <IonItem
        slot="header"
        className="mpp-accordian-header-item"
        placeholder={undefined}
      >
        <IonLabel
          className="mpp-accordian-header-label"
          placeholder={undefined}
        >
          {headerName}
          {isLoading ? " (Loading...)" : ""}
        </IonLabel>
      </IonItem>
      <IonList slot="content" placeholder={undefined}>
        <CustomTableWithTotalBox {...storeSpaceSummary} />
        <DelistInformationBox />
        <StoreSpaceInformationContent />
      </IonList>
    </IonAccordion>
  );
};

export default SpaceSection;
