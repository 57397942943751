import {
  IonAccordion,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import React from "react";
import { Product } from "../../../models/Product";
import {
  getProductPriceCall,
  getProductPromotionCall,
} from "../../../services/CloudFunctionService";
import CurrentPriceBox from "./CurrentPriceBox";
import FuturePriceBox from "./FuturePriceBox";
import * as Util from "../../../components/Util";
import { Price } from "../../../models/Price";
import { Promotion } from "../../../models/Promotion";
import CompetitorPriceBox from "../../ProductDetails/PageComponents/CompetitorPriceBox";
import MultiBuyPromotionsBox from "./MultiBuyPromotionsBox";

// Price Section...
const PriceSection = (props: {
  product: Product | undefined;
  isCorporate: boolean;
  countries: string[];
}) => {
  const [product] = React.useState<Product | undefined>(props.product);
  const [productMin] = React.useState<string | undefined>(
    props.product?.itemNumber
  );
  const [price, setPrice] = React.useState<Price>();
  const [promotion, setPromotion] = React.useState<Promotion>();
  const [isLoading, setLoading] = React.useState<boolean>();
  const [country, setCountry] = React.useState<string>(props.countries[0]);

  // Load data for all other components asynchronously...
  React.useEffect(() => {
    if (productMin) {
      setLoading(true);
      // Load Price information...
      const getProductPriceRes = getProductPriceCall(
        productMin,
        Util.getIdToken(),
        props.isCorporate,
        country
      );
      getProductPriceRes.then((response) => {
        if (Util.getDebugMode()) console.log(response);
        if (response.errorCode) {
          console.error(
            "Product Price Call Failed With Error " + response.errorMessage
          );
        } else {
          setPrice(response);
        }
      });
    }
  }, [productMin, country]);

  // Load Promotion information...
  React.useEffect(() => {
    if (productMin) {
      if (price) {
        setLoading(true);
        const getProductPromotionRes = getProductPromotionCall(
          productMin,
          Util.getIdToken(),
          props.isCorporate,
          country
        );
        getProductPromotionRes
          .then((response) => {
            if (Util.getDebugMode()) console.log(response);
            if (response.errorCode) {
              console.error(
                "Store Space Call Failed With Error " + response.errorMessage
              );
            } else {
              setPromotion(response);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [price]);

  const getComponents = () => {
    return (
      <IonGrid className="mpp-ion-grid" slot="content" placeholder={undefined}>
        <IonRow
          className="mpp-ion-grid-row"
          aria-setsize={3}
          placeholder={undefined}
        >
          <IonCol className="mpp-ion-grid-infobox" placeholder={undefined}>
            <CurrentPriceBox
              {...{ product: product, promotion: promotion, price: price }}
            />
          </IonCol>
          <IonCol className="mpp-ion-grid-infobox" placeholder={undefined}>
            <FuturePriceBox
              {...{ product: product, promotion: promotion, price: price }}
            />
          </IonCol>
          <IonCol placeholder={undefined}>
            <MultiBuyPromotionsBox
              {...{ product: product, promotion: promotion, price: price }}
            />
          </IonCol>
          <IonCol placeholder={undefined}>
            <CompetitorPriceBox {...product} />
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  };

  const getSegmentLabelClass = (val: string) => {
    if (country === val) {
      return "mpp-segment-lebel-selected";
    } else {
      return "mpp-segment-lebel";
    }
  };

  const headerName = "Price & Promotion";
  return (
    <IonAccordion placeholder={undefined}>
      <IonItem
        slot="header"
        className="mpp-accordian-header-item"
        placeholder={undefined}
      >
        <IonLabel
          className="mpp-accordian-header-label"
          placeholder={undefined}
        >
          {headerName}
          {isLoading ? " (Loading...)" : ""}
        </IonLabel>
      </IonItem>
      <IonList slot="content" placeholder={undefined}>
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol
              size="6"
              style={{ minWidth: "350px" }}
              placeholder={undefined}
            >
              <IonSegment
                value={country}
                selectOnFocus={true}
                mode={"ios"}
                placeholder={undefined}
              >
                {props.countries.map((val) => {
                  return (
                    <IonSegmentButton
                      value={val}
                      onClick={() => setCountry(val)}
                      placeholder={undefined}
                    >
                      <IonLabel
                        className={getSegmentLabelClass(val)}
                        placeholder={undefined}
                      >
                        {Util.initCap(val)}
                      </IonLabel>
                    </IonSegmentButton>
                  );
                })}
              </IonSegment>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonList>
      {getComponents()}
    </IonAccordion>
  );
};

export default PriceSection;
