import {
  IonButtons,
  IonHeader,
  IonIcon,
  IonImg,
  IonMenuButton,
  IonPage,
  IonRouterOutlet,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { menu } from "ionicons/icons";
import ContextMenu from "../ContextMenu";
import Menu from "../Menu";
import * as Util from "../../components/Util";
import "./styles.css";
import CustomNotification from "../../components/CustomNotification";

const PageTemplate = ({ children }: any) => {
  return (
    <IonPage placeholder={undefined}>
      <Menu contentId="main" />
      <IonRouterOutlet id="main" placeholder={undefined} />
      <ContextMenu contentId="context" />
      <IonRouterOutlet id="context" placeholder={undefined} />
      <IonHeader placeholder={undefined}>
        <IonToolbar
          className="mpp-template-header-toolbar"
          placeholder={undefined}
        >
          <IonButtons placeholder={undefined}>
            <IonMenuButton menu="main" placeholder={undefined}>
              <IonIcon
                className="mpp-template-header-button-icon"
                icon={menu}
                placeholder={undefined}
              />
            </IonMenuButton>
            <IonTitle placeholder={undefined}>
              <IonText
                className="mpp-template-header-button-text"
                placeholder={undefined}
              >
                My Product Profile
              </IonText>
            </IonTitle>
            <IonImg
              className="mpp-template-header-logo"
              src={
                Util.isDarkMode()
                  ? require("../../assets/images/mymorilogodark.png")
                  : require("../../assets/images/mymorilogo.png")
              }
              placeholder={undefined}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <CustomNotification />
      {children}
    </IonPage>
  );
};

export default PageTemplate;
