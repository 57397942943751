import { CustomTableBox } from "../../../components/CustomTableBox";
import { Product } from "../../../models/Product";
import * as Util from "../../../components/Util";

const OtherInformationBox = (product: Product) => {
  //const product = Util.getProductJson();

  const data = {
    header: "Other Information",
    rows: [
      {
        key: "Unit Of Measure",
        value: product?.sellingUnitOfMeasure
          ? product?.sellingUnitOfMeasure
          : Util.NOT_AVAILABLE,
        isUpper: true,
      },
      {
        key: "Country Of Origin",
        value: product?.countryOfOrigin
          ? product?.countryOfOrigin
          : Util.NOT_AVAILABLE,
      },
      { key: "Catch Weight", value: product?.isCatchWeight ? "Yes" : "No" },
      {
        key: "Orderable Indicator",
        value: product?.productFlags?.orderableInd ? "Yes" : "No",
      },
      {
        key: "Sellable Indicator",
        value: product?.productFlags?.sellableInd ? "Yes" : "No",
      },
      {
        key: "Store Live Date",
        value: product?.storeLiveDate
          ? Util.dayToDdmmyyyy(product?.storeLiveDate)
          : Util.NOT_AVAILABLE,
      },
      {
        key: "Product Life (Time Of Manufacture)",
        value: product?.productLife?.productLifeAtTimeOfManufacture
          ? product?.productLife?.productLifeAtTimeOfManufacture + " (Day)"
          : Util.NOT_AVAILABLE,
      },
      {
        key: "Product Life (Min Acceptable)",
        value: product?.productLife?.minimumAcceptableProductLifedays
          ? product?.productLife?.minimumAcceptableProductLifedays + " (Day)"
          : Util.NOT_AVAILABLE,
      },
      {
        key: "Min % of Manuf. Life on Receipt",
        value:
          product?.productLife?.minimumAcceptableProductLifedays &&
          product?.productLife?.productLifeAtTimeOfManufacture
            ? (
                parseInt(
                  product?.productLife?.minimumAcceptableProductLifedays
                ) /
                parseInt(product?.productLife?.productLifeAtTimeOfManufacture)
              ).toLocaleString(Util.LOCAL, {
                style: "percent",
              })
            : Util.NOT_AVAILABLE,
      },
    ],
  };

  return <CustomTableBox {...data} />;
};

export default OtherInformationBox;
