// scanner video feed
// see https://github.com/zxing-js/library

import React from "react";
import { IonContent, IonGrid, IonRow } from "@ionic/react";
import { BarcodeFormat, BrowserCodeReader } from "@zxing/browser";
import { MultiFormatReader, Result } from "@zxing/library";
import "./styles.css";

interface WebScannerProps {
  open: boolean;
  formats: BarcodeFormat[] | undefined;
  onClose: () => void;
  onScan: (code: string) => void;
  onScanError: (error: string) => void;
}

const timeout = 1000; // time between frames
const scale = 0.6; // scale of barcode scanner

// user clicked on the camera button - bring up scanner window.
const WebScanner: React.FC<WebScannerProps> = ({
  open,
  formats,
  onClose,
  onScan,
  onScanError,
}) => {
  const [reader] = React.useState(new MultiFormatReader());
  const [barcodeReader] = React.useState(new BrowserCodeReader(reader));
  const [videoStream, setVideoStream] = React.useState<MediaStream | null>(
    null
  );

  if (formats) barcodeReader.possibleFormats = formats;

  const closeScanner = React.useCallback(() => {
    if (videoStream) {
      videoStream.getTracks().forEach((track) => track.stop()); // stop webcam feed
      setVideoStream(null);
    }
    onClose();
  }, [onClose, videoStream]);

  const releaseMemory = React.useCallback(() => {
    const img: HTMLImageElement | null =
      document.querySelector("#scanner-image");

    if (img) {
      URL.revokeObjectURL(img.src); // release image blob memory
      img.src = "";
    }
  }, []);

  const found = React.useCallback(
    (result: Result) => {
      closeScanner();
      releaseMemory();
      onScan(result.toString());
    },
    [closeScanner, onScan, releaseMemory]
  );

  const notfound = React.useCallback(
    (err: { name: string }) => {
      if (err.name !== "NotFoundException") {
        onScanError(err.name);
      }
    },
    [onScanError]
  );

  React.useEffect(() => {
    const video: HTMLVideoElement | null = document.querySelector(
      "#scanner-video video"
    );
    const canvas: HTMLCanvasElement | null =
      document.querySelector("#scanner-canvas");
    const img: HTMLImageElement | null =
      document.querySelector("#scanner-image");
    const frame: HTMLDivElement | null =
      document.querySelector("#scanner-frame");

    const constraints: MediaStreamConstraints = {
      audio: false,
      video: {
        facingMode: { ideal: "environment" },
      },
    };

    if (video && frame && !videoStream) {
      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        setVideoStream(stream);

        // handle play callback
        video.addEventListener("play", () => {
          frame.style.left =
            (video.clientWidth - video.clientWidth * scale) / 2 + "px";
          frame.style.top =
            (video.clientHeight - video.clientHeight * scale) / 2 + "px";
          frame.style.width = video.clientWidth * scale + "px";
          frame.style.height = video.clientHeight * scale + "px";

          // start the barcode reader process
          scanFrame();
        });

        video.srcObject = stream;
      });
    }

    const scanFrame = () => {
      if (video && canvas && img) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const videoContext = canvas.getContext("2d");

        if (videoContext) {
          videoContext.drawImage(video, 0, 0);
        }

        createImageBitmap(canvas).then((blob) => {
          if (blob && canvas) {
            canvas.width = blob.width * scale;
            canvas.height = blob.height * scale;

            const ctx = canvas.getContext("2d");
            if (ctx) {
              ctx.drawImage(
                blob,
                // source x, y, w, h:
                (blob.width - blob.width * scale) / 2,
                (blob.height - blob.height * scale) / 2,
                blob.width * scale,
                blob.height * scale,
                // dest x, y, w, h
                0,
                0,
                canvas.width,
                canvas.height
              );
            }

            canvas.toBlob((smallblob) => {
              if (smallblob && img) {
                img.src = URL.createObjectURL(smallblob); // load the image blob
                img.onload = () => {
                  barcodeReader
                    .decodeFromImageUrl(URL.createObjectURL(smallblob))
                    .then(found) // calls onFoundBarcode with the barcode string
                    .catch(notfound)
                    .finally(releaseMemory);
                  img.onload = null;
                  setTimeout(scanFrame, timeout); // repeat
                };
              }
            });
          }
        });
      }
    };
  }, [barcodeReader, videoStream, found, notfound, releaseMemory]);

  return (
    <IonContent className="webScanner" placeholder={undefined}>
      <div className="scanner visible">
        <div id="scanner-video">
          <video autoPlay playsInline></video>
          <div id="scanner-frame"></div>
        </div>
      </div>
      <IonGrid placeholder={undefined}>
        <IonRow placeholder={undefined}>
          <canvas id="scanner-canvas"></canvas>
        </IonRow>
        <IonRow placeholder={undefined}>
          <img id="scanner-image" src="" alt="" />
        </IonRow>
        <IonRow placeholder={undefined}>
          <img id="scanner-image-hidden" src="" alt="" />
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default WebScanner;
