import { IonCol, IonGrid, IonItem, IonRow } from "@ionic/react";
import * as Util from "../Util";
import "./styles.css";

// This box shows data in column format means each column
// will be represented as a row with key value pair...
const CustomTableWithTotalBox = (props: {
  header?: string;
  headerNeeded?: boolean;
  rows?: {
    key: string;
    isTotal: boolean;
    value?: string | number | undefined;
  }[];
}) => {
  const getHeader = () => {
    if (props.headerNeeded) {
      return (
        <IonRow
          className="custom-table-box-grid-title-row"
          placeholder={undefined}
        >
          <IonCol placeholder={undefined}>{props.header}</IonCol>
        </IonRow>
      );
    } else {
      return <></>;
    }
  };

  const getValueHTML = (row: {
    key: string | undefined;
    isTotal: boolean;
    value?: string | number | undefined;
  }) => {
    return <>{row.value ? Util.initCap(row.value.toString()) : row.value}</>;
  };

  const getColClassName = (isTitle: boolean): string => {
    if (isTitle) {
      return "custom-table-box-grid-column-header-total";
    } else {
      return "custom-table-box-grid-column-header";
    }
  };

  const getRowClassName = (isTitle: boolean): string => {
    if (isTitle) {
      return "custom-table-box-grid-column-row-total";
    } else {
      return "custom-table-box-grid-column-row";
    }
  };

  return (
    <IonItem lines="none" placeholder={undefined}>
      <IonGrid className="custom-table-box-grid" placeholder={undefined}>
        {getHeader()}
        {props.rows?.map((row) => {
          return (
            <IonRow
              key={row.key}
              className={getRowClassName(row.isTotal)}
              placeholder={undefined}
            >
              <IonCol
                size="8"
                className={getColClassName(row.isTotal)}
                placeholder={undefined}
              >
                <div className={"selectable"}>{row.key}</div>
              </IonCol>
              <IonCol
                size="4"
                className="custom-table-with-total-box-grid-column-value"
                placeholder={undefined}
              >
                <div className={"selectable"}>{getValueHTML(row)}</div>
              </IonCol>
            </IonRow>
          );
        })}
      </IonGrid>
    </IonItem>
  );
};

export { CustomTableWithTotalBox };
