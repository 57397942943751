import { IonAccordion, IonItem, IonLabel, IonList } from "@ionic/react";
import React from "react";
import { Product } from "../../../models/Product";
import { getProductRetailPriceHistoryCall } from "../../../services/CloudFunctionService";
import * as Util from "../../../components/Util";
import { RetailPriceHistory } from "../../../models/StoreSpace";
import { CustomRowTableBox } from "../../../components/CustomRowTableBox";
import { DataValue } from "../../../components/DataTypes";

interface RetailPriceData {
  effectiveDate?: DataValue;
  endDate?: DataValue;
  type?: DataValue;
  retailPrice?: DataValue;
  /*startDate?: DataValue;*/
}

// Space Section...
const RetailPriceHistorySection = (props: {
  product: Product | undefined;
  isCorporate: boolean;
}) => {
  const [productMin] = React.useState<string | undefined>(
    props.product?.itemNumber
  );

  const [retailPriceData, setRetailPriceData] =
    React.useState<RetailPriceData[]>();

  const [isLoading, setLoading] = React.useState<boolean>();

  // Load data for all other components asynchronously...
  React.useEffect(() => {
    if (productMin) {
      setLoading(true);
      const getProductRetailPriceHistoryRes = getProductRetailPriceHistoryCall(
        productMin,
        Util.getIdToken(),
        props.isCorporate
      );
      getProductRetailPriceHistoryRes
        .then((response) => {
          if (response.errorCode) {
            console.error(
              "Get Product Retail Price History Call Failed With Error " +
                response.errorMessage
            );
          } else {
            processProductRetailPriceHistoryRes(response);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [productMin]);

  const processProductRetailPriceHistoryRes = (
    response: RetailPriceHistory
  ) => {
    const retailPrices = response?.retailPrices;
    if (retailPrices) {
      const data_retail_price_history: any[] = [];
      retailPrices.forEach((inRetailPrice) => {
        const dataRows: DataValue[] = [];

        const effectiveDate: DataValue = {
          key: "effectiveDate",
          value: inRetailPrice.effectiveDate,
          type: "longdate",
        };

        const type: DataValue = {
          key: "type",
          value: inRetailPrice.type,
          type: "string",
        };

        const retailPrice: DataValue = {
          key: "retailPrice",
          value: inRetailPrice.retailPrice,
          type: "currency",
        };

        const endDate: DataValue = {
          key: "endDate",
          value: inRetailPrice.endDate,
          type: "longdate",
          alternalte: "-",
        };

        dataRows.push(effectiveDate, endDate, type, retailPrice);
        data_retail_price_history.push(dataRows);
      });
      setRetailPriceData(data_retail_price_history);
    }
  };

  const RetailPriceHistoryContent = () => {
    const header = ["Effective Date", "End Date", "Price Type", "Retail Price"];
    const fixedColumns = ["Effective Date"];
    const data = retailPriceData;
    const title = "Retail Price History";

    return (
      <CustomRowTableBox
        title={title}
        header={header}
        data={data}
        fixedColumns={fixedColumns}
        csvFileName={
          "RetailPriceHistoryReport" +
          "_MIN-" +
          productMin
            ?.replaceAll(" ", "-")
            .replaceAll("(", "")
            .replaceAll(")", "") +
          ".csv"
        }
        allCapital={false}
        allAlignCenter={false}
      />
    );
  };

  const headerName = "Retail Price History";
  return (
    <IonAccordion placeholder={undefined}>
      <IonItem
        slot="header"
        className="mpp-accordian-header-item"
        placeholder={undefined}
      >
        <IonLabel
          className="mpp-accordian-header-label"
          placeholder={undefined}
        >
          {headerName}
          {isLoading ? " (Loading...)" : ""}
        </IonLabel>
      </IonItem>
      <IonList slot="content" placeholder={undefined}>
        <RetailPriceHistoryContent />
      </IonList>
    </IonAccordion>
  );
};

export default RetailPriceHistorySection;
