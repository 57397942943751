import { CustomTableBox } from "../../../components/CustomTableBox";
import { Product } from "../../../models/Product";
import * as Util from "../../../components/Util";
import { lables } from "../../../components/Labels";

const BasicInformationBox = (product: Product) => {
  //const product = Util.getProductJson();

  // Calcualte primary barcode of the product : start
  const gtins = product?.gtins;
  let primaryBarcode = "";
  gtins?.forEach((gtin) => {
    if (gtin.additionalProperties) {
      if (gtin.additionalProperties.isPrimaryBarcode) {
        primaryBarcode = gtin.id;
      }
    }
  });
  // Calcualte primary barcode of the product : end

  const packs = product?.packs;
  const values: string[] = [];
  packs?.forEach((e) => {
    if (e.packNumber) values.push(e.packNumber + " (" + e.packQuantity + ")");
  });

  const data = {
    header: lables.LBL_SECTION_PRODUCT_BASIC_INFORMATION,
    rows: [
      { key: lables.LBL_NAME, value: product?.itemDescription },
      {
        key: lables.LBL_DESCRIPTION,
        value: product?.customerFriendlyDescription,
      },
      { key: lables.LBL_PRODUCT_MIN, value: product?.itemNumber },
      { key: lables.LBL_PRIMARY_BARCODE, value: primaryBarcode },
      /*{ key: "PIN (Case Size)", value: values.join(", ") },*/
      { key: lables.LBL_PRODUCT_MAN, value: product?.parentItemNumber },
      {
        key: lables.LBL_TUC,
        value: product.tuc ? product.tuc : Util.NOT_AVAILABLE,
      },
      {
        key: lables.LBL_VAT_RATE,
        value: product?.vatRate
          ? (product?.vatRate / 100).toLocaleString(Util.LOCAL, {
              style: "percent",
            })
          : product?.vatRate === 0
          ? "0%"
          : Util.NOT_AVAILABLE,
      },
      {
        key: lables.LBL_ALCOHOL_BY_VOLUME,
        value: product?.beersWinesSpirits?.alcoholByVolume
          ? product?.beersWinesSpirits?.alcoholByVolume + "%"
          : Util.NOT_APPLICABLE,
      },
    ],
  };

  return <CustomTableBox {...data} />;
};

export default BasicInformationBox;
