import { IonCol, IonGrid, IonLabel, IonRow, IonSearchbar } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import * as Util from "../Util";
import "./styles.css";

const CustomSearchAndSelect = (props: {
  initValue: string | undefined;
  options: string[];
  onclickFn: (value: string) => void;
  disabled?: boolean;
}) => {
  const [lookup, setLookup] = useState<string | undefined>(props.initValue);
  const [initValue, setInitValue] = useState<string | undefined>(
    props.initValue
  );
  const [isHidden, setHidden] = useState<boolean>(true);
  const searchBarRef = useRef(null);

  // TODO Make it configurable...
  const limit = 20;
  let counter = 0;

  const setFocus = async (ref: any) => {
    if (ref) {
      const ie = await ref.getInputElement();
      ie.current.focus();
    } else {
      if (Util.getDebugMode()) console.log("focusElement got no ref");
    }
  };

  useEffect(() => {
    setHidden(true);
  }, [initValue]);

  return (
    <>
      <IonSearchbar
        ref={searchBarRef}
        className="mpp_search_bar"
        value={lookup}
        placeholder="Search And Select"
        onIonChange={(e) => {
          setLookup(e.detail.value);
          if (initValue === e.detail.value) {
            setHidden(true);
          } else {
            setHidden(false);
          }
        }}
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            const vals: string[] = [];
            props.options.forEach((element) => {
              if (lookup) {
                if (
                  element &&
                  element.toUpperCase().indexOf(lookup.toUpperCase()) > -1
                ) {
                  vals.push(element);
                }
                if (vals.length === 1) {
                  props.onclickFn(vals[0]);
                  setLookup(vals[0]);
                  setInitValue(vals[0]);
                  setHidden(true);
                  setFocus(searchBarRef);
                }
              }
            });
          }
        }}
        disabled={props.disabled}
      />
      <IonGrid
        className="mpp_custom_grid"
        hidden={isHidden}
        placeholder={undefined}
      >
        {props.options.map((element) => {
          if (lookup) {
            if (
              element &&
              counter < limit &&
              element.toUpperCase().indexOf(lookup.toUpperCase()) > -1
            ) {
              counter++;
              return (
                <IonRow
                  className="mpp_custom_grid_row"
                  key={element}
                  placeholder={undefined}
                >
                  <IonCol placeholder={undefined}>
                    <IonLabel
                      tabIndex={2}
                      className="mpp_custom_grid_lebel"
                      onClick={() => {
                        props.onclickFn(element);
                        setLookup(element);
                        setInitValue(element);
                        setHidden(true);
                        setFocus(searchBarRef);
                      }}
                      placeholder={undefined}
                    >
                      {element}
                    </IonLabel>
                  </IonCol>
                </IonRow>
              );
            }
            return null;
          } else {
            if (element && counter < limit) {
              counter++;
              return (
                <IonRow
                  className="mpp_custom_grid_row"
                  key={element}
                  placeholder={undefined}
                >
                  <IonCol placeholder={undefined}>
                    <IonLabel
                      className="mpp_custom_grid_lebel"
                      onClick={() => {
                        props.onclickFn(element);
                        setLookup(element);
                        setInitValue(element);
                        setHidden(true);
                      }}
                      placeholder={undefined}
                    >
                      {element}
                    </IonLabel>
                  </IonCol>
                </IonRow>
              );
            }
            return null;
          }
        })}
      </IonGrid>
    </>
  );
};

export default CustomSearchAndSelect;
