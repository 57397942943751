import {
  IonBreadcrumb,
  IonBreadcrumbs,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonRow,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import * as Icons from "ionicons/icons";
import * as Util from "../../components/Util";
import PageTemplate from "../PageTemplate";
import { useHistory } from "react-router-dom";
import { useState } from "react";

const Favourites = () => {
  const [favourites] = useState(Util.getFavourites());
  const history = useHistory();
  const [presentAlert] = useIonAlert();

  const setFavouriteBoxes = () => {
    if (favourites?.favourites) {
      return (
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            {favourites?.favourites.map((fav) => {
              return (
                <IonCol
                  style={{
                    border: "1px solid #004330",
                    borderRadius: "15px",
                    maxWidth: "200px",
                    cursor: "pointer",
                    margin: "1%",
                  }}
                  onClick={() => {
                    Util.setProductJson(undefined);
                    Util.setProduct(fav.min ? fav.min : "");
                    history.push({
                      pathname: "/productdetails",
                      state: { caller: "Favourites", backlink: "/favourites" },
                    });
                  }}
                  placeholder={undefined}
                >
                  <IonItem
                    class="ion-text-center"
                    lines="none"
                    placeholder={undefined}
                  >
                    <IonImg
                      src={
                        fav.img
                          ? fav.img
                          : require("../../assets/images/nophoto.jpg")
                      }
                      className="mpp-ion-grid-infobox-image"
                      placeholder={undefined}
                    />
                  </IonItem>
                  <IonItem
                    class="ion-text-center"
                    lines="none"
                    placeholder={undefined}
                  >
                    <IonLabel
                      className="mpp-breadcrumb"
                      style={{ fontSize: "12px" }}
                      placeholder={undefined}
                    >
                      {fav.name}
                    </IonLabel>
                  </IonItem>
                </IonCol>
              );
            })}
          </IonRow>
        </IonGrid>
      );
    } else {
      return <></>;
    }
  };
  return (
    <PageTemplate id="favouritesPage">
      <IonToolbar placeholder={undefined}>
        <IonBreadcrumbs
          maxItems={4}
          itemsAfterCollapse={3}
          placeholder={undefined}
        >
          <IonBreadcrumb
            className="mpp-breadcrumb"
            href="/home"
            placeholder={undefined}
          >
            Home
          </IonBreadcrumb>
          <IonBreadcrumb className="mpp-breadcrumb" placeholder={undefined}>
            Favourites
            <IonIcon
              icon={Icons.storefront}
              className="mpp-favourite-icon"
              onClick={() =>
                presentAlert({
                  header: "Current Store",
                  message: Util.getStore(),
                  buttons: [
                    {
                      text: "Cancel",
                      role: "cancel",
                      cssClass: "mpp-favourite-icon",
                      handler: () => {},
                    },
                    {
                      text: "Settings",
                      role: "confirm",
                      cssClass: "mpp-favourite-icon",
                      handler: () => {
                        history.push({
                          pathname: "/settings",
                          state: {
                            caller: "Favourites",
                            backlink: "/favourites",
                          },
                        });
                      },
                    },
                  ],
                  cssClass: "mpp-favourite-icon",
                })
              }
              placeholder={undefined}
            />
          </IonBreadcrumb>
        </IonBreadcrumbs>
      </IonToolbar>
      <IonContent placeholder={undefined}>{setFavouriteBoxes()}</IonContent>
    </PageTemplate>
  );
};

export { Favourites };
