import { IonAccordion, IonItem, IonLabel, IonList } from "@ionic/react";
import { useEffect, useState } from "react";
import { Product } from "../../../models/Product";
import { getCorporateStoreStock } from "../../../services/LookerService";
import * as Util from "../../../components/Util";
import { CustomTableWithTotalBox } from "../../../components/CustomTableWithTotalBox";

const StockAvailabilitySection = (props: { product: Product | undefined }) => {
  const [productMin] = useState<string | undefined>(props.product?.itemNumber);
  const [stockData, setStockData] = useState<{
    header: string;
    rows: { key: string; value: any; isTotal: boolean }[];
  }>();
  const [isLoading, setLoading] = useState<boolean>();

  const handleServiceResponse = (response: {
    ok: boolean;
    value: any[];
    error: any;
  }) => {
    if (response.ok) {
      const element = response.value[0];
      const data = {
        header: "Store Stock Information",
        rows: [
          {
            key: "Units In Store (End of Day)",
            isTotal: false,
            value: element["store_depot_stock.total_store_stock_volume"]
              ? element["store_depot_stock.total_store_stock_volume"]
              : element["store_depot_stock.total_store_stock_volume"] === 0
              ? element["store_depot_stock.total_store_stock_volume"]
              : Util.NOT_AVAILABLE,
          },
          {
            key: "Units In Transit",
            isTotal: false,
            value: element["store_depot_stock.total_transit_stock_volume"]
              ? element["store_depot_stock.total_transit_stock_volume"]
              : element["store_depot_stock.total_transit_stock_volume"] === 0
              ? element["store_depot_stock.total_transit_stock_volume"]
              : Util.NOT_AVAILABLE,
          },
          {
            key: "Units In Depot",
            isTotal: false,
            value: element["store_depot_stock.total_depotstockvolume"]
              ? element["store_depot_stock.total_depotstockvolume"]
              : element["store_depot_stock.total_depotstockvolume"] === 0
              ? element["store_depot_stock.total_depotstockvolume"]
              : Util.NOT_AVAILABLE,
          },
        ],
      };
      setStockData(data);
    }
  };

  useEffect(() => {
    if (productMin) {
      setLoading(true);
      getCorporateStoreStock(
        productMin,
        Util.getCountry() === Util.ALL_VALUES ? undefined : Util.getCountry(),
        Util.getRegion() === Util.ALL_VALUES ? undefined : Util.getRegion()
      ).then((response) => {
        handleServiceResponse(response);
        setLoading(false);
      });
    }
  }, [productMin]);

  const headerName = "Stock & Availability";

  return (
    <IonAccordion aria-expanded placeholder={undefined}>
      <IonItem
        slot="header"
        className="mpp-accordian-header-item"
        placeholder={undefined}
      >
        <IonLabel
          className="mpp-accordian-header-label"
          placeholder={undefined}
        >
          {headerName}
          {isLoading ? " (Loading...)" : ""}
        </IonLabel>
      </IonItem>
      <IonList slot="content" placeholder={undefined}>
        <CustomTableWithTotalBox {...stockData} />
      </IonList>
    </IonAccordion>
  );
};

export default StockAvailabilitySection;
