import * as Util from "../components/Util";

const getAllLocations = (): string[] => {
  const locations = Util.getLocations();
  return locations;
};

const distinct = (
  value: string | number,
  index: number,
  self: (string | number)[]
): boolean => {
  return self.indexOf(value) === index;
};

const getAllRegions = (country: string | undefined): string[] => {
  const countryRegionMap = Util.getCountryRegionMap();
  if (country) {
    const regionArray = countryRegionMap?.get(country);
    if (regionArray) {
      return regionArray.filter(distinct).sort();
    } else {
      return [];
    }
  } else {
    const regionArray = countryRegionMap?.get(Util.ALL_VALUES);
    if (regionArray) {
      return regionArray.filter(distinct).sort();
    } else {
      return [];
    }
  }
};

const getAllCountries = (): string[] => {
  const countries: string[] = [Util.ALL_VALUES];
  const countryRegionMap = Util.getCountryRegionMap();
  if (countryRegionMap) {
    countryRegionMap.forEach((value, key, map) => {
      countries.push(key);
    });
  }

  return countries.filter(distinct).sort();
};

const getLocationById = (locationId: string): string => {
  const locations = Util.getLocations();

  let final_location = "";
  locations.forEach((location) => {
    if (location.endsWith("(" + locationId + ")")) {
      final_location = location;
    }
  });
  return final_location;
};

const getStoreName = (storeName: string): string => {
  const locations = Util.getLocations();

  let finalLocation = Util.ALL_VALUES;
  locations.forEach((element) => {
    if (element && element.includes(storeName)) {
      finalLocation = element;
    }
  });

  return finalLocation;
};

export {
  getLocationById,
  getAllLocations,
  getStoreName,
  getAllCountries,
  getAllRegions,
};
