export const DEBUG_MODE = false;
export const SUPPORTED_BARCODE_TYPE = [
  "ean13",
  "ean8",
  "upc_a",
  "upc_e",
  "upc_ean",
];
export const LOOKER_DASHBOARD_SALES =
  "my_product_profile::product_sales?MIN=&Store+ID=&Store=&Region+Name=&Channel=&theme=MorrisonsApp";
export const LOOKER_DASHBOARD_ADVANCE_PRODUCT_SEARCH =
  "my_product_profile::product_search?theme=MorrisonsApp&Category=&Class=&Subclass=&Brand=&Item%20Name=&Item%20Description=&MIN=&Store=&Primary%20Supplier=";
export const LOOKER_DASHBOARD_PRODUCT_SEARCH =
  "15?Category=&Class=&Subclass=&Brand=&Item+Name=&Item+Barcode=";
export const LOOKER_DASHBOARD_PRODUCT_DETAILS =
  "my_product_profile::product_details?theme=MorrisonsApp&MIN=&Store=";
export const LOOKER_USER_DOMAIN = "morrisonsplc.co.uk";
export const APPLICATION_GOOGLE_ANDROID_CLIENT_ID =
  "456242796688-ftos6hbmp50g80b9tjk5en0jmpjs4qlu.apps.googleusercontent.com";

export const LOOKER_APP_URL = "https://morrisonsprd.cloud.looker.com";
export const CLOUD_FUNCTION_GATEWAY_URL =
  "https://mpp-service-gateway-8ofcpwy.nw.gateway.dev";
export const APPLICATION_GOOGLE_CLIENT_ID =
  "18891234610-pl952sgto6gua1s7av1n7nftpmrsepp0.apps.googleusercontent.com";

const domain = window.location.hostname;

export const firebaseConfig = {
  apiKey: "AIzaSyAKIoNYGnEPknnso2A3y_4M9cVvhoK3sZk",
  authDomain: domain ? domain : "comm-mpp-prd.firebaseapp.com",
  projectId: "comm-mpp-prd",
  storageBucket: "comm-mpp-prd.appspot.com",
  messagingSenderId: "18891234610",
  appId: "1:18891234610:web:abf86ca067d7255f8bcb2b",
};
