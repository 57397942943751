import { CustomSearchTableBox } from "../../../../components/CustomSearchTableBox";
import NoDataFound from "../../../NoDataFound";
import { DataValue, SearchResult } from "../../../../components/DataTypes";

const ProductSearchResultBox = (props: {
  indata: SearchResult[];
  caller: { pathname: string; callerLabel: string; backlink: string };
}) => {
  const input = {
    title: "Product Search Result",
    header: ["MIN", "Item Description", "Supplier", "Stores Ranged"],
    fixedColumns: ["MIN"],
    data: props.indata,
    titleNeeded: false,
    csvFileName: "Search_Result",
    caller: {
      pathname: props.caller?.pathname,
      callerLabel: props.caller?.callerLabel,
      backlink: props.caller?.backlink,
    },
  };

  if (props.indata.length === undefined) {
    return <></>;
  } else {
    return <CustomSearchTableBox {...input} />;
  }
};

const ProductSearchResult = (props: {
  data: any[] | undefined;
  caller: { pathname: string; callerLabel: string; backlink: string };
}) => {
  const finalData: any[] = [];

  if (props.data && props.data.length > 0) {
    props.data.forEach((element) => {
      const dataRows: DataValue[] = [];

      const min: DataValue = {
        key: "min",
        value: element["product_search.min"],
        type: "string",
      };

      const item_name: DataValue = {
        key: "item_name",
        value: element["product_search.item_name"],
        type: "string",
        linkWithDetails: true,
        linkedProductMin: element["product_search.min"],
      };

      const supplier_name: DataValue = {
        key: "supplier_name",
        value: element["product_search.supplier_name"],
        type: "string",
      };

      const stores_ranged: DataValue = {
        key: "stores_ranged",
        value: element["product_search.stores_ranged"],
        type: "number",
      };

      dataRows.push(min, item_name, supplier_name, stores_ranged);
      finalData.push(dataRows);
    });
  } else if (props.data) {
    return <NoDataFound />;
  }

  return <ProductSearchResultBox indata={finalData} caller={props.caller} />;
};

export { ProductSearchResult, ProductSearchResultBox };
