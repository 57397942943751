import {
  IonAccordion,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonToggle,
} from "@ionic/react";
import React from "react";
import { CustomRowTableBox } from "../../../components/CustomRowTableBox";
import { Product } from "../../../models/Product";
import {
  getRetroDeals,
  getRetroDealsHistory,
} from "../../../services/LookerService";
import * as Util from "../../../components/Util";
import { DataValue } from "../../../components/DataTypes";
import "../styles.css";

const RetroDealSection = (props: { product: Product | undefined }) => {
  interface RetroDealData {
    business_week_start_date?: DataValue;
    deal_description?: DataValue;
    collect_start_date?: DataValue;
    collect_end_date?: DataValue;
    weekly_deal_income_amt?: DataValue;
    per_unit_income?: DataValue;
  }

  const [retroDealByWeekAndIncome, setRetroDealByWeekAndIncome] =
    React.useState<RetroDealData[]>();

  const [retroDealHistoryByWeekAndIncome, setRetroDealHistoryByWeekAndIncome] =
    React.useState<RetroDealData[]>();

  const [productMin] = React.useState<string | undefined>(
    props.product?.itemNumber
  );

  const [isLoading, setLoading] = React.useState<boolean>();

  const [currentOrHistory, setCurrentOrHistory] =
    React.useState<boolean>(false);

  const handleServiceResponse = (
    response: {
      ok: boolean;
      value: any[];
      error: any;
    },
    table: string,
    setData: any
  ) => {
    if (Util.getDebugMode()) console.log(JSON.stringify(response));
    if (response.ok) {
      const data_by_income: any[] = [];
      const rows = response.value;
      rows.forEach((row) => {
        const dataRows_by_income: DataValue[] = [];

        const business_week_start_date: DataValue = {
          key: "business_week_start_date",
          value:
            row[table + ".bsns_wk_strt_dt"] &&
            row[table + ".bsns_wk_strt_dt"] !== ""
              ? row[table + ".bsns_wk_strt_dt"]
              : undefined,
          type: "week",
        };

        const deal_description: DataValue = {
          key: "deal_description",
          value:
            row[table + ".deal_description"] &&
            row[table + ".deal_description"] !== ""
              ? row[table + ".deal_description"]
              : undefined,
          type: "string",
        };

        const collect_start_date: DataValue = {
          key: "collect_start_date",
          value:
            row[table + ".collect_start_date"] &&
            row[table + ".collect_start_date"] !== ""
              ? row[table + ".collect_start_date"]
              : undefined,
          type: "longdate",
        };

        const collect_end_date: DataValue = {
          key: "collect_end_date",
          value:
            row[table + ".collect_end_date"] &&
            row[table + ".collect_end_date"] !== ""
              ? row[table + ".collect_end_date"]
              : undefined,
          type: "longdate",
        };

        const weekly_deal_income_amt: DataValue = {
          key: "weekly_deal_income_amt",
          value:
            row[table + ".weekly_deal_income_amt"] &&
            row[table + ".weekly_deal_income_amt"] !== ""
              ? row[table + ".weekly_deal_income_amt"]
              : undefined,
          type: "currency",
        };

        const per_unit_income: DataValue = {
          key: "per_unit_income",
          value:
            row[table + ".per_unit_income"] &&
            row[table + ".per_unit_income"] !== ""
              ? row[table + ".per_unit_income"]
              : undefined,
          type: "currency",
        };

        // Week and Taking
        if (business_week_start_date.value) {
          dataRows_by_income.push(
            business_week_start_date,
            deal_description,
            collect_start_date,
            collect_end_date,
            weekly_deal_income_amt,
            per_unit_income
          );

          data_by_income.push(dataRows_by_income);
        }
      });
      setData(data_by_income);
    } else {
      console.error(
        "No sales information found for " +
          productMin +
          " for store " +
          Util.getStore()
      );
    }
  };

  React.useEffect(() => {
    if (productMin) {
      setLoading(true);
      getRetroDeals(productMin).then((response) => {
        handleServiceResponse(
          response,
          "retro_deal",
          setRetroDealByWeekAndIncome
        );
        setLoading(false);
      });
    }
  }, [productMin]);

  React.useEffect(() => {
    if (
      productMin &&
      currentOrHistory &&
      retroDealHistoryByWeekAndIncome === undefined
    ) {
      setLoading(true);
      getRetroDealsHistory(productMin).then((response) => {
        handleServiceResponse(
          response,
          "retro_deal_history",
          setRetroDealHistoryByWeekAndIncome
        );
        setLoading(false);
      });
    }
  }, [productMin, currentOrHistory]);

  const HistoryRetroDealContent = () => {
    return retroDealContent(retroDealHistoryByWeekAndIncome);
  };

  const WeeklyRetroDealContent = () => {
    return retroDealContent(retroDealByWeekAndIncome);
  };

  const retroDealContent = (retroDealData: RetroDealData[] | undefined) => {
    const header = [
      "Week",
      "Description",
      "Start Date",
      "End Date",
      "Weekly Income (£)",
      "Income/Unit (£)",
    ];
    const fixedColumns = ["Week"];
    const data = retroDealData;
    const title = "Retro Deal Information";
    return (
      <CustomRowTableBox
        title={title}
        header={header}
        data={data}
        fixedColumns={fixedColumns}
        csvFileName={
          "WeeklyRetroDealReport" +
          "_MIN-" +
          productMin
            ?.replaceAll(" ", "-")
            .replaceAll("(", "")
            .replaceAll(")", "") +
          ".csv"
        }
      />
    );
  };

  const getVisibleComponent = () => {
    if (currentOrHistory) {
      return <HistoryRetroDealContent />;
    } else {
      return <WeeklyRetroDealContent />;
    }
  };

  const headerName = "Corporate Retro Deals";
  return (
    <IonAccordion aria-expanded placeholder={undefined}>
      <IonItem
        slot="header"
        className="mpp-accordian-header-item"
        placeholder={undefined}
      >
        <IonLabel
          className="mpp-accordian-header-label"
          placeholder={undefined}
        >
          {headerName}
          {isLoading ? " (Loading...)" : ""}
        </IonLabel>
      </IonItem>
      <IonList slot="content" placeholder={undefined}>
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <IonLabel className="fixedLabelWeek" placeholder={undefined}>
                5 Weeks
              </IonLabel>
              <IonToggle
                class="mppToggle"
                checked={currentOrHistory}
                onIonChange={(e) => setCurrentOrHistory(e.detail.checked)}
                placeholder={undefined}
              />
              <IonLabel className="fixedLabelDay" placeholder={undefined}>
                18 Months
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
        {getVisibleComponent()}
      </IonList>
    </IonAccordion>
  );
};

export default RetroDealSection;
