import { IonCol, IonGrid, IonIcon, IonNavLink, IonRow } from "@ionic/react";
import { getPlatforms } from "@ionic/react";
import * as Icons from "ionicons/icons";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import * as Util from "../../components/Util";
import { lables } from "../../components/Labels";
import "./styles.css";
import { PageModalData } from "../../pages/CorporateProductDetails/PageModalData";

const formatValue = (row: any): string => {
  let formattedValue = "";

  if (row.value == null || row.value === undefined) {
    formattedValue = row.alternalte ? row.alternalte : "N/A";
    return formattedValue;
  }

  if (row.type === "currency") {
    formattedValue = Util.getUKPriceFormat(row.value);
  } else if (row.type === "percent") {
    formattedValue = row.value.toLocaleString(Util.LOCAL, {
      style: row.type,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  } else if (row.type === "number" && row.value.toString().includes(".")) {
    formattedValue = "" + Math.round(row.value * 100) / 100;
  } else if (row.type === "date") {
    formattedValue = Util.getDateFormat(row.value);
  } else if (row.type === "week") {
    formattedValue = Util.getWeekFormat(row.value);
  } else if (row.type === "longdate") {
    formattedValue = Util.yyyymmddToDdmmyyyy(row.value);
  } else {
    formattedValue = row.value;
  }

  return formattedValue;
};

const CustomRowTableBox = (props: {
  title: string;
  header: string[];
  fixedColumns: string[];
  data: any[] | undefined;
  titleNeeded?: boolean;
  csvFileName?: string;
  allAlignCenter?: boolean;
  allCapital?: boolean;
  modalKey?: string;
  modalMIN?: string;
  modalAdditionalValues?: { planogramClass?: string | number };
}) => {
  let rowstyle: any;
  let gridstyle: any;
  const history = useHistory();

  if (getPlatforms().includes("desktop") || getPlatforms().includes("tablet")) {
    let multiplyFactor = 1;
    if (props.header && props.header.length > 0) {
      if (props.header.length > 1) {
        multiplyFactor = 100 * props.header.length;
      } else {
        multiplyFactor = 100 * 4;
      }
    }
    rowstyle = { width: multiplyFactor + "px" };
    gridstyle = {
      flexWrap: "nowrap",
      overflowX: "scroll !important",
      overflowY: "hidden",
    };
  } else {
    // Add scroll on the mobile...
    let multiplyFactor = 1;
    if (props.header && props.header.length > 0) {
      if (props.header.length > 1) {
        multiplyFactor = 100 * props.header.length;
      } else {
        multiplyFactor = 100 * 4;
      }
    }
    rowstyle = { width: multiplyFactor + "px" };
    gridstyle = {
      flexWrap: "nowrap",
      overflowX: "scroll !important",
      overflowY: "hidden",
    };
  }

  const addTitle = () => {
    return props.titleNeeded ? (
      <IonRow
        className="mpp-grid-title-row"
        style={rowstyle}
        placeholder={undefined}
      >
        <IonCol placeholder={undefined}>{props.title}</IonCol>
      </IonRow>
    ) : (
      <></>
    );
  };

  const downloadCSVData = (): any[] => {
    const csvData: any[] = [];

    csvData.push(props.header);
    props.data?.forEach((row: any[]) => {
      const newrow: any[] = [];
      row.forEach((val: any) => {
        if (row.indexOf(val) >= 0 && row.indexOf(val) < props.header.length) {
          newrow.push(formatValue(val));
        }
      });
      csvData.push(newrow);
    });

    return csvData;
  };

  const getValueHTML = (row: {
    key: string | undefined;
    value?: string | number | undefined;
    linkWithDetails?: boolean;
    linkedProductMin?: string;
    modalKey?: string;
    modalMIN?: string;
    modalAdditionalValues?: { planogramClass?: string | number };
  }) => {
    if (row.linkWithDetails) {
      return (
        <>
          <IonNavLink
            className="mpp-min-link"
            //expand="block"
            //fill="default"
            onClick={() => {
              Util.setProductJson(undefined);
              Util.setProduct(row.linkedProductMin ? row.linkedProductMin : "");
              history.push({
                pathname: "/productdetails",
                state: {
                  caller: lables.LBL_STORE_PRODUCT_SEARCH,
                  backlink: "/advancesearch",
                },
              });
            }}
            placeholder={undefined}
          >
            {row.value
              ? props.allCapital
                ? formatValue(row).toString()
                : Util.initCap(formatValue(row).toString())
              : formatValue(row)}
          </IonNavLink>
        </>
      );
    } else if (row.modalKey && row.modalMIN) {
      return (
        <PageModalData
          componentName={row.modalKey}
          productMin={row.modalMIN}
          value={row.value}
          modalAdditionalValues={row.modalAdditionalValues}
        />
      );
    } else {
      return (
        <>
          {row.value
            ? props.allCapital
              ? formatValue(row).toString()
              : Util.initCap(formatValue(row).toString())
            : formatValue(row)}
        </>
      );
    }
  };

  return (
    <IonGrid
      className="custom-row-table-box-grid"
      style={gridstyle}
      placeholder={undefined}
    >
      {addTitle()}
      <IonRow
        className="mpp-grid-header-row"
        style={rowstyle}
        placeholder={undefined}
      >
        <IonCol
          style={{ width: "100px", textAlign: "left" }}
          placeholder={undefined}
        >
          {props.header[0]}
          <CSVLink
            data={downloadCSVData()}
            filename={
              props.csvFileName ? props.csvFileName : "mpp-report-data.csv"
            }
          >
            <IonIcon
              icon={Icons.download}
              className="mpp-download-icon"
              placeholder={undefined}
            />
          </CSVLink>
        </IonCol>
        {props.header.map((head) => {
          if (props.header.indexOf(head) > 0)
            return <IonCol placeholder={undefined}>{head}</IonCol>;
          return null;
        })}
      </IonRow>
      {props.data?.map((row: any[]) => {
        return (
          <IonRow
            className="mpp-grid-value-row"
            style={rowstyle}
            placeholder={undefined}
          >
            <IonCol
              style={{
                width: "100px",
                fontWeight: row[0].fontWeight,
              }}
              class={row[0].styleClass}
              placeholder={undefined}
            >
              {formatValue(row[0])}
            </IonCol>
            {row.map((data) => {
              if (
                row.indexOf(data) > 0 &&
                row.indexOf(data) < props.header.length
              )
                return (
                  <IonCol
                    style={{
                      width: "100px",
                      fontWeight: data.fontWeight,
                    }}
                    class={row[0].styleClass}
                    className={
                      data.type === "currency" ||
                      data.type === "percent" ||
                      data.type === "number"
                        ? "mpp-grid-value-col-num"
                        : props.allAlignCenter
                        ? "mpp-grid-value-col-center"
                        : "mpp-grid-value-col"
                    }
                    placeholder={undefined}
                  >
                    {getValueHTML(data)}
                  </IonCol>
                );
              return null;
            })}
          </IonRow>
        );
      })}
    </IonGrid>
  );
};

export { CustomRowTableBox };
