import {
  getPlatforms,
  IonBreadcrumb,
  IonBreadcrumbs,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import "./styles.css";
import { lables } from "../../components/Labels";
import { useEffect, useState } from "react";
import PageTemplate from "../PageTemplate";
import * as Util from "../../components/Util";
import {
  getCommercialHierarchy,
  getCountriesAndRegions,
  getStoreDescriptions,
  getWeekNumbers,
} from "../../services/LookerService";
import {
  getPreference,
  getFavourites,
  getStores,
} from "../../services/FirebaseService";
import { PersistentPreference } from "../../models/MPPUserPreference";
import { Favourites, Stores } from "../../models/Favourites";
import {
  getCountryRegionMap,
  setCountryRegionMap,
} from "../../components/Util";

const AuthenticatedLanding = () => {
  const [isActive, setActive] = useState(false);
  const [store] = useState(Util.getStore());
  const [isLoading, setLoading] = useState(true);
  const [present, dismiss] = useIonLoading();

  useEffect(() => {
    const loadLatLam = async () => {
      getStores().then((response) => {
        const data = response.data();
        if (data) {
          const stores: Stores = JSON.parse(JSON.stringify(data));
          Util.setStoreGeo(stores);
          if (Util.getDebugMode())
            console.log("Number of stores are " + stores.stores.length);
        }
      });
    };

    const json = sessionStorage.getItem("storeGeo");
    if (json) {
      // Do nothing. Data is already loaded...
    } else {
      loadLatLam();
    }
  }, []);

  useEffect(() => {
    const callGetCountriesAndRegions = async () => {
      const getCountriesAndRegionsRes = getCountriesAndRegions();
      getCountriesAndRegionsRes.then((response) => {
        if (response.ok) {
          const rows = response.value;
          const countriesAndRegions = new Map();
          if (rows && rows.length > 0) {
            const allCountryRegionArray: string[] = [Util.ALL_VALUES];
            rows.forEach((row) => {
              let tempArray: string[] = countriesAndRegions.get(
                row["location.Country"]
              );

              if (tempArray) {
                tempArray.push(row["location.Region"]);
                allCountryRegionArray.push(row["location.Region"]);
              } else {
                tempArray = [Util.ALL_VALUES];
                tempArray.push(row["location.Region"]);
                allCountryRegionArray.push(row["location.Region"]);
              }
              countriesAndRegions.set(row["location.Country"], tempArray);
            });
            // Add the entry for the all country...
            countriesAndRegions.set(Util.ALL_VALUES, allCountryRegionArray);
          } else {
            console.error(
              "Application Error: No country and region data found"
            );
            alert("No country and region data found");
          }
          setCountryRegionMap(countriesAndRegions);
        }
      });
    };
    if (getCountryRegionMap() === undefined) {
      callGetCountriesAndRegions();
    }
  }, []);

  useEffect(() => {
    if (store === null || store === undefined) {
      const locations: string[] = [];
      getStoreDescriptions()
        .then((response) => {
          if (response.ok) {
            const rows = response.value;
            if (rows && rows.length > 0) {
              rows.forEach((row) => {
                locations.push(row["location.store_description"]);
              });
            } else {
              console.error("Application Error: No location data found");
              alert("No location data found");
            }
            if (locations && locations.length > 0) {
              Util.setLocations(locations);
              Util.setUpLocation();
            }
          } else {
            console.error(
              "Application Error: Unable to fetch location descriptions"
            );
            alert("Unable to fetch location descriptions");
          }
        })
        .catch((error) => {
          console.error(error);
          alert(error);
        });
    }

    if (Util.getProduct() !== null && Util.getProduct().length > 0) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [isActive]);

  useEffect(() => {
    getFavourites().then((response) => {
      const data = response.data();
      if (data) {
        const favourites: Favourites = JSON.parse(JSON.stringify(data));
        Util.setFavourites(favourites);
      }
    });
  }, []);

  useEffect(() => {
    getPreference().then((response) => {
      if (response) {
        const data = response.data();
        if (data) {
          const persistentPreference: PersistentPreference = JSON.parse(
            JSON.stringify(data)
          );
          const mppUserPreference = Util.getUserPreference();
          mppUserPreference.persistentPreference = persistentPreference;
          Util.setUserPreference(mppUserPreference);
        } else {
          // Data is null or undefined...
          const mppUserPreference = Util.getUserPreference();
          mppUserPreference.persistentPreference = {
            enableCorporateRetroDeals: true,
            enableDepotOutboundAndSupplierInbound: true,
            enableLocationAndSpace: true,
            enablePriceAndPromotion: true,
            enableRetailPriceHistory: true,
            enableSalesAndFutureForecast: true,
            enableStockAvailability: true,
            enableStockHistory: true,
            enableSupplierInformation: true,
            enableWasteAndMarkdown: true,
          };
          Util.setUserPreference(mppUserPreference);
        }
      } else {
        // Response is null or undefined...
        const mppUserPreference = Util.getUserPreference();
        mppUserPreference.persistentPreference = {
          enableCorporateRetroDeals: true,
          enableDepotOutboundAndSupplierInbound: true,
          enableLocationAndSpace: true,
          enablePriceAndPromotion: true,
          enableRetailPriceHistory: true,
          enableSalesAndFutureForecast: true,
          enableStockAvailability: true,
          enableStockHistory: true,
          enableSupplierInformation: true,
          enableWasteAndMarkdown: true,
        };
        Util.setUserPreference(mppUserPreference);
      }
    });
  }, []);

  useEffect(() => {
    //Load application cache...
    const loadCache = async () => {
      getWeekNumbers()
        .then((response) => {
          if (response.ok) {
            const rows = response.value;
            const weekNumberMap = new Map<string, string>();
            rows.forEach((row) => {
              let weekNumber: string = row["calendarisland.RPRTNG_CMPNY_WK"];
              if (weekNumber) {
                weekNumber = weekNumber.substring(2);
              }

              weekNumberMap.set(
                row["calendarisland.business_week_start_date"],
                weekNumber
              );
              Util.setWeekNumbers(weekNumberMap);
            });
          } else {
            alert(
              "Application Error: Unable to setup application environment."
            );
            alert(JSON.stringify(response.error));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const weekNumberMap = sessionStorage.getItem("weekNumberMap");
    if (weekNumberMap) {
      // Cache already created...
      setLoading(false);
    } else {
      loadCache();
    }
  }, []);

  useEffect(() => {
    //Load application cache...
    const loadCache = async () => {
      present({
        message: "Initializing",
        cssClass: "mpp-custom-loading-spinner",
        spinner: "bubbles",
      });
      getCommercialHierarchy(
        Util.ALL_VALUES,
        Util.ALL_VALUES,
        Util.ALL_VALUES,
        Util.ALL_VALUES,
        Util.ALL_VALUES
      )
        .then((response) => {
          if (response.ok) {
            const json = response.value;
            Util.setCommercialHierarchy(json);
            //sessionStorage.setItem("commercialHierarchy", JSON.stringify(json));
            //console.log(
            //  "Commercial Hierarchy loaded with " + json.length + " values"
            //);
          } else {
            alert("Unable to setup application environment.");
            alert(JSON.stringify(response.error));
          }
        })
        .finally(() => {
          setLoading(false);
          dismiss();
        });
    };

    const jsonValues = sessionStorage.getItem("commercialHierarchy");
    if (jsonValues) {
      // Cache already created...
      setLoading(false);
    } else {
      loadCache();
    }
  }, []);

  if (isLoading) {
    return (
      <PageTemplate id="productDetailsTypePage">
        <></>
      </PageTemplate>
    );
  }

  const getGridClass = () => {
    if (
      (getPlatforms().includes("iphone") &&
        getPlatforms().includes("ios") &&
        getPlatforms().includes("mobile") &&
        getPlatforms().includes("mobileweb")) ||
      (getPlatforms().includes("android") &&
        getPlatforms().includes("mobile") &&
        getPlatforms().includes("mobileweb"))
    ) {
      return "morrisons-login-grid-mobile";
    } else {
      return "morrisons-login-grid";
    }
  };

  const options = [
    {
      className: "mpp-button",
      routerLink: "/corporateadvancesearch",
      routerDirection: "none",
      value: lables.LBL_CORPORATE_PRODUCT_SEARCH,
    },
    {
      className: "mpp-button",
      routerLink: "/advancesearch",
      routerDirection: "none",
      value: lables.LBL_STORE_PRODUCT_SEARCH,
    },
    {
      className: "mpp-button",
      routerLink: "/scan",
      routerDirection: "none",
      value: lables.LBL_PRODUCT_SCAN,
    },
    {
      className: "mpp-button",
      routerLink: "/favourites",
      routerDirection: "none",
      value: lables.LBL_FAVOURITES,
    },
    {
      className: "mpp-button",
      routerLink: "/settings",
      routerDirection: "none",
      value: lables.LBL_SETTINGS,
    },
  ];

  const getOptionButtons = () => {
    return options.map((option) => {
      return (
        <IonCol placeholder={undefined}>
          <IonButton
            className={option.className}
            routerLink={option.routerLink}
            routerDirection="none"
            placeholder={undefined}
          >
            {option.value}
          </IonButton>
        </IonCol>
      );
    });
  };

  return (
    <PageTemplate id="homePage">
      <IonToolbar placeholder={undefined}>
        <IonBreadcrumbs
          maxItems={4}
          itemsAfterCollapse={3}
          placeholder={undefined}
        >
          <IonBreadcrumb className="mpp-breadcrumb" placeholder={undefined}>
            {lables.LBL_HOME}
          </IonBreadcrumb>
        </IonBreadcrumbs>
      </IonToolbar>
      <IonContent className="main-container" placeholder={undefined}>
        <IonGrid className={getGridClass()} placeholder={undefined}>
          {getOptionButtons()}
        </IonGrid>
      </IonContent>
    </PageTemplate>
  );
};

export default AuthenticatedLanding;
