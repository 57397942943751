import {
  IonBreadcrumb,
  IonBreadcrumbs,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { auth, signOut } from "../../services/FirebaseService";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import * as Util from "../Util";
import PageTemplate from "../../pages/PageTemplate";
import * as LookerService from "../../services/LookerService";

const Logout = () => {
  const [isSignedIn, setSignedIn] = useState(Util.getUser().isLoggedIn);

  useEffect(() => {
    // Remove this from here if you want to have a second
    // logout confirmation before signing out
    logOut();
  }, [isSignedIn]);

  const logOut = () => {
    LookerService.logout(); // Logout from Looker session...
    signOut(auth); // Logout from Firebase session...
    setSignedIn(false);
    Util.deleteItemFromStorage("mppUser"); // Set mpp seesion user to initial value...
    sessionStorage.clear(); // Clear all session cache...
    localStorage.clear(); //Clear all local cache...
  };

  return isSignedIn ? (
    <PageTemplate id="logoutPage">
      <IonToolbar placeholder={undefined}>
        <IonBreadcrumbs
          maxItems={4}
          itemsAfterCollapse={3}
          placeholder={undefined}
        >
          <IonBreadcrumb href="/home" placeholder={undefined}>
            Home
          </IonBreadcrumb>
        </IonBreadcrumbs>
      </IonToolbar>
      <IonContent placeholder={undefined}>
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <IonButton
                className="menu-button"
                onClick={logOut}
                placeholder={undefined}
              >
                LogOut
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </PageTemplate>
  ) : (
    <Redirect to="/" />
  );
};

export default Logout;
