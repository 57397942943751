import {
  IonAccordion,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonToggle,
} from "@ionic/react";
import React, { useState } from "react";
import { CustomRowTableBox } from "../../../components/CustomRowTableBox";
import { Product } from "../../../models/Product";
import {
  getDepotOutbound,
  getLastDeliveryDate,
  getMinOrderQuantity,
  getSupplierInbound,
} from "../../../services/LookerService";
import * as Util from "../../../components/Util";
import { DataValue } from "../../../components/DataTypes";
import { CustomTableBox } from "../../../components/CustomTableBox";

const InboundOutboundSection = (props: { product: Product | undefined }) => {
  interface DepotOutbound {
    units_demanded?: DataValue;
    units_fulfilled?: DataValue;
    units_short?: DataValue;
    percentage_achieved?: DataValue;
    business_week_start_date?: DataValue;
  }

  interface SupplierInbound {
    supplier_purchases_units_ordered?: DataValue;
    supplier_purchases_value_ordered?: DataValue;
    supplier_purchases_units_delivered?: DataValue;
    supplier_purchases_value_delivered?: DataValue;
    units_short?: DataValue;
    percentage_achieved?: DataValue;
    business_week_start_date?: DataValue;
  }

  interface SupplierFlags {
    depot_name?: DataValue;
    moq_type?: DataValue;
    pad_flag?: DataValue;
    min_order_qty_cases?: DataValue;
    do_not_order_flag?: DataValue;
    last_delivery_date?: DataValue;
  }

  // For sales forecast...
  const [depotOutboundWeekly, setDepotOutboundWeekly] =
    React.useState<DepotOutbound[]>();
  const [depotOutboundDaily, setDepotOutboundDaily] =
    React.useState<DepotOutbound[]>();

  const [supplierInboundWeekly, setSupplierInboundWeekly] =
    React.useState<SupplierInbound[]>();
  const [supplierInboundDaily, setSupplierInboundDaily] =
    React.useState<SupplierInbound[]>();

  const [productMin] = React.useState<string | undefined>(
    props.product?.itemNumber
  );
  const [weekOrDay, setWeekOrDay] = React.useState<boolean>(false);
  const [isLoading, setLoading] = React.useState<boolean>();

  const handleSalesServiceResponse = (
    response: {
      ok: boolean;
      value: any[];
      error: any;
    },
    isDay: boolean
  ) => {
    if (Util.getDebugMode()) console.log(JSON.stringify(response));
    if (response.ok) {
      const data: any[] = [];
      const rows = response.value;
      rows.forEach((row) => {
        const dataRows: DataValue[] = [];
        const unitsDemanded: DataValue = {
          key: "units_demanded",
          value: row["depot_outbound.total_demand_qty"],
          type: "number",
        };

        const unitsFulfilled: DataValue = {
          key: "units_fulfilled",
          value: row["depot_outbound.total_deamnd_fulfilled_qty"],
          type: "number",
        };

        const businessWeekStartDateDataValue: DataValue = {
          key: "business_week_start_date",
          value: isDay
            ? row["morrisons_calendar.calendar_date_date"]
            : row["morrisons_calendar.business_week_start_date"],
          type: isDay ? "date" : "week",
        };

        const unitsShort: DataValue = {
          key: "units_short",
          value: row["units_short"],
          type: "number",
        };

        const percentageAchieved: DataValue = {
          key: "percentage_achieved",
          value: row["percentage_achieved"],
          type: "percent",
        };

        dataRows.push(
          businessWeekStartDateDataValue,
          unitsDemanded,
          unitsFulfilled,
          unitsShort,
          percentageAchieved
        );

        data.push(dataRows);
      });

      if (isDay) {
        setDepotOutboundDaily(data);
      } else {
        setDepotOutboundWeekly(data);
      }
    } else {
      console.error(
        "No depoy outbound information found for " +
          productMin +
          " for store " +
          Util.getStore()
      );
    }
  };

  const handleSupplierInboundServiceResponse = (
    response: {
      ok: boolean;
      value: any[];
      error: any;
    },
    isDay: boolean
  ) => {
    if (Util.getDebugMode()) console.log(JSON.stringify(response));
    if (response.ok) {
      const data: any[] = [];
      const rows = response.value;
      rows.forEach((row) => {
        const dataRows: DataValue[] = [];

        const reportingDate: string = isDay
          ? row["morrisons_calendar.calendar_date_date"]
          : row["morrisons_calendar.business_week_start_date"];

        const reportingDateIdx = parseInt(reportingDate.replaceAll("-", ""), 10);
        const currentDate = new Date();
        const currentDateIdx = parseInt(
          currentDate.toISOString().split("T")[0].replaceAll("-", ""),
          10
        );

        const supplierPurchasesUnitsOrdered: DataValue = {
          key: "supplier_purchases_units_ordered",
          value: row["supplier_inbound.Supplier_purchases_units_ordered"],
          type: "number",
          styleClass:
            reportingDateIdx > currentDateIdx
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        const supplierPurchasesValueOrdered: DataValue = {
          key: "supplier_purchases_value_ordered",
          value: row["supplier_inbound.Supplier_purchases_value_ordered"],
          type: "currency",
          styleClass:
            reportingDateIdx > currentDateIdx
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        const supplierPurchasesUnitsDelivered: DataValue = {
          key: "supplier_purchases_units_delivered",
          value: row["supplier_inbound.Supplier_purchases_units_delivered"],
          type: "number",
          styleClass:
            reportingDateIdx > currentDateIdx
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        const supplierPurchasesValueDelivered: DataValue = {
          key: "supplier_purchases_value_delivered",
          value: row["supplier_inbound.Supplier_purchases_value_delivered"],
          type: "currency",
          styleClass:
            reportingDateIdx > currentDateIdx
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        const businessWeekStartDateDataValue: DataValue = {
          key: "business_week_start_date",
          value: isDay
            ? row["morrisons_calendar.calendar_date_date"]
            : row["morrisons_calendar.business_week_start_date"],
          type: isDay ? "date" : "week",
          styleClass:
            reportingDateIdx > currentDateIdx
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        const unitsShort: DataValue = {
          key: "units_short",
          value: row["units_short"],
          type: "number",
          styleClass:
            reportingDateIdx > currentDateIdx
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        const percentageAchieved: DataValue = {
          key: "percentage_achieved",
          value: row["percentage_achieved"],
          type: "percent",
          styleClass:
            reportingDateIdx > currentDateIdx
              ? "mpp-grid-value-row-highlighted"
              : undefined,
        };

        dataRows.push(
          businessWeekStartDateDataValue,
          supplierPurchasesUnitsOrdered,
          supplierPurchasesValueOrdered,
          supplierPurchasesUnitsDelivered,
          supplierPurchasesValueDelivered,
          unitsShort,
          percentageAchieved
        );

        data.push(dataRows);
      });

      if (isDay) {
        setSupplierInboundDaily(data);
      } else {
        setSupplierInboundWeekly(data);
      }
    } else {
      console.error(
        "No depoy outbound information found for " +
          productMin +
          " for store " +
          Util.getStore()
      );
    }
  };

  React.useEffect(() => {
    if (productMin) {
      setLoading(true);
      getDepotOutbound(productMin, false, Util.getStore()).then((response) => {
        handleSalesServiceResponse(response, false);
        setLoading(false);
      });
    }
  }, [productMin]);

  React.useEffect(() => {
    if (productMin && weekOrDay && depotOutboundDaily === undefined) {
      setLoading(true);
      getDepotOutbound(productMin, true, Util.getStore()).then((response) => {
        handleSalesServiceResponse(response, true);
        setLoading(false);
      });
    }
  }, [productMin, weekOrDay]);

  React.useEffect(() => {
    if (productMin) {
      setLoading(true);
      getSupplierInbound(productMin, false, Util.getStore()).then(
        (response) => {
          handleSupplierInboundServiceResponse(response, false);
          setLoading(false);
        }
      );
    }
  }, [productMin]);

  React.useEffect(() => {
    if (productMin && weekOrDay && supplierInboundDaily === undefined) {
      setLoading(true);
      getSupplierInbound(productMin, true, Util.getStore()).then((response) => {
        handleSupplierInboundServiceResponse(response, true);
        setLoading(false);
      });
    }
  }, [productMin, weekOrDay]);
  /////////////// New Code Start /////////////////////
  const [minOrderQuantity, setMinOrderQuantity] = useState<SupplierFlags>();
  const [lastDeliveryDate, setLastDeliveryDate] = useState<SupplierFlags>();

  const handleGetLastDeliveryDateResponse = (response: {
    ok: boolean;
    value: any[];
    error: any;
  }) => {
    if (Util.getDebugMode()) console.log(JSON.stringify(response));
    if (response.ok) {
      const rows = response.value;
      rows.forEach((row) => {
        const last_delivery_date: DataValue = {
          key: "last_delivery_date",
          value: row["supplier_inbound.Last_Delivery_date"],
          type: "date",
        };
        const flags = { last_delivery_date: last_delivery_date };
        setLastDeliveryDate(flags);
      });
    } else {
      console.error(
        "No information found for last delivery date " +
          productMin +
          " for store " +
          Util.getStore()
      );
    }
  };

  const handleGetMinOrderQuantityResponse = (response: {
    ok: boolean;
    value: any[];
    error: any;
  }) => {
    if (Util.getDebugMode()) console.log(JSON.stringify(response));
    if (response.ok) {
      const rows = response.value;
      rows.forEach((row) => {
        const moq_type: DataValue = {
          key: "moq_type",
          value: row["min_order_qty.moq_type"],
          type: "string",
        };

        const pad_flag: DataValue = {
          key: "pad_flag",
          value: row["min_order_qty.PAD_Flag"],
          type: "string",
        };

        const min_order_qty_cases: DataValue = {
          key: "min_order_qty_cases",
          value: row["min_order_qty.min_order_qty_cases"],
          type: "string",
        };

        const do_not_order_flag: DataValue = {
          key: "do_not_order_flag",
          value: row["min_order_qty.Do_not_order_flag"],
          type: "string",
        };
        const depot_name: DataValue = {
          key: "depot_name",
          value: row["min_order_qty.serving_depot"],
          type: "string",
        };

        const flags = {
          depot_name: depot_name,
          moq_type: moq_type,
          pad_flag: pad_flag,
          min_order_qty_cases: min_order_qty_cases,
          do_not_order_flag: do_not_order_flag,
        };
        setMinOrderQuantity(flags);
      });
    } else {
      console.error(
        "No information found for last delivery date " +
          productMin +
          " for store " +
          Util.getStore()
      );
    }
  };

  React.useEffect(() => {
    if (productMin) {
      getLastDeliveryDate(productMin, Util.getStore()).then((response) => {
        handleGetLastDeliveryDateResponse(response);
      });
    }
  }, [productMin]);

  React.useEffect(() => {
    if (productMin) {
      getMinOrderQuantity(productMin, Util.getStore()).then((response) => {
        handleGetMinOrderQuantityResponse(response);
      });
    }
  }, [productMin]);
  /////////////// New Code End /////////////////////

  const WeeklySalesDetailsContent = () => {
    const header = [
      "Week",
      "Store Demand From Depot (Units)",
      "Store Demand Fulfilled From Depot (Units)",
      "Units Short",
      "% Achieved",
    ];
    const fixedColumns = ["Week"];
    const data = depotOutboundWeekly;
    const title = "Depot Outbound Information";

    return (
      <CustomRowTableBox
        title={title}
        header={header}
        data={data}
        fixedColumns={fixedColumns}
        csvFileName={
          "WeeklyDepotOutboundReport" +
          "_MIN-" +
          productMin
            ?.replaceAll(" ", "-")
            .replaceAll("(", "")
            .replaceAll(")", "") +
          ".csv"
        }
      />
    );
  };

  const DailySalesDetailsContent = () => {
    const header = [
      "Day",
      "Store Demand From Depot (Units)",
      "Store Demand Fulfilled From Depot (Units)",
      "Units Short",
      "% Achieved",
    ];
    const fixedColumns = ["Day"];
    const data = depotOutboundDaily;
    const title = "Depot Outbound Service To Store";

    return (
      <CustomRowTableBox
        title={title}
        header={header}
        data={data}
        fixedColumns={fixedColumns}
        csvFileName={
          "DailyDepotOutboundReport" +
          "_MIN-" +
          productMin
            ?.replaceAll(" ", "-")
            .replaceAll("(", "")
            .replaceAll(")", "") +
          ".csv"
        }
      />
    );
  };

  const WeeklySupplierInboundContent = () => {
    const header = [
      "Week",
      "Units Ordered By Depot",
      "Value Ordered By Depot",
      "Units Delivered By Supplier",
      "Value Delivered By Supplier",
      "Units Short",
      "% Achieved",
    ];
    const fixedColumns = ["Week"];
    const data = supplierInboundWeekly;
    const title = "Supplier Inbound Service";

    return (
      <CustomRowTableBox
        title={title}
        header={header}
        data={data}
        fixedColumns={fixedColumns}
        csvFileName={
          "WeeklySupplierInboundReport" +
          "_MIN-" +
          productMin
            ?.replaceAll(" ", "-")
            .replaceAll("(", "")
            .replaceAll(")", "") +
          ".csv"
        }
      />
    );
  };

  const DailySupplierInboundContent = () => {
    const header = [
      "Day",
      "Units Ordered By Depot",
      "Value Ordered By Depot",
      "Units Delivered By Supplier",
      "Value Delivered By Supplier",
      "Units Short",
      "% Achieved",
    ];
    const fixedColumns = ["Day"];
    const data = supplierInboundDaily;
    const title = "Supplier Inbound Information";

    return (
      <CustomRowTableBox
        title={title}
        header={header}
        data={data}
        fixedColumns={fixedColumns}
        csvFileName={
          "DailySupplierInboundReport" +
          "_MIN-" +
          productMin
            ?.replaceAll(" ", "-")
            .replaceAll("(", "")
            .replaceAll(")", "") +
          ".csv"
        }
      />
    );
  };

  const getVisibleComponent = () => {
    if (!weekOrDay) {
      return (
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <WeeklySalesDetailsContent />
            </IonCol>
          </IonRow>
        </IonGrid>
      );
    } else {
      return (
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <DailySalesDetailsContent />
            </IonCol>
          </IonRow>
        </IonGrid>
      );
    }
  };

  const getVisibleComponentSupplierInbound = () => {
    if (!weekOrDay) {
      return (
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <WeeklySupplierInboundContent />
            </IonCol>
          </IonRow>
        </IonGrid>
      );
    } else {
      return (
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <DailySupplierInboundContent />
            </IonCol>
          </IonRow>
        </IonGrid>
      );
    }
  };

  const InboundOutboundFlags = () => {
    const data = {
      header: "Flags",
      rows: [
        {
          key: "Serving Depot",
          value: minOrderQuantity?.depot_name
            ? minOrderQuantity?.depot_name.value
            : Util.NOT_AVAILABLE,
          type: "",
        },
        {
          key: "Last Delivery Date",
          value: lastDeliveryDate?.last_delivery_date
            ? Util.yyyymmddToDdmmyyyy(
                "" + lastDeliveryDate?.last_delivery_date.value
              )
            : Util.NOT_AVAILABLE,
          type: "",
        },
        {
          key: "PAD Flag By Depot",
          value: minOrderQuantity?.pad_flag
            ? minOrderQuantity?.pad_flag.value
            : Util.NOT_AVAILABLE,
        },
        {
          key: "Do Not Order Flag",
          value: minOrderQuantity?.do_not_order_flag
            ? minOrderQuantity?.do_not_order_flag.value
            : Util.NOT_AVAILABLE,
        },
        {
          key: "Minimum Order Quantity - Cases/Layer/Pallet",
          value: minOrderQuantity?.moq_type
            ? minOrderQuantity?.moq_type.value
            : Util.NOT_AVAILABLE,
        },
        {
          key: "Minimum Order Quantity - Number",
          value: minOrderQuantity?.min_order_qty_cases
            ? minOrderQuantity?.min_order_qty_cases.value
            : Util.NOT_AVAILABLE,
        },
      ],
    };

    return <CustomTableBox {...data} />;
  };

  const headerName = "Depot Outbound & Supplier Inbound";

  return (
    <IonAccordion aria-expanded placeholder={undefined}>
      <IonItem
        slot="header"
        className="mpp-accordian-header-item"
        placeholder={undefined}
      >
        <IonLabel
          className="mpp-accordian-header-label"
          placeholder={undefined}
        >
          {headerName}
          {isLoading ? " (Loading...)" : ""}
        </IonLabel>
      </IonItem>
      <IonList slot="content" placeholder={undefined}>
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <IonLabel className="fixedLabelWeek" placeholder={undefined}>
                Week
              </IonLabel>
              <IonToggle
                class="mppToggle"
                checked={weekOrDay}
                onIonChange={(e) => setWeekOrDay(e.detail.checked)}
                placeholder={undefined}
              />
              <IonLabel className="fixedLabelDay" placeholder={undefined}>
                Day
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
        {getVisibleComponent()}
        {getVisibleComponentSupplierInbound()}
        <InboundOutboundFlags />
      </IonList>
    </IonAccordion>
  );
};

export default InboundOutboundSection;
