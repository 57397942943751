import {
  IonAccordion,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonToggle,
} from "@ionic/react";
import React from "react";
import { CustomRowTableBox } from "../../../components/CustomRowTableBox";
import { Product } from "../../../models/Product";

import * as Util from "../../../components/Util";
import "../styles.css";
import { getStoreStockHistory } from "../../../services/LookerService";
import { DataValue } from "../../../components/DataTypes";

const StockHistorySection = (props: { product: Product | undefined }) => {
  interface StoreStockData {
    business_week_start_date?: DataValue;
    total_store_stock_volume?: DataValue;
    total_store_stock_value?: DataValue;
    total_transit_stock_volume?: DataValue;
    total_transit_stock_value?: DataValue;
    total_depot_stock_volume?: DataValue;
    total_depot_stock_value?: DataValue;
  }

  const [storeStockByDayAndVolume, setStoreStockByDayAndVolume] =
    React.useState<StoreStockData[]>();
  const [storeStockByDayAndValue, setStoreStockByDayAndValue] =
    React.useState<StoreStockData[]>();
  const [productMin] = React.useState<string | undefined>(
    props.product?.itemNumber
  );

  const [volumeOrValue, setVolumeOrValue] = React.useState<boolean>(false);
  const [isLoading, setLoading] = React.useState<boolean>();

  const handleServiceResponse = (response: {
    ok: boolean;
    value: any[];
    error: any;
  }) => {
    if (Util.getDebugMode()) console.log(JSON.stringify(response));
    if (response.ok) {
      const data_by_volume: any[] = [];
      const data_by_value: any[] = [];
      const rows = response.value;
      rows.forEach((row) => {
        const dataRows_by_volume: DataValue[] = [];
        const dataRows_by_value: DataValue[] = [];

        const business_week_start_date: DataValue = {
          key: "business_week_start_date",
          value: row["store_depot_stock.trx_date"],
          type: "date",
        };

        const total_store_stock_value: DataValue = {
          key: "total_store_stock_value",
          value: row["store_depot_stock.total_store_stock_value"],
          type: "currency",
        };

        const total_store_stock_volume: DataValue = {
          key: "total_store_stock_volume",
          value: row["store_depot_stock.total_store_stock_volume"],
          type: "number",
        };

        const total_transit_stock_volume: DataValue = {
          key: "total_transit_stock_volume",
          value: row["store_depot_stock.total_transit_stock_volume"],
          type: "number",
        };

        const total_transit_stock_value: DataValue = {
          key: "total_transit_stock_value",
          value: row["store_depot_stock.total_transit_stock_value"],
          type: "currency",
        };

        const total_depot_stock_volume: DataValue = {
          key: "total_depot_stock_volume",
          value: row["store_depot_stock.total_depotstockvolume"],
          type: "number",
        };

        const total_depot_stock_value: DataValue = {
          key: "total_depot_stock_value",
          value: row["store_depot_stock.total_depotstockvalue"],
          type: "currency",
        };

        // By Volume
        dataRows_by_volume.push(
          business_week_start_date,
          total_store_stock_volume,
          total_transit_stock_volume,
          total_depot_stock_volume
        );

        // By Value
        dataRows_by_value.push(
          business_week_start_date,
          total_store_stock_value,
          total_transit_stock_value,
          total_depot_stock_value
        );

        data_by_volume.push(dataRows_by_volume);
        data_by_value.push(dataRows_by_value);
      });
      setStoreStockByDayAndVolume(data_by_volume);
      setStoreStockByDayAndValue(data_by_value);
    } else {
      console.error(
        "No sales information found for " +
          productMin +
          " for store " +
          Util.getStore()
      );
    }
  };

  React.useEffect(() => {
    if (productMin) {
      setLoading(true);
      getStoreStockHistory(productMin, Util.getStore())
        .then((response) => {
          handleServiceResponse(response);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [productMin]);

  const DailyStoreStockContent = () => {
    const header = ["Day", "Store Stock", "In Transit", "Servicing Depot Stock"];
    const fixedColumns = ["Day"];
    const data = volumeOrValue
      ? storeStockByDayAndValue
      : storeStockByDayAndVolume;
    const title = "Stock History Information";
    return (
      <CustomRowTableBox
        title={title}
        header={header}
        data={data}
        fixedColumns={fixedColumns}
        csvFileName={
          "DailyStoreStockReportBy" +
          (volumeOrValue ? "Volume" : "Value") +
          "_MIN-" +
          productMin
            ?.replaceAll(" ", "-")
            .replaceAll("(", "")
            .replaceAll(")", "") +
          ".csv"
        }
      />
    );
  };

  const getVisibleComponent = () => {
    return <DailyStoreStockContent />;
  };

  const headerName = "Stock History";

  return (
    <IonAccordion aria-expanded placeholder={undefined}>
      <IonItem
        slot="header"
        className="mpp-accordian-header-item"
        placeholder={undefined}
      >
        <IonLabel
          className="mpp-accordian-header-label"
          placeholder={undefined}
        >
          {headerName}
          {isLoading ? " (Loading...)" : ""}
        </IonLabel>
      </IonItem>
      <IonList slot="content" placeholder={undefined}>
        <IonGrid placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol size="auto" placeholder={undefined}>
              <IonLabel className="fixedLabelTurnover" placeholder={undefined}>
                Units
              </IonLabel>
              <IonToggle
                class="mppToggle"
                checked={volumeOrValue}
                onIonChange={(e) => setVolumeOrValue(e.detail.checked)}
                placeholder={undefined}
              />
              <IonLabel
                className="fixedLabelCostOfSales"
                placeholder={undefined}
              >
                Cost
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>

        {getVisibleComponent()}
      </IonList>
    </IonAccordion>
  );
};

export default StockHistorySection;
