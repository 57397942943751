import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  useIonLoading,
} from "@ionic/react";
import { lables } from "../../components/Labels";
import { browserSessionPersistence } from "firebase/auth";
import { useEffect, useState } from "react";
import { getPlatforms } from "@ionic/react";
import { Redirect, useHistory } from "react-router-dom";
import { MPPUser } from "../../models/MPPUser";
import {
  auth,
  provider,
  signInWithRedirect,
  getRedirectResult,
  setPersistence,
} from "../../services/FirebaseService";
import * as Util from "../Util";
import "./styles.css";
import { checkMPPAccessCall } from "../../services/CloudFunctionService";

const Login = () => {
  const [isSignedIn, setSignedIn] = useState(Util.getUser().isLoggedIn);
  const [mppUser] = useState<MPPUser>(Util.getUser());
  const [presentLogging, dismissLogging] = useIonLoading();
  const [presentAccessChecking, dismissAccessChecking] = useIonLoading();
  const history = useHistory();

  useEffect(() => {
    if (mppUser.isLoggedIn === undefined) {
      // Show the loading banner...
      presentLogging({
        message: "Logging",
        cssClass: "mpp-custom-loading-spinner",
        spinner: "bubbles",
      });
    }

    const loginHandle = async () => {
      const response = await getRedirectResult(auth);
      if (response) {
        const idToken = await auth.currentUser?.getIdToken();
        const mppUser: MPPUser = {
          email: "",
          domain: "",
          googleId: "",
          imageUrl: "",
          idToken: "",
          accessToken: "",
          isLoggedIn: true,
        };
        mppUser.family_name = response.user.displayName
          ? response.user.displayName.split(" ")[1]
          : "";
        mppUser.given_name = response.user.displayName
          ? response.user.displayName.split(" ")[0]
          : "";
        mppUser.email = response.user.email ? response.user.email : "";
        mppUser.imageUrl = response.user.photoURL ? response.user.photoURL : "";
        mppUser.domain = "morrisonsplc.co.uk";
        mppUser.googleId = response.user.providerId;
        mppUser.idToken = idToken ? idToken : "";
        Util.setUser(mppUser);
        Util.setFirebaeUser(response.user);
        dismissLogging(); // Remove the loading banner...

        //
        // Show the loading banner...
        presentAccessChecking({
          message: "Checking MPP Access",
          cssClass: "mpp-custom-loading-spinner",
          spinner: "bubbles",
        });
        const checkMPPAccessResponse = await checkMPPAccessCall(idToken);
        if (checkMPPAccessResponse.isValidMPPUser) {
          dismissAccessChecking();
        } else {
          dismissAccessChecking();
          alert("Please contact MPP Support Team for app access");
          history.push({
            pathname: "/logout",
          });
        }
        //
        setSignedIn(true);
      }
    };
    loginHandle();
  }, []);

  const logIn = async () => {
    const mppUser: MPPUser = {
      email: "",
      domain: "",
      googleId: "",
      imageUrl: "",
      idToken: "",
      accessToken: "",
      isLoggedIn: undefined,
    };
    Util.setUser(mppUser);
    setPersistence(auth, browserSessionPersistence).then(() => {
      return signInWithRedirect(auth, provider);
    });
  };

  const getGridClass = () => {
    if (Util.getDebugMode())
      console.log("Executing platform is " + getPlatforms().join(", "));
    if (
      (getPlatforms().includes("iphone") &&
        getPlatforms().includes("ios") &&
        getPlatforms().includes("mobile") &&
        getPlatforms().includes("mobileweb")) ||
      (getPlatforms().includes("android") &&
        getPlatforms().includes("mobile") &&
        getPlatforms().includes("mobileweb"))
    ) {
      return "morrisons-login-grid-mobile";
    } else {
      return "morrisons-login-grid";
    }
  };

  return !isSignedIn ? (
    <IonPage id="loginPage" placeholder={undefined}>
      <IonContent placeholder={undefined}>
        <IonGrid className={getGridClass()} placeholder={undefined}>
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <IonImg
                className="morrisons-image"
                src={require("../../assets/images/morrisons-logo.png")}
                placeholder={undefined}
              />
            </IonCol>
          </IonRow>
          <IonRow placeholder={undefined}>
            <IonCol className="application-name" placeholder={undefined}>
              {lables.LBL_APPLICATION_NAME}
            </IonCol>
          </IonRow>
          <IonRow placeholder={undefined}>
            <IonCol placeholder={undefined}>
              <IonButton
                className="mpp-button"
                onClick={logIn}
                placeholder={undefined}
              >
                {lables.LBL_LOGIN}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  ) : (
    <Redirect to="/home" />
  );
};

export default Login;
