import { CustomTableBox } from "../../../components/CustomTableBox";
import { Product } from "../../../models/Product";
import * as Util from "../../../components/Util";
import { lables } from "../../../components/Labels";

const CommercialHierarchyBox = (product: Product) => {
  //const product = Util.getProductJson();

  const commercialData = {
    header: lables.LBL_SECTION_PRODUCT_COMMERCIAL_HIERARCHY,
    rows: [
      {
        key: lables.LBL_PRODUCT_DIVISION,
        value:
          product?.reportingHierarchy?.divisionName &&
          product?.reportingHierarchy?.divisionName !== ""
            ? product?.reportingHierarchy?.divisionName
            : Util.NOT_AVAILABLE,
      },
      {
        key: lables.LBL_PRODUCT_TRADING_GROUP,
        value:
          product?.reportingHierarchy?.groupName &&
          product?.reportingHierarchy?.groupName !== ""
            ? product?.reportingHierarchy?.groupName
            : Util.NOT_AVAILABLE,
      },
      {
        key: lables.LBL_PRODUCT_CATEGORY,
        value:
          product?.reportingHierarchy?.categoryName &&
          product?.reportingHierarchy?.categoryName !== ""
            ? product?.reportingHierarchy?.categoryName
            : Util.NOT_AVAILABLE,
      },
      {
        key: lables.LBL_PRODUCT_GROUP,
        value:
          product?.reportingHierarchy?.departmentName &&
          product?.reportingHierarchy?.departmentName !== ""
            ? product?.reportingHierarchy?.departmentName
            : Util.NOT_AVAILABLE,
      },
      {
        key: lables.LBL_PRODUCT_CLASS,
        value:
          product?.reportingHierarchy?.className &&
          product?.reportingHierarchy?.className !== ""
            ? product?.reportingHierarchy?.className
            : Util.NOT_AVAILABLE,
      },
      {
        key: lables.LBL_PRODUCT_SUBCLASS,
        value:
          product?.reportingHierarchy?.subclassName &&
          product?.reportingHierarchy?.subclassName !== ""
            ? product?.reportingHierarchy?.subclassName
            : Util.NOT_AVAILABLE,
      },
      {
        key: lables.LBL_PRODUCT_BRAND,
        value:
          product?.brand && product?.brand !== ""
            ? product?.brand
            : Util.NOT_AVAILABLE,
      },
    ],
  };

  return <CustomTableBox {...commercialData} />;
};

export default CommercialHierarchyBox;
