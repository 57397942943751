import { ProxySession, ITransport, IApiSettings } from "@looker/sdk-rtl";
import LookerAccessToken from "../../models/LookerAccessToken";
import { getUserTokenCall } from "../../services/CloudFunctionService";
import * as Util from "../Util";

export class EmbedSession extends ProxySession {
  getToken(): Promise<any> {
    return getUserTokenCall();
  }

  activeToken: LookerAccessToken | undefined;

  constructor(public settings: IApiSettings, transport?: ITransport) {
    super(settings, "", transport);
  }

  async authenticate(props: any) {
    if (Util.getLookerToken()) {
      const token = Util.getLookerToken();
      this.activeToken = token;
      if (Util.getDebugMode()) {
        console.log(
          "Looker token from cache = " + this.activeToken?.access_token
        );
        console.log(
          "Looker token expire at  = " + this.activeToken?.expires_in
        );
      }
    } else {
      if (Util.getUser().isLoggedIn) {
        const token = await this.getToken();
        if (token) {
          this.activeToken = token;
          Util.setLookerToken(token);
          if (Util.getDebugMode()) {
            console.log("Looker token = " + this.activeToken?.access_token);
          }
        }
      }
    }

    if (this.isAuthenticated()) {
      props.mode = "cors";
      delete props["credentials"];
      props.headers = {
        Authorization: `token ${this.activeToken?.access_token}`,
        "x-looker-appid": "My Product Profile App",
      };
    }
    return props;
  }
}
