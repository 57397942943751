import {
  IonBreadcrumb,
  IonBreadcrumbs,
  IonContent,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ScanError, Scanner, ScanResult } from "../../components/Scanner";
import * as Util from "../../components/Util";
import PageTemplate from "../PageTemplate";

const Scan = () => {
  const [isCameraOpen, setCameraOpen] = useState(true);
  const history = useHistory();

  useEffect(() => {
    // Do nothing...
  }, []);

  return (
    <PageTemplate id="scanPage">
      <IonToolbar placeholder={undefined}>
        <IonBreadcrumbs
          maxItems={4}
          itemsAfterCollapse={3}
          placeholder={undefined}
        >
          <IonBreadcrumb
            className="mpp-breadcrumb"
            href="/home"
            placeholder={undefined}
          >
            Home
          </IonBreadcrumb>
          <IonBreadcrumb className="mpp-breadcrumb" placeholder={undefined}>
            Product Scan
          </IonBreadcrumb>
        </IonBreadcrumbs>
      </IonToolbar>
      <IonContent placeholder={undefined}>
        <Scanner
          open={isCameraOpen}
          onClose={function (): void {
            setCameraOpen(false);
          }}
          onScan={function (error: ScanError, code: ScanResult): void {
            if (code) {
              setCameraOpen(false);
              Util.setProductJson(undefined);
              Util.setProduct(code ? code : "");
              history.push({
                pathname: "/productdetails",
                state: { caller: "Product Scan", backlink: "/scan" },
              });
            }
          }}
        />
      </IonContent>
    </PageTemplate>
  );
};

export default Scan;
