import { User } from "firebase/auth";
import { Favourites, Stores } from "../../models/Favourites";
import LookerAccessToken from "../../models/LookerAccessToken";
import { MPPUser } from "../../models/MPPUser";
import { MPPUserPreference } from "../../models/MPPUserPreference";
import { Product } from "../../models/Product";
import { setUpLocation, getStoreNumber } from "./locations";

const setUser = (user: MPPUser) => {
  sessionStorage.setItem("mppUser", JSON.stringify(user));
};

const deleteItemFromStorage = (key: string) => {
  sessionStorage.removeItem(key);
};

const getUser = (): MPPUser => {
  let mppUser: MPPUser = {
    domain: "",
    email: "",
    googleId: "",
    idToken: "",
    accessToken: "",
    imageUrl: "",
    isLoggedIn: false,
  };
  const userStr = sessionStorage.getItem("mppUser");
  if (userStr === null || userStr.length === 0) {
  } else {
    mppUser = JSON.parse(userStr);
  }
  return mppUser;
};

const setFirebaeUser = (user: User | null): void => {
  if (user) {
    sessionStorage.setItem("firebaeUser", JSON.stringify(user));
  } else {
    sessionStorage.setItem("firebaeUserB", JSON.stringify(user));
  }
};

const getFirebaeUser = (): User | undefined => {
  let mppUser: User | undefined = undefined;
  const userStr = sessionStorage.getItem("firebaeUser");
  if (userStr === null || userStr.length === 0) {
  } else {
    mppUser = JSON.parse(userStr);
  }
  return mppUser;
};

const setUserPreference = (userPreference: MPPUserPreference) => {
  sessionStorage.setItem("mppUserPreference", JSON.stringify(userPreference));
};

const getUserPreference = (): MPPUserPreference => {
  let mppUserPreference: MPPUserPreference;
  const preferenceStr = sessionStorage.getItem("mppUserPreference");

  if (preferenceStr === null || preferenceStr.length === 0) {
    mppUserPreference = {};
  } else {
    mppUserPreference = JSON.parse(preferenceStr);
  }

  return mppUserPreference;
};

const setProduct = (min: string) => {
  const mppUserPreference: MPPUserPreference = getUserPreference();
  mppUserPreference.min = min;
  setUserPreference(mppUserPreference);
};

const getProduct = (): string => {
  const userPreference: MPPUserPreference = getUserPreference();
  return userPreference.min ? userPreference.min : "";
};

const getCurrentCorporateLocation = (): string => {
  let message = "";

  if (getRegion() === ALL_VALUES && getCountry() === ALL_VALUES) {
    message = "Corporate All Countries";
  } else if (getRegion() === ALL_VALUES && getCountry() !== ALL_VALUES) {
    message = getCountry() + " All Stores";
  } else if (getRegion() !== ALL_VALUES && getCountry() !== ALL_VALUES) {
    message = "Region " + getRegion() + " (" + getCountry() + ")";
  } else if (getRegion() !== ALL_VALUES && getCountry() === ALL_VALUES) {
    message = "Region " + getRegion() + " (All Countries)";
  }

  return message;
};

const setCountryRegionMap = (countryRegionMap: Map<string, string[]>) => {
  if (countryRegionMap.size > 0) {
    sessionStorage.setItem(
      "countryRegionMap",
      JSON.stringify(Object.fromEntries(countryRegionMap))
    );
  }
};

const getCountryRegionMap = (): Map<string, string[]> | undefined => {
  const str = sessionStorage.getItem("countryRegionMap");
  if (str) {
    const countryRegionMap: Map<string, string[]> = new Map(
      Object.entries(JSON.parse(str))
    );
    return countryRegionMap;
  } else {
    return undefined;
  }
};

const setRegion = (region: string) => {
  const mppUserPreference: MPPUserPreference = getUserPreference();
  mppUserPreference.region = region ? region : ALL_VALUES;
  setUserPreference(mppUserPreference);
};

const getRegion = (): string => {
  const userPreference: MPPUserPreference = getUserPreference();
  const region = userPreference.region ? userPreference.region : ALL_VALUES;
  if (getDebugMode()) console.log("Region is " + region);
  return region;
};

const setCountry = (country: string) => {
  const mppUserPreference: MPPUserPreference = getUserPreference();
  mppUserPreference.country = country ? country : ALL_VALUES;
  setUserPreference(mppUserPreference);
};

const getCountry = (): string => {
  const userPreference: MPPUserPreference = getUserPreference();
  const country = userPreference.country ? userPreference.country : ALL_VALUES;
  if (getDebugMode()) console.log("Country is " + country);
  return country;
};

const getCountryFromRegion = (): string[] => {
  const countryArray: string[] = [];
  const userPreference: MPPUserPreference = getUserPreference();
  const country = userPreference.country ? userPreference.country : ALL_VALUES;
  const region = userPreference.region ? userPreference.region : ALL_VALUES;
  const countryRegionMap = getCountryRegionMap();

  if (region === ALL_VALUES && country === ALL_VALUES) {
    countryRegionMap?.forEach((value, key) => {
      if (key && key !== ALL_VALUES && key.length >= 5) {
        countryArray.push(key);
      }
    });
  } else if (country !== ALL_VALUES) {
    countryArray.push(country);
  } else if (region !== ALL_VALUES && country === ALL_VALUES) {
    countryRegionMap?.forEach((value, key) => {
      if (key && key !== ALL_VALUES && key.length >= 5) {
        if (value.includes(region)) {
          countryArray.push(key);
        }
      }
    });
  }
  if (getDebugMode()) console.log("Countries are " + countryArray);
  return countryArray;
};

const setStore = (store: string) => {
  const mppUserPreference: MPPUserPreference = getUserPreference();
  mppUserPreference.store = store;
  setUserPreference(mppUserPreference);
};

const getStore = (): string | undefined => {
  const userPreference: MPPUserPreference = getUserPreference();
  return userPreference.store;
};

const delay = (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

const setStoreGeo = (stores: Stores) => {
  if (stores) sessionStorage.setItem("storeGeo", JSON.stringify(stores));
  else sessionStorage.setItem("storeGeo", '{"stores":[]}');
};

const getStoreGeo = (): Stores | undefined => {
  const str = sessionStorage.getItem("storeGeo");
  if (str) {
    const stores: Stores = JSON.parse(str);
    return stores;
  } else {
    return undefined;
  }
};

const setFavourites = (favourites: Favourites) => {
  if (favourites)
    sessionStorage.setItem("favourites", JSON.stringify(favourites));
  else sessionStorage.setItem("favourites", '{"favourites":[]}');
};

const getFavourites = (): Favourites | undefined => {
  const str = sessionStorage.getItem("favourites");
  if (str) {
    const favourites: Favourites = JSON.parse(str);
    return favourites;
  } else {
    return undefined;
  }
};

const getFavourite = (): boolean => {
  const favourites = getFavourites()?.favourites;
  let flag = false;
  favourites?.forEach((favourite) => {
    if (favourite.min === getProduct()) {
      flag = true;
    }
  });

  return flag;
};

const getDebugMode = () => {
  const str = sessionStorage.getItem("isDebug");
  if (str) {
    const isDebug: boolean = JSON.parse(str);
    return isDebug;
  } else {
    sessionStorage.setItem("isDebug", JSON.stringify(false));
    return false; // default is false...
  }
};

const getStoreNumberFromStoreName = (
  store: string | undefined
): number | undefined => {
  let storeNumberStr: string | undefined = undefined;
  if (store) {
    const storeParts = store?.split("(");
    storeNumberStr = storeParts[storeParts.length - 1].split(")")[0];
  }
  const storeNumber = storeNumberStr ? parseInt(storeNumberStr) : undefined;
  return storeNumber;
};

const getEmail = (): string => {
  const user: MPPUser = getUser();
  return user.email;
};

const setLookerToken = (lookerToken: LookerAccessToken | undefined) => {
  const user: MPPUser = getUser();

  if (lookerToken) {
    if (lookerToken.expires_in) {
      lookerToken.expires_in =
        new Date().getTime() + lookerToken.expires_in * 1000;
    }
  }

  user.lookerToken = lookerToken;
  setUser(user);
};

const setNotice = (notice: any) => {
  sessionStorage.setItem("notice", JSON.stringify(notice));
};

const getNotice = (): any => {
  const flag = sessionStorage.getItem("notice");
  if (flag) {
    return JSON.parse(flag);
  } else {
    setNotice({ message: "", title: "", timestamp: 0 });
    return { message: "", title: "", timestamp: 0 };
  }
};

const setShowNotice = (flag: string) => {
  sessionStorage.setItem("showNotice", flag);
};

const getShowNotice = (): string => {
  const flag = sessionStorage.getItem("showNotice");
  if (flag) {
    return flag;
  } else {
    setShowNotice("N");
    return "N"; //default value is N...
  }
};

const setCommercialHierarchy = (json: any[]) => {
  sessionStorage.setItem("commercialHierarchy", JSON.stringify(json));
};

const getCommercialHierarchy = (): any[] => {
  let json: any[] = [];
  const jsonStr = sessionStorage.getItem("commercialHierarchy");
  if (jsonStr) {
    json = JSON.parse(jsonStr);
  }
  return json;
};

const setWeekNumbers = (weekNumberMap: Map<string, string>) => {
  sessionStorage.setItem(
    "weekNumberMap",
    JSON.stringify(Array.from(weekNumberMap.entries()))
  );
};

const getWeekNumbers = (): Map<string, string> | undefined => {
  let weekNumberMap: Map<string, string> | undefined = undefined;
  const jsonStr = sessionStorage.getItem("weekNumberMap");
  if (jsonStr) {
    weekNumberMap = new Map(JSON.parse(jsonStr));
  }
  return weekNumberMap;
};

const getLookerToken = (): LookerAccessToken | undefined => {
  const lookerToken = getUser().lookerToken;
  if (lookerToken) {
    if (lookerToken.expires_in) {
      const tokenExpireTimeInMilis = lookerToken.expires_in;
      const currentTimeInMilis = new Date().getTime();
      if (currentTimeInMilis + 5000 > tokenExpireTimeInMilis) {
        setLookerToken(undefined);
      }
    } else {
      setLookerToken(undefined);
    }
  }

  return getUser().lookerToken;
};

const getFamilyName = (): string => {
  const user: MPPUser = getUser();
  return user.family_name ? user.family_name : "";
};

const getGivenName = (): string => {
  const user: MPPUser = getUser();
  return user.given_name ? user.given_name : "";
};

const getIdToken = (): string => {
  const user: MPPUser = getUser();
  return user.idToken;
};

const getAccessToken = (): string => {
  const user: MPPUser = getUser();
  return user.accessToken;
};

const getImageUrl = (): string => {
  const user: MPPUser = getUser();
  return user.imageUrl;
};

const getProductImageUrl = (): string | undefined => {
  const userPreference: MPPUserPreference = getUserPreference();
  return userPreference.imageUrl;
};

const setProductImageUrl = (imageUrl: string | undefined) => {
  let mppUserPreference: MPPUserPreference;
  const stringVal = sessionStorage.getItem("mppUserPreference");
  if (stringVal === null || stringVal.length === 0) {
    mppUserPreference = {};
  } else {
    mppUserPreference = JSON.parse(stringVal);
  }
  mppUserPreference.imageUrl = imageUrl;
  sessionStorage.setItem(
    "mppUserPreference",
    JSON.stringify(mppUserPreference)
  );
};

const getProductJson = (): Product | undefined => {
  const userPreference: MPPUserPreference = getUserPreference();
  return userPreference.productJson;
};

const setProductJson = (productJson: Product | undefined) => {
  const mppUserPreference: MPPUserPreference = getUserPreference();
  mppUserPreference.productJson = productJson;
  setUserPreference(mppUserPreference);
};

const isDarkMode = (): boolean => {
  const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
  return prefersDark.matches;
};

const getProperProductBarcode = (
  barcode: string
): { result: boolean; finalBarcode: string } => {
  let finalBarcode = "";
  let inputText = "";

  // This is a check for digitize and non-digitize QR code...
  if (barcode.includes(",")) {
    inputText = barcode.split(",")[0];
  } else {
    inputText = barcode;
  }

  // Length 4 = PLU Type 1. // Normally does not come from till.
  // Length 5 = PLU Type 2. // Normally does not come from till.
  // Length 8 = EAN-8
  // Length 12 = UPC_A // Normally does not come from till. Till prefixes 0 to make it 13 digit long.
  // Length 13 = EAN-13
  // Length 26 = CODE-128 // HHT generated mark down barcode starts with 97 followed by 13 digit barcode.
  const patternRMSBarcode = /^([0-9]{4}|[0-9]{5}|[0-9]{8}|[0-9]{12}|[0-9]{13})$/;
  const patternHHTBarcode = /^([0-9]{26})$/;

  // Check the barcode is an RMS barcode if false then check for a HHT barcode...
  let result = patternRMSBarcode.test(inputText);
  if (!result) {
    result = patternHHTBarcode.test(inputText);
    if (result) {
      // 26 digit barcodes generated by HHT are starting with 92 followed by 13 digit product barcode...
      if (inputText.startsWith("92")) {
        finalBarcode = inputText.substring(2, 15);
      }
    } else {
      // Handle other valid barcodes...
      finalBarcode = inputText;
    }
  } else {
    // Handle Type 1 price embedded barcodes...
    if (inputText.length === 12) {
      inputText = "0" + inputText;
    }

    if (inputText.startsWith("02")) {
      finalBarcode = inputText.substring(2, 6);
    } else if (
      // Handle Type 2 price embedded barcodes...
      inputText.startsWith("20") ||
      inputText.startsWith("23") ||
      inputText.startsWith("24") ||
      inputText.startsWith("27")
    ) {
      finalBarcode = inputText.substring(2, 7);
    } else {
      // Handle other valid barcodes...
      finalBarcode = inputText;
    }
  }

  return { result: result, finalBarcode: finalBarcode };
};

const ENGLAND_CORPORATE_STORE_NUMBER = 9940;
const WALES_CORPORATE_STORE_NUMBER = 9962;
const SCOTLAND_CORPORATE_STORE_NUMBER = 9938;
const GIBRALTAR_CORPORATE_STORE_NUMBER = 452;
const ALCOHOLIC_ITEM_DEPARTMENTS = [216, 217, 222, 223, 224, 225];

const getCorporateStoreNumber = (inCountry: string | undefined) => {
  // Check the line of product. If the product is a spirit then
  // if non-alcaholoc then return the corporate store number of england
  // else use the country specific code.
  let corporateStoreNumber = ENGLAND_CORPORATE_STORE_NUMBER;
  const product = getProductJson();
  if (product) {
    if (product.reportingHierarchy && product.reportingHierarchy.department) {
      const departmentNumber = parseInt(product.reportingHierarchy?.department);
      const country = inCountry ? inCountry : getCountry();
      if (getDebugMode())
        console.log(
          "Country is " +
            country +
            " and department number is " +
            departmentNumber
        );
      if (ALCOHOLIC_ITEM_DEPARTMENTS.includes(departmentNumber)) {
        if (country) {
          switch (country) {
            case "ENGLAND":
              corporateStoreNumber = ENGLAND_CORPORATE_STORE_NUMBER;
              break;
            case "WALES":
              corporateStoreNumber = WALES_CORPORATE_STORE_NUMBER;
              break;
            case "SCOTLAND":
              corporateStoreNumber = SCOTLAND_CORPORATE_STORE_NUMBER;
              break;
            case "GIBRALTAR":
              corporateStoreNumber = GIBRALTAR_CORPORATE_STORE_NUMBER;
              break;
            default:
              corporateStoreNumber = ENGLAND_CORPORATE_STORE_NUMBER;
              break;
          }
        }
      }
    }
  }
  if (getDebugMode())
    console.log("Corporate store number is " + corporateStoreNumber);
  return corporateStoreNumber;
};

const LOCAL = "en-GB";
const NOT_AVAILABLE = "Not Available";
const NOT_APPLICABLE = "Not Applicable";
const ALL_VALUES = "All Values";
const yyyymmddToDdmmyyyy = (input: string | undefined): string => {
  if (input) {
    if (NOT_AVAILABLE === input) {
      return NOT_AVAILABLE;
    } else {
      const dateParts = input.split("-");
      return dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0];
    }
  } else {
    return NOT_AVAILABLE;
  }
};

const getMonth = (month: string): string => {
  if (month === "JAN") return "01";
  if (month === "FEB") return "02";
  if (month === "MAR") return "03";
  if (month === "APR") return "04";
  if (month === "MAY") return "05";
  if (month === "JUN") return "06";
  if (month === "JUL") return "07";
  if (month === "AUG") return "08";
  if (month === "SEP") return "09";
  if (month === "OCT") return "10";
  if (month === "NOV") return "11";
  if (month === "DEC") return "12";
  return "";
};

const dayToDdmmyyyy = (inputDate: string): string => {
  if (inputDate) {
    const dateParts = inputDate.split("-");
    const month = dateParts[1];
    let year = parseInt(dateParts[2]);
    if (year >= 60) {
      year = 1900 + year;
    } else {
      year = 2000 + year;
    }

    return dateParts[0] + "/" + getMonth(month.toUpperCase()) + "/" + year;
  } else {
    return inputDate;
  }
};

const initCap = (input: string): string => {
  return input.toLowerCase().replace(/(?:^|\s)[a-z]/g, (val) => {
    return val.toUpperCase();
  });
};

const getUKPriceFormat = (input: any): string => {
  return input.toLocaleString(LOCAL, {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

const getUKPercentageFormat = (row: any): string => {
  return row.toLocaleString(LOCAL, {
    style: "percent",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

const getDateFormat = (input: string | undefined): string => {
  if (input) {
    const inputSplit = input.split("-");
    const weekday = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const dt = new Date(input);
    const day = weekday[dt.getDay()];
    return inputSplit[2] + "/" + inputSplit[1] + " " + day;
  } else {
    return "N/A";
  }
};

const getWeekFormat = (input: string | undefined): string => {
  const weekNumberMap = getWeekNumbers();
  let weekNumber: string | undefined = undefined;
  if (weekNumberMap && input) {
    weekNumber = weekNumberMap.get(input);
  }

  if (input) {
    const inputSplit = input.split("-");
    return (
      (weekNumber ? weekNumber + " " : "") + inputSplit[2] + "/" + inputSplit[1]
    );
  } else {
    return "N/A";
  }
};

const setLocations = (locations: string[]) => {
  sessionStorage.setItem("locations", JSON.stringify(locations));
};

const getLocations = (): string[] => {
  let locations: string[] = [];
  const jsonStr = sessionStorage.getItem("locations");
  if (jsonStr) {
    locations = JSON.parse(jsonStr);
  }
  return locations;
};

export {
  setLocations,
  getLocations,
  getEmail,
  getIdToken,
  setLookerToken,
  getLookerToken,
  getAccessToken,
  getProduct,
  setProduct,
  getCurrentCorporateLocation,
  setCountry,
  getCountry,
  setRegion,
  getRegion,
  getStore,
  setStore,
  getUser,
  setUser,
  getProductJson,
  setProductJson,
  getProductImageUrl,
  setProductImageUrl,
  getUserPreference,
  setUserPreference,
  getFamilyName,
  getGivenName,
  getImageUrl,
  getStoreNumber,
  setUpLocation,
  setFirebaeUser,
  getFirebaeUser,
  deleteItemFromStorage,
  setCommercialHierarchy,
  getCommercialHierarchy,
  isDarkMode,
  getProperProductBarcode,
  getCorporateStoreNumber,
  LOCAL,
  NOT_AVAILABLE,
  NOT_APPLICABLE,
  ALL_VALUES,
  yyyymmddToDdmmyyyy,
  dayToDdmmyyyy,
  initCap,
  getUKPriceFormat,
  setWeekNumbers,
  getWeekNumbers,
  getDateFormat,
  getWeekFormat,
  getStoreNumberFromStoreName,
  getUKPercentageFormat,
  setNotice,
  getNotice,
  setShowNotice,
  getShowNotice,
  delay,
  setStoreGeo,
  getStoreGeo,
  getDebugMode,
  setFavourites,
  getFavourites,
  getFavourite,
  setCountryRegionMap,
  getCountryRegionMap,
  getCountryFromRegion,
};
