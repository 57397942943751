import * as React from "react";
import {
  IonAccordion,
  IonAccordionGroup,
  IonBreadcrumb,
  IonBreadcrumbs,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import * as Util from "../../components/Util";
import * as Icons from "ionicons/icons";
import PageTemplate from "../PageTemplate";
import { lables } from "../../components/Labels";
import {
  getCommercialBands,
  searchProduct,
  getSupplierName,
} from "../../services/LookerService";

import { ProductSearchResult } from "../AdvanceSearch/PageComponents/ProductSearchResult";
import CustomSearchAndSelect from "../../components/CustomSearchAndSelect";
import "./styles.css";
import { auth } from "../../services/FirebaseService";
import { useHistory } from "react-router-dom";
import { getAllCountries, getAllRegions } from "../../services/LocationService";

const CorporateAdvanceSearch = () => {
  const restoreCurrentFilters = ():
    | {
        tradingGroup: string;
        category: string;
        productGroup: string;
        clasz: string;
        subClass: string;
        brand: string;
        supplier: string;
        min: string;
        pin: string;
        barcode: string;
        itemName: string;
      }
    | undefined => {
    const filterString = sessionStorage.getItem("advanceSearchfilters");
    if (filterString) {
      const filters: {
        tradingGroup: string;
        category: string;
        productGroup: string;
        clasz: string;
        subClass: string;
        brand: string;
        supplier: string;
        min: string;
        pin: string;
        barcode: string;
        itemName: string;
      } = JSON.parse(filterString);
      return filters;
    } else {
      return undefined;
    }
  };

  // Declare functional variables...
  const [tradingGroup, setTradingGroup] = React.useState<string | undefined>(
    restoreCurrentFilters()?.tradingGroup
      ? restoreCurrentFilters()?.tradingGroup
      : Util.ALL_VALUES
  );
  const [tradingGroupOptions, setTradingGroupOptions] = React.useState<any[]>();
  const [category, setCategory] = React.useState<string | undefined>(
    restoreCurrentFilters()?.category
      ? restoreCurrentFilters()?.category
      : Util.ALL_VALUES
  );
  const [categoryOptions, setCategoryOptions] = React.useState<any[]>();
  const [productGroup, setProductGroup] = React.useState<string | undefined>(
    restoreCurrentFilters()?.productGroup
      ? restoreCurrentFilters()?.productGroup
      : Util.ALL_VALUES
  );
  const [productGroupOptions, setproductGroupOptions] = React.useState<any[]>();
  const [clasz, setClasz] = React.useState<string | undefined>(
    restoreCurrentFilters()?.clasz
      ? restoreCurrentFilters()?.clasz
      : Util.ALL_VALUES
  );
  const [claszOptions, setClaszOptions] = React.useState<any[]>();
  const [subClass, setSubClass] = React.useState<string | undefined>(
    restoreCurrentFilters()?.subClass
      ? restoreCurrentFilters()?.subClass
      : Util.ALL_VALUES
  );
  const [subClassOptions, setSubClassOptions] = React.useState<any[]>();
  const [brand, setBrand] = React.useState<string | undefined>(
    restoreCurrentFilters()?.brand
      ? restoreCurrentFilters()?.brand
      : Util.ALL_VALUES
  );
  const [brandOptions, setBrandOptions] = React.useState<any[]>();
  const [supplier, setSupplier] = React.useState<string | undefined>(
    restoreCurrentFilters()?.supplier
      ? restoreCurrentFilters()?.supplier
      : Util.ALL_VALUES
  );
  const [supplierOptions, setSupplierOptions] = React.useState<any[]>();
  const [country, setCountry] = React.useState<string | undefined>(
    Util.getCountry()
  );
  const [region, setRegion] = React.useState<string | undefined>(
    Util.getRegion()
  );
  const [disableResults, setDisableResults] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [barcodeErrorMessage, setBarcodeErrorMessage] = React.useState("");
  const [min, setMin] = React.useState<string | undefined>(
    restoreCurrentFilters()?.min
  );
  const [invalidMin, setInvalidMin] = React.useState(false);

  const [pin] = React.useState<string | undefined>(
    restoreCurrentFilters()?.pin
  );

  const [invalidBarcode, setInvalidBarcode] = React.useState(false);

  const [barcode, setBarcode] = React.useState<string | undefined>(
    restoreCurrentFilters()?.barcode
  );
  const [itemName, setItemName] = React.useState<string | undefined>(
    restoreCurrentFilters()?.itemName
  );
  const [disableCommercialHierarchy, setDisableCommercialHierarchy] =
    React.useState(restoreCurrentFilters()?.min ? true : false);
  const [searchResult, setSearchResult] = React.useState<any[]>();

  const [presentSearching, dismissSearching] = useIonLoading();
  const [isSearching, setSearching] = React.useState<boolean>(false);
  const history = useHistory();

  const saveCurrentFilters = () => {
    const filters = {
      tradingGroup: tradingGroup,
      category: category,
      productGroup: productGroup,
      clasz: clasz,
      subClass: subClass,
      brand: brand,
      supplier: supplier,
      min: min,
      pin: pin,
      barcode: barcode,
      itemName: itemName,
    };
    sessionStorage.setItem("advanceSearchfilters", JSON.stringify(filters));
  };

  const compareValues = (
    d1: { key: string; value: string },
    d2: { key: string; value: string }
  ) => {
    let returnValue = 0; // Both values are same...

    if (d1.key < d2.key) {
      returnValue = -1;
    } else if (d1.key > d2.key) {
      returnValue = 1;
    }
    return returnValue;
  };

  const uniqueArray = (array: string[]): string[] => {
    const uniqueArray = array.filter((value: string, index: number) => {
      return array.indexOf(value) === index;
    });
    return uniqueArray;
  };

  // Reset Method...
  const reset = () => {
    //Remove search options from session and reload...
    sessionStorage.setItem("advanceSearchfilters", JSON.stringify({}));
    window.location.reload();
  };

  // Main Search Method...
  const search = () => {
    saveCurrentFilters();
    setSearchResult(undefined);
    setDisableResults(true);
    setSearching(true);

    auth.currentUser
      ?.getIdToken()
      .then((idToken) => {
        const mppUser = Util.getUser();
        mppUser.idToken = idToken;
        Util.setUser(mppUser);
      })
      .catch((error) => {
        console.warn("Client Warning: Session expired");
        alert("Session expired. New login required.");
        history.push({
          pathname: "/logout",
        });
      });

    if (min) {
      setSearching(false);
      Util.setProductJson(undefined);
      Util.setProduct(min);
      history.push({
        pathname: "/corporateproductdetails",
        state: {
          caller: lables.LBL_CORPORATE_PRODUCT_SEARCH,
          backlink: "/corporateadvancesearch",
        },
      });
    } else if (barcode) {
      setSearching(false);
      Util.setProductJson(undefined);
      Util.setProduct(barcode);
      history.push({
        pathname: "/corporateproductdetails",
        state: {
          caller: lables.LBL_CORPORATE_PRODUCT_SEARCH,
          backlink: "/corporateadvancesearch",
        },
      });
    } else {
      presentSearching({
        message: "Searching",
        cssClass: "mpp-custom-loading-spinner",
        spinner: "bubbles",
      });

      searchProduct(
        tradingGroup,
        category,
        productGroup,
        clasz,
        subClass,
        brand,
        supplier,
        min,
        pin,
        itemName
      ).then((response) => {
        if (response.ok) {
          const values = response.value;
          setSearchResult(values);
          setDisableResults(false);
          setSearching(false);
          dismissSearching();
        }
      });
    }
  };

  // Loader functions...
  const loadTradingGroups = () => {
    const jsonString = sessionStorage.getItem("commercialHierarchy");
    const tradingGroups: any = [];

    let tradingGroupValues: string[] = [];

    if (jsonString) {
      const values: any[] = JSON.parse(jsonString);
      values.forEach((element) => {
        tradingGroupValues.push(element["product.commercial_trading_group"]);
      });
    }

    tradingGroupValues = uniqueArray(tradingGroupValues);

    tradingGroupValues.forEach((element) => {
      tradingGroups.push({ key: element, value: element });
    });

    tradingGroups.sort(compareValues);
    tradingGroups.unshift({ key: Util.ALL_VALUES, value: Util.ALL_VALUES });

    setTradingGroupOptions(tradingGroups);
    if (restoreCurrentFilters()?.tradingGroup) {
      setTradingGroup(restoreCurrentFilters()?.tradingGroup);
    } else {
      setTradingGroup(tradingGroups[0].value);
    }
  };

  const loadCategories = (tradingGroup: string) => {
    const jsonString = sessionStorage.getItem("commercialHierarchy");
    const categories: any = [];

    let categoryValues: string[] = [];

    if (jsonString) {
      const values: any[] = JSON.parse(jsonString);
      values.forEach((element) => {
        if (
          tradingGroup === Util.ALL_VALUES ||
          tradingGroup === element["product.commercial_trading_group"]
        ) {
          categoryValues.push(element["product.commercial_category"]);
        }
      });
    }

    categoryValues = uniqueArray(categoryValues);

    categoryValues.forEach((element) => {
      categories.push({ key: element, value: element });
    });

    categories.sort(compareValues);
    categories.unshift({ key: Util.ALL_VALUES, value: Util.ALL_VALUES });

    setCategoryOptions(categories);
    setDefault(categories, restoreCurrentFilters()?.category, setCategory);
  };

  const setDefault = (
    options: { key: string; value: string }[],
    savedValue: string | undefined,
    fn: (val: string | undefined) => void
  ) => {
    let isDefaultToStoreValue = false;
    //const cat = restoreCurrentFilters()?.category;
    if (savedValue) {
      for (let index = 0; index < options.length; index++) {
        if (options[index].value === savedValue) {
          isDefaultToStoreValue = true;
          break;
        }
      }
    }

    if (isDefaultToStoreValue) {
      fn(savedValue);
    } else {
      fn(options[0].value);
    }
  };

  const loadProductGroups = (tradingGroup: string, category: string) => {
    const jsonString = sessionStorage.getItem("commercialHierarchy");
    const productGroups: any = [];
    let productGroupValues: string[] = [];

    if (jsonString) {
      const values: any[] = JSON.parse(jsonString);
      values.forEach((element) => {
        if (
          tradingGroup === Util.ALL_VALUES ||
          tradingGroup === element["product.commercial_trading_group"]
        ) {
          if (
            category === Util.ALL_VALUES ||
            category === element["product.commercial_category"]
          )
            productGroupValues.push(element["product.commercial_department"]);
        }
      });
    }

    productGroupValues = uniqueArray(productGroupValues);

    productGroupValues.forEach((element) => {
      productGroups.push({ key: element, value: element });
    });

    productGroups.sort(compareValues);
    productGroups.unshift({ key: Util.ALL_VALUES, value: Util.ALL_VALUES });

    setproductGroupOptions(productGroups);
    setDefault(
      productGroups,
      restoreCurrentFilters()?.productGroup,
      setProductGroup
    );
  };

  const loadClasses = (
    tradingGroup: string,
    category: string,
    productGroup: string
  ) => {
    const jsonString = sessionStorage.getItem("commercialHierarchy");
    const classes: any = [];
    let classValues: string[] = [];

    if (jsonString) {
      const values: any[] = JSON.parse(jsonString);
      values.forEach((element) => {
        if (
          tradingGroup === Util.ALL_VALUES ||
          tradingGroup === element["product.commercial_trading_group"]
        ) {
          if (
            category === Util.ALL_VALUES ||
            category === element["product.commercial_category"]
          ) {
            if (
              productGroup === Util.ALL_VALUES ||
              productGroup === element["product.commercial_department"]
            )
              classValues.push(element["product.commercial_class"]);
          }
        }
      });
    }

    classValues = uniqueArray(classValues);

    classValues.forEach((element) => {
      classes.push({ key: element, value: element });
    });

    classes.sort(compareValues);
    classes.unshift({ key: Util.ALL_VALUES, value: Util.ALL_VALUES });

    setClaszOptions(classes);
    setDefault(classes, restoreCurrentFilters()?.clasz, setClasz);
  };

  const loadSubClasses = (
    tradingGroup: string,
    category: string,
    productGroup: string,
    clasz: string
  ) => {
    const jsonString = sessionStorage.getItem("commercialHierarchy");
    const subclasses: any = [];

    let subClassValues: string[] = [];

    if (jsonString) {
      const values: any[] = JSON.parse(jsonString);
      values.forEach((element) => {
        if (
          tradingGroup === Util.ALL_VALUES ||
          tradingGroup === element["product.commercial_trading_group"]
        ) {
          if (
            category === Util.ALL_VALUES ||
            category === element["product.commercial_category"]
          ) {
            if (
              productGroup === Util.ALL_VALUES ||
              productGroup === element["product.commercial_department"]
            ) {
              if (
                clasz === Util.ALL_VALUES ||
                clasz === element["product.commercial_class"]
              )
                subClassValues.push(element["product.commercial_subclass"]);
            }
          }
        }
      });
    }

    subClassValues = uniqueArray(subClassValues);

    subClassValues.forEach((element) => {
      subclasses.push({ key: element, value: element });
    });

    subclasses.sort(compareValues);
    subclasses.unshift({ key: Util.ALL_VALUES, value: Util.ALL_VALUES });

    setSubClassOptions(subclasses);
    setDefault(subclasses, restoreCurrentFilters()?.subClass, setSubClass);
  };

  const loadBrands = (
    tradingGroup: string,
    category: string,
    productGroup: string,
    clasz: string,
    subClass: string
  ) => {
    getCommercialBands(
      tradingGroup,
      category,
      productGroup,
      clasz,
      subClass
    ).then((response) => {
      if (response.ok) {
        const values = response.value;
        const brands: any = [];
        brands.push({ key: Util.ALL_VALUES, value: Util.ALL_VALUES });
        values.forEach((element) => {
          brands.push({
            key: element["product.brand_name"],
            value: element["product.brand_name"],
          });
        });
        setBrandOptions(brands);
        setDefault(brands, restoreCurrentFilters()?.brand, setBrand);
      }
    });
  };

  const loadSuppliers = (
    tradingGroup: string,
    category: string,
    productGroup: string,
    clasz: string,
    subClass: string
  ) => {
    getSupplierName(tradingGroup, category, productGroup, clasz, subClass).then(
      (response) => {
        if (response.ok) {
          const values = response.value;
          const suppliers: any = [];
          suppliers.push({ key: Util.ALL_VALUES, value: Util.ALL_VALUES });
          values.forEach((element) => {
            suppliers.push({
              key: element["supplier.supplier_name"],
              value: element["supplier.supplier_name"],
            });
          });
          setSupplierOptions(suppliers);
          setDefault(suppliers, restoreCurrentFilters()?.supplier, setSupplier);
        }
      }
    );
  };

  // Run when page loads...
  React.useEffect(() => {
    setSearchResult(undefined);
    setDisableResults(true);
    loadTradingGroups();
    loadCategories(Util.ALL_VALUES);
    loadProductGroups(Util.ALL_VALUES, Util.ALL_VALUES);
    loadClasses(Util.ALL_VALUES, Util.ALL_VALUES, Util.ALL_VALUES);
    loadSubClasses(
      Util.ALL_VALUES,
      Util.ALL_VALUES,
      Util.ALL_VALUES,
      Util.ALL_VALUES
    );
    loadBrands(
      Util.ALL_VALUES,
      Util.ALL_VALUES,
      Util.ALL_VALUES,
      Util.ALL_VALUES,
      Util.ALL_VALUES
    );
    loadSuppliers(
      Util.ALL_VALUES,
      Util.ALL_VALUES,
      Util.ALL_VALUES,
      Util.ALL_VALUES,
      Util.ALL_VALUES
    );
  }, []);

  React.useEffect(() => {
    setSearchResult(undefined);
    setDisableResults(true);
    if (tradingGroup) {
      loadCategories(tradingGroup);
      loadProductGroups(tradingGroup, Util.ALL_VALUES);
      loadClasses(tradingGroup, Util.ALL_VALUES, Util.ALL_VALUES);
      loadSubClasses(
        tradingGroup,
        Util.ALL_VALUES,
        Util.ALL_VALUES,
        Util.ALL_VALUES
      );
      loadBrands(
        tradingGroup,
        Util.ALL_VALUES,
        Util.ALL_VALUES,
        Util.ALL_VALUES,
        Util.ALL_VALUES
      );
      loadSuppliers(
        tradingGroup,
        Util.ALL_VALUES,
        Util.ALL_VALUES,
        Util.ALL_VALUES,
        Util.ALL_VALUES
      );
    }
  }, [tradingGroup]);

  React.useEffect(() => {
    setSearchResult(undefined);
    setDisableResults(true);
    if (category) {
      if (tradingGroup) {
        loadProductGroups(tradingGroup, category);
        loadClasses(tradingGroup, category, Util.ALL_VALUES);
        loadSubClasses(
          tradingGroup,
          category,
          Util.ALL_VALUES,
          Util.ALL_VALUES
        );
        loadBrands(
          tradingGroup,
          category,
          Util.ALL_VALUES,
          Util.ALL_VALUES,
          Util.ALL_VALUES
        );
        loadSuppliers(
          tradingGroup,
          category,
          Util.ALL_VALUES,
          Util.ALL_VALUES,
          Util.ALL_VALUES
        );
      }
    }
  }, [category]);

  React.useEffect(() => {
    setSearchResult(undefined);
    setDisableResults(true);
    if (productGroup) {
      if (category) {
        if (tradingGroup) {
          loadClasses(tradingGroup, category, productGroup);
          loadSubClasses(tradingGroup, category, productGroup, Util.ALL_VALUES);
          loadBrands(
            tradingGroup,
            category,
            productGroup,
            Util.ALL_VALUES,
            Util.ALL_VALUES
          );
          loadSuppliers(
            tradingGroup,
            category,
            productGroup,
            Util.ALL_VALUES,
            Util.ALL_VALUES
          );
        }
      }
    }
  }, [productGroup]);

  React.useEffect(() => {
    setSearchResult(undefined);
    setDisableResults(true);
    if (clasz) {
      if (productGroup) {
        if (category) {
          if (tradingGroup) {
            loadSubClasses(tradingGroup, category, productGroup, clasz);
            loadBrands(
              tradingGroup,
              category,
              productGroup,
              clasz,
              Util.ALL_VALUES
            );
            loadSuppliers(
              tradingGroup,
              category,
              productGroup,
              clasz,
              Util.ALL_VALUES
            );
          }
        }
      }
    }
  }, [clasz]);

  React.useEffect(() => {
    setSearchResult(undefined);
    setDisableResults(true);
    if (subClass) {
      if (clasz) {
        if (productGroup) {
          if (category) {
            if (tradingGroup) {
              loadBrands(tradingGroup, category, productGroup, clasz, subClass);
              loadSuppliers(
                tradingGroup,
                category,
                productGroup,
                clasz,
                subClass
              );
            }
          }
        }
      }
    }
  }, [subClass]);

  React.useEffect(() => {
    setSearchResult(undefined);
    setDisableResults(true);
  }, [brand]);

  React.useEffect(() => {
    setSearchResult(undefined);
    setDisableResults(true);
  }, [supplier]);

  const getTradingGroups = (): any[] => {
    if (tradingGroupOptions) {
      return tradingGroupOptions;
    } else {
      return [{ key: "Loading", value: lables.MSG_LOADING_VALUES }];
    }
  };

  const getCategory = (): any[] => {
    if (categoryOptions) {
      return categoryOptions;
    } else {
      return [{ key: "Loading", value: lables.MSG_LOADING_VALUES }];
    }
  };

  const getProductGroup = (): any[] => {
    if (productGroupOptions) {
      return productGroupOptions;
    } else {
      return [{ key: "Loading", value: lables.MSG_LOADING_VALUES }];
    }
  };

  const getClasz = (): any[] => {
    if (claszOptions) {
      return claszOptions;
    } else {
      return [{ key: "Loading", value: lables.MSG_LOADING_VALUES }];
    }
  };

  const getSubClass = (): any[] => {
    if (subClassOptions) {
      return subClassOptions;
    } else {
      return [{ key: "Loading", value: lables.MSG_LOADING_VALUES }];
    }
  };

  const getBrand = (): any[] => {
    if (brandOptions) {
      return brandOptions;
    } else {
      return [{ key: "Loading", value: lables.MSG_LOADING_VALUES }];
    }
  };

  const getSupplier = (): any[] => {
    if (supplierOptions) {
      return supplierOptions;
    } else {
      return [{ key: "Loading", value: lables.MSG_LOADING_VALUES }];
    }
  };

  const getOpenTags = () => {
    if (disableResults) {
      return ["Filters"];
    } else {
      return ["Results"];
    }
  };

  // Enable or disable the search button based on the error status...
  const isDisableSearchButtopn = (): boolean => {
    if (
      (errorMessage && errorMessage.length > 0) ||
      (barcodeErrorMessage && barcodeErrorMessage.length > 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  // MIN validations...
  const minOnly = (event: any): boolean => {
    setDisableResults(true);

    const pattern = /^([0-9]{9})$/;
    const inputText = event.detail.value;

    if (inputText === undefined || inputText.length === 0) {
      setDisableCommercialHierarchy(false);
      setMin(undefined);
      setErrorMessage("");
      setInvalidMin(false);
      return true;
    }

    const result = pattern.test(inputText);

    if (result) {
      setMin(inputText);
      setDisableCommercialHierarchy(true);
      setErrorMessage("");
      setInvalidMin(false);
    } else {
      setMin(inputText);
      setDisableCommercialHierarchy(false);
      setErrorMessage(lables.ERR_NOT_A_VALID_MIN);
      setInvalidMin(true);
    }
    return result;
  };

  // Barcode validations...
  const barcodeOnly = (event: any): boolean => {
    setDisableResults(true);
    setInvalidBarcode(false);
    const inputText = event.detail.value;

    // No need to check anything...
    if (inputText === undefined || inputText.length === 0) {
      setDisableCommercialHierarchy(false);
      setBarcode(undefined);
      setBarcodeErrorMessage("");
      return true;
    }

    const res = Util.getProperProductBarcode(inputText);
    const result = res.result;

    if (result) {
      setBarcode(inputText);
      setDisableCommercialHierarchy(true);
      setBarcodeErrorMessage("");
      setInvalidBarcode(false);
    } else {
      setBarcode(inputText);
      setDisableCommercialHierarchy(false);
      setBarcodeErrorMessage(lables.ERR_NOT_A_VALID_BARCODE);
      setInvalidBarcode(true);
    }
    return result;
  };

  const productNameOnly = () => {
    setDisableResults(true);
  };

  return (
    <PageTemplate id="productDetailsTypePage">
      <IonToolbar placeholder={undefined}>
        <IonBreadcrumbs
          maxItems={4}
          itemsAfterCollapse={3}
          placeholder={undefined}
        >
          <IonBreadcrumb
            className="mpp-breadcrumb"
            href="/home"
            placeholder={undefined}
          >
            {lables.LBL_HOME}
          </IonBreadcrumb>
          <IonBreadcrumb className="mpp-breadcrumb" placeholder={undefined}>
            {lables.LBL_CORPORATE_PRODUCT_SEARCH}
          </IonBreadcrumb>
        </IonBreadcrumbs>
      </IonToolbar>
      <IonContent placeholder={undefined}>
        <IonFab
          vertical="bottom"
          horizontal="end"
          slot="fixed"
          style={{ width: "15%", height: "25%" }}
          placeholder={undefined}
        >
          <IonFabButton
            tabIndex={1}
            title={lables.MSG_RESET_FILTERS}
            size="small"
            className="mpp-fab-button"
            onClick={reset}
            placeholder={undefined}
          >
            <IonIcon
              style={{ pointerEvents: "none" }}
              icon={Icons.refreshCircle}
              placeholder={undefined}
            />
          </IonFabButton>
          <IonFabButton
            tabIndex={1}
            title={lables.LBL_CORPORATE_PRODUCT_SEARCH}
            size="small"
            className="mpp-fab-button"
            disabled={isDisableSearchButtopn()}
            onClick={search}
            placeholder={undefined}
          >
            <IonIcon
              style={{ pointerEvents: "none" }}
              icon={Icons.searchCircle}
              placeholder={undefined}
            />
          </IonFabButton>
        </IonFab>
        <IonAccordionGroup
          expand="compact"
          value={getOpenTags()}
          placeholder={undefined}
        >
          <IonAccordion value="Filters" placeholder={undefined}>
            <IonItem
              slot="header"
              className="mpp-accordian-header-item"
              placeholder={undefined}
            >
              <IonLabel
                className="mpp-accordian-header-label"
                placeholder={undefined}
              >
                {lables.LBL_FILTERS}
              </IonLabel>
            </IonItem>
            <IonItem slot="content" placeholder={undefined}>
              <IonGrid placeholder={undefined}>
                <IonRow placeholder={undefined}>
                  <IonCol
                    size="4"
                    style={{ minWidth: "200px" }}
                    placeholder={undefined}
                  >
                    <IonLabel
                      className="mpp-filter-key-text"
                      placeholder={undefined}
                    >
                      {lables.LBL_COUNTRY}
                    </IonLabel>
                  </IonCol>
                  <IonCol
                    size="6"
                    style={{ minWidth: "350px" }}
                    placeholder={undefined}
                  >
                    <CustomSearchAndSelect
                      initValue={country}
                      options={getAllCountries()}
                      onclickFn={function (value: string): void {
                        setCountry(value);
                        setRegion(Util.ALL_VALUES);
                        Util.setCountry(value);
                        Util.setRegion(Util.ALL_VALUES);
                      }}
                    />
                  </IonCol>
                </IonRow>
                <IonRow placeholder={undefined}>
                  <IonCol
                    size="4"
                    style={{ minWidth: "200px" }}
                    placeholder={undefined}
                  >
                    <IonLabel
                      className="mpp-filter-key-text"
                      placeholder={undefined}
                    >
                      {lables.LBL_REGION}
                    </IonLabel>
                  </IonCol>
                  <IonCol
                    size="6"
                    style={{ minWidth: "350px" }}
                    placeholder={undefined}
                  >
                    <CustomSearchAndSelect
                      initValue={region}
                      options={getAllRegions(country)}
                      onclickFn={function (value: string): void {
                        setRegion(value);
                        Util.setRegion(value);
                      }}
                    />
                  </IonCol>
                </IonRow>
                <IonRow placeholder={undefined}>
                  <IonCol
                    size="4"
                    style={{ minWidth: "200px" }}
                    placeholder={undefined}
                  >
                    <IonLabel
                      className="mpp-filter-key-text"
                      placeholder={undefined}
                    >
                      {lables.LBL_PRODUCT_MIN}
                    </IonLabel>
                    <IonLabel
                      hidden={!invalidMin}
                      style={{ color: "red" }}
                      placeholder={undefined}
                    >
                      {errorMessage}
                    </IonLabel>
                  </IonCol>
                  <IonCol
                    size="6"
                    style={{ minWidth: "350px" }}
                    placeholder={undefined}
                  >
                    <IonInput
                      className="mpp-filter-value-text"
                      autofocus={false}
                      type="text"
                      maxlength={9}
                      onIonChange={minOnly}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          if (
                            min &&
                            errorMessage.length === 0 &&
                            min.length > 0
                          ) {
                            search();
                          }
                        }
                      }}
                      value={min}
                      placeholder={lables.LBL_PRODUCT_MIN_PLACEHOLDER}
                    />
                  </IonCol>
                </IonRow>
                <IonRow placeholder={undefined}>
                  <IonCol
                    size="4"
                    style={{ minWidth: "200px" }}
                    placeholder={undefined}
                  >
                    <IonLabel
                      className="mpp-filter-key-text"
                      placeholder={undefined}
                    >
                      {lables.LBL_PRODUCT_BARCODE}
                    </IonLabel>
                    <IonLabel
                      hidden={!invalidBarcode}
                      style={{ color: "red" }}
                      placeholder={undefined}
                    >
                      {barcodeErrorMessage}
                    </IonLabel>
                  </IonCol>
                  <IonCol
                    size="6"
                    style={{ minWidth: "350px" }}
                    placeholder={undefined}
                  >
                    <IonInput
                      className="mpp-filter-value-text"
                      autofocus={false}
                      type="text"
                      maxlength={26}
                      onIonChange={barcodeOnly}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          if (
                            barcode &&
                            barcodeErrorMessage.length === 0 &&
                            barcode.length > 0
                          ) {
                            search();
                          }
                        }
                      }}
                      value={barcode}
                      placeholder={lables.LBL_PRODUCT_BARCODE_PLACEHOLDER}
                    />
                  </IonCol>
                </IonRow>
                <IonRow placeholder={undefined}>
                  <IonCol
                    size="4"
                    style={{ minWidth: "200px" }}
                    placeholder={undefined}
                  >
                    <IonLabel
                      className="mpp-filter-key-text"
                      placeholder={undefined}
                    >
                      {lables.LBL_PRODUCT_NAME}
                    </IonLabel>
                  </IonCol>
                  <IonCol
                    size="6"
                    style={{ minWidth: "350px" }}
                    placeholder={undefined}
                  >
                    <IonInput
                      value={itemName}
                      className="mpp-filter-value-text"
                      autofocus={false}
                      type="text"
                      placeholder={lables.LBL_PRODUCT_NAME_PLACEHOLDER}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          if (itemName && itemName.length > 0) {
                            search();
                          }
                        }
                      }}
                      onIonChange={(e) => {
                        if (e.detail.value) {
                          productNameOnly();
                          setItemName(e.detail.value);
                        } else {
                          productNameOnly();
                          setItemName(undefined);
                        }
                      }}
                    />
                  </IonCol>
                </IonRow>
                <AppFilter
                  label={lables.LBL_PRODUCT_TRADING_GROUP}
                  isDisable={disableCommercialHierarchy}
                  value={tradingGroup}
                  setValue={setTradingGroup}
                  getOptionArray={getTradingGroups().map((e) => e.value)}
                />
                <AppFilter
                  label={lables.LBL_PRODUCT_CATEGORY}
                  isDisable={disableCommercialHierarchy}
                  value={category}
                  setValue={setCategory}
                  getOptionArray={getCategory().map((e) => e.value)}
                />
                <AppFilter
                  label={lables.LBL_PRODUCT_GROUP}
                  isDisable={disableCommercialHierarchy}
                  value={productGroup}
                  setValue={setProductGroup}
                  getOptionArray={getProductGroup().map((e) => e.value)}
                />
                <AppFilter
                  label={lables.LBL_PRODUCT_CLASS}
                  isDisable={disableCommercialHierarchy}
                  value={clasz}
                  setValue={setClasz}
                  getOptionArray={getClasz().map((e) => e.value)}
                />
                <AppFilter
                  label={lables.LBL_PRODUCT_SUBCLASS}
                  isDisable={disableCommercialHierarchy}
                  value={subClass}
                  setValue={setSubClass}
                  getOptionArray={getSubClass().map((e) => e.value)}
                />
                <AppFilter
                  label={lables.LBL_PRODUCT_BRAND}
                  isDisable={disableCommercialHierarchy}
                  value={brand}
                  setValue={setBrand}
                  getOptionArray={getBrand().map((e) => e.value)}
                />
                <AppFilter
                  label={lables.LBL_PRODUCT_SUPPLIER}
                  isDisable={disableCommercialHierarchy}
                  value={supplier}
                  setValue={setSupplier}
                  getOptionArray={getSupplier().map((e) => e.value)}
                />
              </IonGrid>
            </IonItem>
          </IonAccordion>
          <IonAccordion
            value="Results"
            disabled={disableResults}
            placeholder={undefined}
          >
            <IonItem
              slot="header"
              className="mpp-accordian-header-item"
              placeholder={undefined}
            >
              <IonLabel
                className="mpp-accordian-header-label"
                placeholder={undefined}
              >
                {lables.LBL_RESULTS}
              </IonLabel>
            </IonItem>
            <IonItem slot="content" placeholder={undefined}>
              {isSearching ? (
                <></>
              ) : (
                <ProductSearchResult
                  data={searchResult}
                  caller={{
                    pathname: "/corporateproductdetails",
                    callerLabel: lables.LBL_CORPORATE_PRODUCT_SEARCH,
                    backlink: "/corporateadvancesearch",
                  }}
                />
              )}
            </IonItem>
          </IonAccordion>
        </IonAccordionGroup>
      </IonContent>
    </PageTemplate>
  );
};

const AppFilter = (props: {
  label: string;
  isDisable: boolean;
  value: string | undefined;
  setValue: (val: string) => void;
  getOptionArray: any[];
}) => {
  return (
    <IonRow placeholder={undefined}>
      <IonCol size="4" style={{ minWidth: "200px" }} placeholder={undefined}>
        <IonLabel className="mpp-filter-key-text" placeholder={undefined}>
          {props.label}
        </IonLabel>
      </IonCol>
      <IonCol size="6" style={{ minWidth: "350px" }} placeholder={undefined}>
        <CustomSearchAndSelect
          disabled={props.isDisable}
          initValue={props.value}
          options={props.getOptionArray}
          onclickFn={function (value: string): void {
            props.setValue(value);
          }}
        />
      </IonCol>
    </IonRow>
  );
};

export default CorporateAdvanceSearch;
