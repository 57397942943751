import React from "react";
import { BarcodeFormat } from "@zxing/library";
import WebScanner from "./WebScanner";
import { ScanResult, ScanError } from "./types";

interface ScannerProps {
  formats?: BarcodeFormat[];
  open: boolean;
  showCloseButton?: boolean;
  onClose: () => void;
  onScan: (error: ScanError, code: ScanResult) => void;
}

const Scanner: React.FC<ScannerProps> = ({
  onScan,
  onClose,
  formats,
  open,
}) => {
  return (
    <>
      <WebScanner
        open={open}
        formats={formats}
        onClose={onClose}
        onScan={(code: string) => {
          onScan(null, code);
        }}
        onScanError={(error: string) => {
          onScan(error, null);
        }}
      />
    </>
  );
};

Scanner.defaultProps = {
  formats: [
    BarcodeFormat.EAN_8,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
    BarcodeFormat.CODE_128,
  ],
};

export default Scanner;
