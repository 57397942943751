import { IonCol, IonGrid, IonItem, IonNavLink, IonRow } from "@ionic/react";
import { useHistory } from "react-router-dom";
import * as Util from "../Util";
import { lables } from "../../components/Labels";
import "./styles.css";
import { PageModalData } from "../../pages/CorporateProductDetails/PageModalData";

// This box shows data in column format means each column
// will be represented as a row with key value pair...
const CustomTableBox = (props: {
  header?: string;
  headerNeeded?: boolean;
  rows?: {
    key: string;
    value?: string | number | undefined;
    linkWithDetails?: boolean;
    linkedProductMin?: string;
    isUpper?: boolean;
    isLower?: boolean;
    modalKey?: string;
    modalMIN?: string;
    modalAdditionalValues?: { planogramClass?: string | number };
  }[];
}) => {
  const history = useHistory();

  const getHeader = () => {
    if (props.headerNeeded) {
      return (
        <IonRow
          className="custom-table-box-grid-title-row"
          placeholder={undefined}
        >
          <IonCol placeholder={undefined}>{props.header}</IonCol>
        </IonRow>
      );
    } else {
      return <></>;
    }
  };

  const getValueHTML = (row: {
    key: string | undefined;
    value?: string | number | undefined;
    linkWithDetails?: boolean;
    linkedProductMin?: string;
    isUpper?: boolean;
    isLower?: boolean;
    modalKey?: string;
    modalMIN?: string;
    modalAdditionalValues?: { planogramClass?: string | number };
  }) => {
    if (row.linkWithDetails) {
      return (
        <>
          <IonNavLink
            className="mpp-min-link"
            //expand="block"
            //fill="default"
            onClick={() => {
              Util.setProductJson(undefined);
              Util.setProduct(row.linkedProductMin ? row.linkedProductMin : "");
              history.push({
                pathname: "/productdetails",
                state: {
                  caller: lables.LBL_STORE_PRODUCT_SEARCH,
                  backlink: "/advancesearch",
                },
              });
            }}
            placeholder={undefined}
          >
            {row.value ? Util.initCap(row.value.toString()) : row.value}
          </IonNavLink>
        </>
      );
    } else if (row.modalKey && row.modalMIN) {
      return (
        <PageModalData
          componentName={row.modalKey}
          productMin={row.modalMIN}
          value={row.value}
          modalAdditionalValues={row.modalAdditionalValues}
        />
      );
    } else {
      if (row.isUpper) {
        return (
          <>
            {row.value && row.value !== Util.NOT_AVAILABLE
              ? row.value.toString().toLocaleUpperCase()
              : row.value}
          </>
        );
      }

      if (row.isLower) {
        return (
          <>
            {row.value && row.value !== Util.NOT_AVAILABLE
              ? row.value.toString().toLocaleLowerCase()
              : row.value}
          </>
        );
      }

      return <>{row.value ? Util.initCap(row.value.toString()) : row.value}</>;
    }
  };

  return (
    <IonItem lines="none" placeholder={undefined}>
      <IonGrid className="custom-table-box-grid" placeholder={undefined}>
        {getHeader()}
        {props.rows?.map((row) => {
          return (
            <IonRow
              key={row.key}
              className="custom-table-box-grid-column-row"
              placeholder={undefined}
            >
              <IonCol
                size="4"
                className="custom-table-box-grid-column-header"
                placeholder={undefined}
              >
                <div className={"selectable"}>{row.key}</div>
              </IonCol>
              <IonCol
                size="8"
                className="custom-table-box-grid-column-value"
                placeholder={undefined}
              >
                <div className={"selectable"}>{getValueHTML(row)}</div>
              </IonCol>
            </IonRow>
          );
        })}
      </IonGrid>
    </IonItem>
  );
};

export { CustomTableBox };
