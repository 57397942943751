import { CustomTableBox } from "../../../components/CustomTableBox";
import { Product } from "../../../models/Product";
import * as Util from "../../../components/Util";

const HFSSInformationBox = (product: Product) => {
  const packs = product?.packs;
  const values: string[] = [];
  packs?.forEach((e) => {
    if (e.packNumber) values.push(e.packNumber + " (" + e.packQuantity + ")");
  });

  const data = {
    header: "HFSS Information",
    rows: [
      { key: "Food/Drink", value: product?.hfssFoodDrink },
      { key: "In/Out Of Scope", value: product?.hfssInScope },
      {
        key: "Score",
        value:
          product?.hfssScore != null ? product?.hfssScore : Util.NOT_AVAILABLE,
      },
      { key: "HFSS Status", value: product?.hfssStatus },
      { key: "HFSS Category", value: product?.hfssCategory },
    ],
  };

  return <CustomTableBox {...data} />;
};

export default HFSSInformationBox;
